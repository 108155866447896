import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const useCreateApplicant = (close: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (
      values:
        | {
            jobId: string;
            status: string;
            candidateId: string;
            assignee: string;
          }
        | {}
    ) => axios.post("/application", values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Application added successful");
        close();
        queryClient.resetQueries(["applicants"]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useCreateApplicant;
