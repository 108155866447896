import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

export default function useLogin() {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { email: string; password: string } | {}) =>
      axios.post("/auth/signin", values).then((res) => res.data),
    {
      onSuccess: (user) => {
        queryClient.setQueryData("userData", {
          ...user,
          dataFetched: true,
        });
        return { ...user, dataFetched: true };
      },
      onError: async (error: any) => {
        await queryClient.setQueryData("userData", {
          dataFetched: true,
        });
      },
    }
  );
}
