import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const useMoveApplicant = (close: () => void, id: string) => {
  const params = useParams();
  const queryClient = useQueryClient();
  return useMutation(
    (
      values:
        | {
            jobId: string;
            status: string;
            assignee?: string;
          }
        | {}
    ) => axios.patch(`/application/${id}`, values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Application moved successfully");
        close();
        queryClient.invalidateQueries(["applicant", params?.jobId]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useMoveApplicant;
