import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack5";

// mui
import { Grid, IconButton } from "@mui/material";
import Iconify from "./Iconify";

export default function PdfViewer({ filePath }: { filePath: string }) {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={2}
      >
        <Grid item>
          <IconButton
            disabled={pageNumber === 1 || numPages === null}
            onClick={() => {
              setPageNumber((prev) => prev - 1);
            }}
          >
            <Iconify icon="ooui:previous-ltr" />
          </IconButton>
        </Grid>

        <Grid item>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </Grid>
        <Grid item>
          <IconButton
            disabled={numPages === null || pageNumber === numPages}
            onClick={() => {
              setPageNumber((prev) => prev + 1);
            }}
          >
            <Iconify icon="ooui:previous-rtl" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          bgcolor: "white",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: 1,
          p: 3.5,
          m: 1,
          position: "relative",
        }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Document
          file={filePath}
          onLoadSuccess={onDocumentLoadSuccess}
          className={"react-pdf__Page"}
        >
          <Page
            pageNumber={pageNumber}
            className={"react-pdf__Page__canvas"}
            width={window.screen.width * 0.8}
          />
        </Document>
      </Grid>
    </Grid>
  );
}
