import * as React from "react";
import { useNavigate } from "react-router-dom";

// mui
import Button from "@mui/material/Button";
import { IconButton, InputAdornment, Link as MuiLink } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled, useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// hooks
import useTitle from "../../components/hooks/useTitle";

// components
import Logo from "../../components/Logo";
import { BRAND } from "../../utils/constants";

const HeaderStyle = styled("header")(({ theme }: { theme: any }) => ({
  top: 0,
  zIndex: 9,
  right: "5rem",
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "flex-end",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const Register = () => {
  useTitle("Register");
  const theme = useTheme();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={0} sm={0} md={6} sx={{ overflow: "hidden" }}>
          <img
            src="/images/signup.jpg"
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            bgcolor: "white",
            borderTopLeftRadius: "0.5rem",
            borderBottomLeftRadius: "0.5rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.up("md")]: {
              px: 16,
            },
          }}
          p="2rem 4rem"
        >
          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Register with {BRAND}
          </Typography>

          <Typography sx={{ color: "text.secondary", mb: 2 }}>
            Enter your details below.
          </Typography>
          <form>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField label="Firstname" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField label="Lastname" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField label="Email address" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirm password"
                  type={showConfirmPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="secondary"
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign up
                </Button>
              </Grid>
            </Grid>
          </form>

          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Already have an account?{" "}
            <MuiLink
              sx={{ cursor: "pointer" }}
              variant="subtitle2"
              onClick={() => navigate("/auth/login")}
              // color="secondary"
            >
              Sign-in
            </MuiLink>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Register;
