import { useQuery } from "react-query";
import { JOB_DETAILS_SCHEMA } from "../../admin/Job/CreateJobDetails";
import axios from "axios";
import { JOB } from "./useCreateJob";

export const useGetJobDetails = (id?: string) => {
  const fetchJobDetails = () => {
    return axios.get(`/job/${id}`).then((res) => res.data);
  };

  return useQuery<JOB>(["jobs", id], fetchJobDetails, {
    refetchOnMount: "always",
    keepPreviousData: true,
  });
};
