import axios from "axios";
import { QueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

function useForgetPassword() {
  const queryClient = new QueryClient();

  return useMutation(
    (values: { email: string }) => axios.post("/auth/forgot-password", values),
    {
      onSuccess: () => {
        toast.success("Password link send successful");
      },
      onError: () => {
        toast.error("Something went wrong, please try again");
      },
    }
  );
}

export default useForgetPassword;
