import { useEffect, useState } from "react";

// react-query
import { useQueryClient } from "react-query";
import { MAPPER, MAPPING } from "../react-query/mapping/useCreateMapper";
import useMapper from "../react-query/mapping/useMapper";

// mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
} from "@mui/material";

// component
import PreviewMappings from "./PreviewMappings";
import { TabPanel, a11yProps } from "../TabComponent";
import NoDataPage from "../NoDataPage";

export default function MapperSelectorDialog({
  selectedMapper,
  open,
  handleClose,
  handleSelectionMapper,
}: {
  selectedMapper: MAPPER | null;
  open: boolean;
  handleClose: () => void;
  handleSelectionMapper: (m: MAPPER) => void;
}) {
  const [mapper, setMapper] = useState<MAPPER | null>(selectedMapper);

  const limitMappers: number = 20;
  const [loadMoreMapper, setLoadMoreMapper] = useState<boolean>(true);
  const [skipMappers, setSkipMappers] = useState<number>(0);

  const { isLoading: MapperGettingLoading, data } = useMapper({
    limit: limitMappers,
    skip: skipMappers,
  });
  const queryClient = useQueryClient();
  const mappers: MAPPER[] | undefined = queryClient.getQueryData(["mappers"]);

  useEffect(() => {
    if (!MapperGettingLoading) {
      if (data?.length !== limitMappers) {
        loadMoreMapper && setLoadMoreMapper(false);
      }
    }
  }, [MapperGettingLoading]);

  // tabs
  const [tabValue, setTabValue] = useState(0);
  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Select Mapper`}</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabValueChange}>
              <Tab label="Select Mapper" {...a11yProps(0)} />
              <Tab label="Preview Selected Mapper" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Mapper</FormLabel>
              <RadioGroup value={mapper?.mapperId}>
                {mappers?.map((m: MAPPER, index) => (
                  <FormControlLabel
                    key={m?.mapperId + index}
                    value={m.mapperId}
                    onClick={() => {
                      setMapper(m);
                    }}
                    control={<Radio />}
                    label={`${m.name} (${m.mapperId})`}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            {mapper ? (
              <Box>
                <PreviewMappings mappings={mapper?.mappings} />
              </Box>
            ) : (
              <NoDataPage title="Please select mapper" />
            )}
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>

        <Button
          variant="contained"
          onClick={() => {
            if (mapper) {
              handleSelectionMapper(mapper);
            } else {
              handleClose();
            }
          }}
        >
          Use Selected
        </Button>
      </DialogActions>
    </Dialog>
  );
}
