import React, { useEffect, useState } from "react";

// hoc
import withLayout from "../../../components/HOC/withLayout";
import useTitle from "../../../components/hooks/useTitle";
import useModalState from "../../../components/react-query/useModalState";

// layout
import AdminLayout from "../../../components/layouts/admin";

// components
import ProcessCard from "../../../components/admin/process/ProcessCard";
import EditProcessForm from "../../../components/admin/process/EditProcessForm";
import CustomDialog from "../../../components/CustomDialog";
import CreateProcessForm from "../../../components/admin/process/CreateProcessForm";
import CustomLoader from "../../../components/CustomLoader";
import SearchNotFound from "../../../components/SearchNotFound";

// react-query
import { useQuery, useQueryClient } from "react-query";
import useProcess from "../../../components/react-query/process/useProcess";
import { PROCESS } from "../../../components/react-query/process/useCreateProcess";
import useSearchProcess from "../../../components/react-query/process/useSearchProcess";

// mui
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import useDebounce from "../../../components/hooks/useDebounce";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";

const Process = () => {
  useTitle("Process");
  const limitProcesses: number = 12;
  const [loadMoreProcess, setLoadMoreProcess] = useState<boolean>(true);
  const [skipProcesses, setSkipProcesses] = useState<number>(0);

  const { isLoading: ProcessGettingLoading, data } = useProcess({
    limit: limitProcesses,
    skip: skipProcesses,
    active: true,
  });

  const { data: Processes, isLoading } = useQuery<PROCESS[]>(["processes"]);

  const {
    modalState: IsOpenCreateProcessDialog,
    open: handleOpenCreateProcessDialog,
    close: handleCloseCreateProcessDialog,
  } = useModalState();

  useEffect(() => {
    if (!ProcessGettingLoading) {
      if (data?.length !== limitProcesses) {
        loadMoreProcess && setLoadMoreProcess(false);
      }
    }
  }, [ProcessGettingLoading]);

  // update process dialog states
  const [selectedProcess, setSelectedProcess] = useState<null | PROCESS>(null);
  const openUpdateProcessDialog = (p: PROCESS) => {
    setSelectedProcess(p);
  };
  const closeUpdateProcessDialog = () => {
    setSelectedProcess(null);
  };

  // search process
  const [searchText, setSearchText] = useState("");
  const debouncedSearchQuery = useDebounce(searchText, 300);

  const { isLoading: isLoadingSearchProcess, data: SearchProcesses } =
    useSearchProcess({
      name: debouncedSearchQuery,
    });

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5">Process</Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleOpenCreateProcessDialog}
          >
            Process
          </Button>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Search Process
              </InputLabel>
              <OutlinedInput
                type={"text"}
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setSearchText("");
                      }}
                      edge="end"
                    >
                      {searchText && <CloseIcon />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Search Process"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          {SearchProcesses?.map((p: PROCESS, index: number) => (
            <Grid item xs={12} md={6} lg={4} key={p.processId + index}>
              <ProcessCard
                process={{ ...p }}
                openUpdateDialog={openUpdateProcessDialog}
              />
            </Grid>
          ))}

          {!!SearchProcesses?.length && (
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
            </Grid>
          )}
        </Grid>

        {Processes?.map((p: PROCESS, index: number) => (
          <Grid item xs={12} md={6} lg={4} key={p.processId + index}>
            <ProcessCard
              process={{ ...p }}
              openUpdateDialog={openUpdateProcessDialog}
            />
          </Grid>
        ))}
        {loadMoreProcess ? (
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            {ProcessGettingLoading ? (
              <CustomLoader />
            ) : (
              <Button
                variant="outlined"
                onClick={() =>
                  setSkipProcesses(Processes?.length ? Processes.length : 0)
                }
              >
                Load more processes
              </Button>
            )}
          </Grid>
        ) : (
          !data?.length && (
            <Grid item xs={12}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Grid>
          )
        )}
      </Grid>

      <CustomDialog
        title={"Create Process"}
        onClose={handleCloseCreateProcessDialog}
        open={IsOpenCreateProcessDialog}
        hideFooter={true}
        maxWidth="sm"
      >
        <CreateProcessForm handleClose={handleCloseCreateProcessDialog} />
      </CustomDialog>

      {selectedProcess !== null ? (
        <CustomDialog
          title={`Edit Process (ID: ${selectedProcess?.processId})`}
          onClose={closeUpdateProcessDialog}
          open={selectedProcess !== null}
          hideFooter={true}
          maxWidth="sm"
        >
          <EditProcessForm
            handleClose={closeUpdateProcessDialog}
            process={selectedProcess}
          />
        </CustomDialog>
      ) : null}
    </>
  );
};

export default withAuth(withLayout(Process, AdminLayout), AUTH_ROLES.ADMIN);
