// component
import CustomLoader from "../../CustomLoader";
import PipelineView from "../pipeline/PipelineView";
import CustomDialog from "../../CustomDialog";
import CreatePipelineForm from "../pipeline/CreatePipelineForm";

// interfacrs
import { Pipeline_Schema } from "../../../utils/constants";

// mui
import { Box, Button, Container } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// hooks & hocs
import useModalState from "../../react-query/useModalState";

const JobPipelineView = ({
  pipelineOptimizeData,
  isLoading,
  isFetching,
}: {
  pipelineOptimizeData: Pipeline_Schema | undefined;
  isLoading: boolean;
  isFetching: boolean;
}) => {
  // modal for add round
  const {
    modalState: createPipelineModalState,
    open: openPipelineModal,
    close: closePipelineModal,
  } = useModalState();

  return (
    <Box>
      {isLoading ? (
        <CustomLoader />
      ) : pipelineOptimizeData?.jobId ? (
        <>
          <PipelineView data={{ ...pipelineOptimizeData }} />
        </>
      ) : (
        <Container>
          <Box sx={{ display: "flex", gap: 4 }}>
            <Box
              sx={{
                mt: 3,
                p: 3,
                mx: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                border: `1px solid #ddd`,
                minWidth: "24rem",
                height: "90vh",
                overflow: "hidden",
                overflowY: "auto",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "white.main",
                },
              }}
              onClick={openPipelineModal}
            >
              <Button
                variant="text"
                startIcon={<AddRoundedIcon />}
                sx={{ mb: 16 }}
              >
                Add Round
              </Button>
            </Box>
          </Box>
          <CustomDialog
            open={createPipelineModalState}
            title={"Add Round"}
            onClose={closePipelineModal}
            hideFooter={true}
            maxWidth="xs"
          >
            <CreatePipelineForm handleClose={closePipelineModal} />
          </CustomDialog>
        </Container>
      )}
    </Box>
  );
};

export default JobPipelineView;
