import React, { useState } from "react";

// utils
import {
  InteractionStatus,
  Pipeline_Obj_Schema,
  Pipeline_Schema,
} from "../../../utils/constants";

// mui
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SortIcon from "@mui/icons-material/Sort";

// hocs & hooks
import useModalState from "../../react-query/useModalState";
import CustomDialog from "../../CustomDialog";
import AddRoundPipelineForm from "./AddRoundPipelineForm";
import useEditPipeline from "../../react-query/pipeline/useEditPipeline";
import { SortingListObj } from "./PipelineView";
import { useQueryClient } from "react-query";
import { useParams } from "react-router";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const PipelineRoundHead = ({
  column,
  pipelineIndex,
  pipeline,
}: {
  column: Pipeline_Obj_Schema;
  pipelineIndex: number;
  pipeline: Pipeline_Schema;
}) => {
  const queryClient = useQueryClient();
  const params = useParams();
  const sortingListData: SortingListObj[] | undefined =
    queryClient.getQueryData(["sorting-list"]);

  const getSortingListIndex = sortingListData?.findIndex(
    (data) => data.key === column.key
  );

  const [selectedHead, setSelectedHead] = useState<
    Pipeline_Obj_Schema | undefined
  >();

  const [anchorSectionMenu, setAnchorSectionMenu] = useState(null);
  const [anchorFilterMenu, setAnchorFilterMenu] = useState(null);
  const handleOpenSectionMenu = (event: any) => {
    setAnchorSectionMenu(event.currentTarget);
  };
  const handleCloseSectionMenu = () => {
    setAnchorSectionMenu(null);
  };

  const handleOpenFilterMenu = (event: any) => {
    setAnchorFilterMenu(event.currentTarget);
  };
  const handleCloseFilterMenu = () => {
    setAnchorFilterMenu(null);
  };

  const [interactionText, setInteractionText] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  // modal for add round
  const {
    modalState: addRoundModalState,
    open: openAddRoundModal,
    close: closeAddRoundModal,
  } = useModalState();

  // add round at this index
  const [addIndex, setAddIndex] = useState(0);

  // menu in roundHead
  const menuItems = [
    {
      label: "Add Round",
      icon: <ArrowBackIcon />,
      onClick: (pipelineIndex: number) => {
        setAddIndex(pipelineIndex);
        openAddRoundModal();
        handleCloseSectionMenu();
      },
    },
    {
      label: "Add Round",
      icon: <ArrowForwardIcon />,
      onClick: (pipelineIndex: number) => {
        setAddIndex(pipelineIndex + 1);
        openAddRoundModal();
        handleCloseSectionMenu();
      },
    },
    {
      label: "Round",
      icon: <DeleteIcon color="primary" />,
      onClick: (pipelineIndex: number) => {
        deleteRound(pipelineIndex);
        handleCloseSectionMenu();
      },
    },
  ];

  // update Round in pipeline
  const { mutateAsync: editRoundInPipeline, isLoading: IsRoundGettingAdded } =
    useEditPipeline(pipeline.pipelineId);

  const deleteRound = async (deleteIndex: number) => {
    try {
      let localPipeline = [...pipeline.pipeline];

      localPipeline.splice(deleteIndex, 1);
      const editedPipeline = await editRoundInPipeline({
        ...pipeline,
        pipeline: [...localPipeline],
      });
    } catch (error) {}
  };

  // round Name
  const [label, setLabel] = useState(column.label);

  const updateRoundName = async () => {
    if (label === column.label) {
      return;
    }
    try {
      let localPipeline = [...pipeline.pipeline];
      localPipeline[pipelineIndex].label = label;
      const editedPipeline = await editRoundInPipeline({
        ...pipeline,
        pipeline: [...localPipeline],
      });
    } catch (error) {}
  };

  const handleSorting = () => {
    // let localSortingListData = sortingListData;
    // if (getSortingListIndex !== undefined && localSortingListData) {
    //   let data = localSortingListData[getSortingListIndex].sort;
    //   if (data) {
    //     localSortingListData[getSortingListIndex].sort = false;
    //   } else {
    //     localSortingListData[getSortingListIndex].sort = true;
    //   }
    //   queryClient.setQueryData(["sorting-list"], [...localSortingListData]);
    //   queryClient.invalidateQueries(["applicant", params?.jobId]);
    // }
  };

  const handleSearch = () => {
    let localSortingListData = sortingListData;

    if (getSortingListIndex !== undefined && localSortingListData) {
      localSortingListData[getSortingListIndex].interaction = interactionText;
      localSortingListData[getSortingListIndex].date = selectedDate;
      localSortingListData[getSortingListIndex].sort = true;

      queryClient.setQueryData(["sorting-list"], [...localSortingListData]);
      queryClient.invalidateQueries(["applicant", params?.jobId]);
    }
  };

  const handleClear = () => {
    setInteractionText("");
    setSelectedDate("");

    let localSortingListData = sortingListData;

    if (getSortingListIndex !== undefined && localSortingListData) {
      localSortingListData[getSortingListIndex].interaction = "";
      localSortingListData[getSortingListIndex].date = "";
      localSortingListData[getSortingListIndex].sort = false;

      queryClient.setQueryData(["sorting-list"], [...localSortingListData]);
      queryClient.invalidateQueries(["applicant", params?.jobId]);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {selectedHead?.label ? (
          <OutlinedInput
            defaultValue={column?.label}
            onChange={(e) => {
              setLabel(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={() => {
                    updateRoundName();
                    setSelectedHead(undefined);
                  }}
                >
                  <CheckIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        ) : (
          <Typography
            variant="h6"
            onClick={() => {
              setSelectedHead(column);
            }}
          >
            {column.label}
          </Typography>
        )}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            // color={
            //   sortingListData?.length &&
            //   getSortingListIndex !== undefined &&
            //   sortingListData[getSortingListIndex]?.sort
            //     ? "primary"
            //     : "secondary"
            // }
            onClick={(e) => handleOpenFilterMenu(e)}
          >
            <SortIcon />
          </IconButton>
          <Tooltip title="More actions">
            <>
              <IconButton
                onClick={(e) => {
                  handleOpenSectionMenu(e);
                }}
              >
                <MoreHorizRoundedIcon />
              </IconButton>
              <Menu
                anchorEl={anchorFilterMenu}
                open={Boolean(anchorFilterMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleCloseFilterMenu}
              >
                <MenuItem>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Select date"
                      value={
                        selectedDate ? moment(selectedDate, "YYYY-MM-DD") : null
                      }
                      onChange={(newValue: any) => {
                        newValue &&
                          setSelectedDate(newValue?.format("YYYY-MM-DD"));
                      }}
                      renderInput={(params: any) => (
                        <TextField fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </MenuItem>
                <MenuItem>
                  <TextField
                    fullWidth
                    value={interactionText}
                    select
                    onChange={(e) => {
                      setInteractionText(e.target.value);
                    }}
                    label="Interaction status"
                    placeholder="select interaction status"
                  >
                    {Object.values(InteractionStatus).map((v: string) => (
                      <MenuItem key={v} value={v}>
                        {v}
                      </MenuItem>
                    ))}
                  </TextField>
                </MenuItem>
                <MenuItem>
                  <Button onClick={handleSearch}>Search</Button>
                  <Button onClick={handleClear}>Clear</Button>
                </MenuItem>
              </Menu>
              <Menu
                anchorEl={anchorSectionMenu}
                open={Boolean(anchorSectionMenu)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handleCloseSectionMenu}
              >
                {menuItems.map((item, menuIndex) => (
                  <MenuItem
                    key={item.label + menuIndex}
                    onClick={() => item.onClick(pipelineIndex)}
                    sx={{
                      bgcolor: "grey.light",
                      my: 1,
                      "&:hover": {
                        bgcolor: "#FFCCCB",
                      },
                    }}
                  >
                    {item.icon}
                    <Typography
                      sx={{ ml: 2, fontWeight: 600, color: "rgba(0,0,0,0.6)" }}
                    >
                      {item.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </>
          </Tooltip>
        </Box>
      </Box>

      <CustomDialog
        open={addRoundModalState}
        title={"Add Round"}
        onClose={closeAddRoundModal}
        hideFooter={true}
        maxWidth="xs"
      >
        {pipeline ? (
          <AddRoundPipelineForm
            handleClose={closeAddRoundModal}
            pipeline={pipeline}
            addIndex={addIndex}
          />
        ) : null}
      </CustomDialog>
    </>
  );
};

export default PipelineRoundHead;
