// @mui
import { styled } from "@mui/material/styles";
import { Typography, Container, Box } from "@mui/material";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 0),
}));

// ----------------------------------------------------------------------

function NoDataPage({
  title = "Sorry, No Data Found",
  subTitle = "",
}: {
  title?: string;
  subTitle?: string;
}) {
  return (
    <Container>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h4">{title}</Typography>
        {subTitle !== "" ? (
          <Typography sx={{ color: "text.secondary" }}>{subTitle}</Typography>
        ) : null}
        <Box
          component="img"
          src="/images/NoDataFound.png"
          sx={{ height: 250, mx: "auto", my: 2 }}
        />
      </ContentStyle>
    </Container>
  );
}

export default NoDataPage;
