import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Pipeline_Schema } from "../../../utils/constants";
import { toast } from "react-toastify";

const useEditPipeline = (pipelineId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: Pipeline_Schema) =>
      axios.patch(`/pipeline/${pipelineId}`, values).then((res) => res.data),
    {
      onSuccess: (updatedData) => {
        toast.success("Pipeline updated successfully");
        queryClient.setQueryData(["pipeline", updatedData.jobId], {
          ...updatedData,
        });
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useEditPipeline;
