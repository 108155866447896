import moment from "moment";
import { CREATE_CANDIDATE } from "../components/react-query/candidate/useCreateCandidate";
import { FIELD } from "./constants";

export const getObjectOfExcelKeytoBeKey = (
  mappings: { excelKey: string; beKey: FIELD }[]
) => {
  let obj: any = {};

  mappings.forEach((item) => {
    obj[item.excelKey] = item.beKey;
  });

  return obj;
};

export const convertExcelCandidatesToBeSchema = (
  excelCandidates: object[],
  keyMapper: any
) => {
  let candidates: CREATE_CANDIDATE[] = [];
  let convertedCandidates: any[] = [];
  excelCandidates.forEach((applicant: any, index: number) => {
    let candidate: any = {};
    Object.entries(applicant).forEach(([excelKey, value], index) => {
      candidate[keyMapper[excelKey]] = value;
    });

    convertedCandidates.push(candidate);
  });

  candidates = convertedCandidates.map((applicant: any, index: any) => {
    let candidate: any = { ...applicant };
    candidate["dob"] = candidate?.["dob"]
      ? moment(candidate?.["dob"]).isValid()
        ? moment(candidate?.["dob"]).format("YYYY-MM-DD")
        : moment(candidate?.["dob"], "DD-MM-YYYY").isValid()
        ? moment(candidate?.["dob"], "DD-MM-YYYY").format("YYYY-MM-DD")
        : ""
      : "";
    delete candidate["extra"];

    if (
      candidate?.["country"] ||
      candidate?.["state"] ||
      candidate?.["city"] ||
      candidate?.["addressLine1"]
    ) {
      let address: any = {
        country: candidate?.["country"] ? candidate["country"] : "",
        state: candidate?.["state"] ? candidate["state"] : "",
        city: candidate?.["city"] ? candidate["city"] : "",
        addressLine1: candidate?.["addressLine1"]
          ? candidate["addressLine1"]
          : "",
      };
      candidate["address"] = [{ ...address }];
    }
    delete candidate["state"];
    delete candidate["city"];
    delete candidate["addressLine1"];

    let education: any[] = [];

    if (candidate?.["10th"]) {
      let secondary = {
        type: "10th",
        marks: candidate?.["10th"] ? candidate["10th"] : "",
      };
      education = [...education, { ...secondary }];
    }
    delete candidate["10th"];

    if (candidate?.["12th"]) {
      let higherSecondary = {
        type: "12th",
        marks: candidate?.["12th"] ? candidate["12th"] : "",
      };
      education = [...education, { ...higherSecondary }];
    }
    delete candidate["12th"];

    if (
      candidate?.["graduation"] ||
      candidate?.["graduation%"] ||
      candidate?.["graduationCollege"] ||
      candidate?.["graduationPassout"]
    ) {
      let graduation = {
        type: candidate?.["graduation"] ? candidate["graduation"] : "",
        marks: candidate?.["graduation%"] ? candidate["graduation%"] : "",
        institute: candidate?.["graduationCollege"]
          ? candidate["graduationCollege"]
          : "",
        end: candidate?.["graduationPassout"]
          ? candidate["graduationPassout"]
          : "",
      };
      education = [...education, { ...graduation }];
    }
    delete candidate["graduation"];
    delete candidate["graduation%"];
    delete candidate["graduationPassout"];
    delete candidate["graduationCollege"];

    if (
      candidate?.["pg"] ||
      candidate?.["pg%"] ||
      candidate?.["pgCollege"] ||
      candidate?.["pgPassout"]
    ) {
      let pg = {
        type: candidate?.["pg"] ? candidate["pg"] : "",
        mark: candidate?.["pg%"] ? candidate["pg%"] : "",
        institute: candidate?.["pgCollege"] ? candidate["pgCollege"] : "",
        end: candidate?.["pgPassout"] ? candidate["pgPassout"] : "",
      };
      education = [...education, { ...pg }];
    }
    delete candidate["pg"];
    delete candidate["pg%"];
    delete candidate["pgPassout"];
    delete candidate["pgCollege"];

    return {
      id: index,
      ...candidate,
      education: [...education],
    };
  });
  return candidates;
};
