import moment from "moment";

// mui
import { Box, Divider, Grid, Typography } from "@mui/material";

// utils
import { Education, Experience } from "../../../utils/constants";

const getEducation = (value: Education[]) => {
  if (!!!value || !!!value.length) {
    return null;
  }
  return value.map((education: Education, index) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {education?.institute ? (
          <Typography variant="body2" fontWeight={800}>
            {education?.institute}
          </Typography>
        ) : null}
        {education?.type ? (
          <Typography variant="body2" fontWeight={500}>
            {education?.type}
          </Typography>
        ) : null}
        {education?.start || education?.end ? (
          <Typography variant="body2" fontWeight={400}>
            {`${education?.start ? education?.start : "NA"} - ${
              education?.end ? education?.end : "NA"
            }`}
          </Typography>
        ) : null}
        {education?.marks || education?.totalMarks ? (
          <Typography variant="body2" fontWeight={400}>
            {`${education?.marks ? education?.marks : "NA"}/${
              education?.totalMarks ? education?.totalMarks : "NA"
            }`}
          </Typography>
        ) : null}
        {index !== value.length - 1 ? <Divider sx={{ my: 2 }} /> : null}
      </Grid>
    </Grid>
  ));
};

const getExperience = (value: Experience[]) => {
  if (!!!value || !!!value.length) {
    return null;
  }
  return value.map((experience: Experience, index) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {experience?.employer ? (
          <Typography variant="body2" fontWeight={800}>
            {experience?.employer}
          </Typography>
        ) : null}
        {experience?.jobRole ? (
          <Typography variant="body2" fontWeight={700}>
            {experience?.jobRole}
          </Typography>
        ) : null}
        {experience?.start || experience?.end ? (
          <Typography variant="body2" fontWeight={400}>
            {`${experience?.start ? experience?.start : "NA"} - ${
              experience.isCurrent === true
                ? "Now"
                : experience?.end
                ? experience?.end
                : "NA"
            }`}
          </Typography>
        ) : null}

        {experience?.jobDescription?.length ? (
          <Box sx={{ pl: 4 }}>
            <ul>
              {experience?.jobDescription?.map((res: String, index: number) => (
                <li key={index}>
                  <Typography>{res}</Typography>
                </li>
              ))}
            </ul>
          </Box>
        ) : null}
        {index !== value.length - 1 ? <Divider sx={{ my: 2 }} /> : null}
      </Grid>
    </Grid>
  ));
};

const getValue = (value: any, type: string) => {
  if (!!!value) {
    return null;
  }
  switch (type) {
    case "string":
      return value;
    case "stringArray":
      return value.join(", ");
    case "educationArray":
      return getEducation(value as Education[]);
    case "experienceArray":
      return getExperience(value as Experience[]);
    case "YYYY-MM-DD":
      return moment(value).format("DD-MM-YYYY");
    default:
      return null;
  }
};

const GetCandidateKeyView = ({
  title,
  value,
  type,
  divider,
}: {
  title: string;
  value: any;
  type: string;
  divider?: boolean;
}) => {
  let v = getValue(value, type);

  return v ? (
    <Grid item xs={12}>
      <Typography variant="h6">{`${title} :`}</Typography>
      <Typography>{getValue(value, type)}</Typography>
      {divider ? <Divider sx={{ mt: 2 }} /> : null}
    </Grid>
  ) : null;
};

export default GetCandidateKeyView;
