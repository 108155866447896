import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import axios from "axios";

// mui
import ThemeProvider from "./components/theme";

//compoents
import ToastContainerCustom from "./components/ToastContainer";

// pages
import Dashboard from "./pages/admin";
import BulkUpload from "./pages/admin/bulk-upload";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgotPassword";
import ResetPassword from "./pages/auth/resetPassword";
import Page404 from "./components/Page404";
import Process from "./pages/admin/process";
import View from "./pages/admin/process/view";
import ViewJobs from "./pages/admin/jobs/view";
import ViewApplicants from "./pages/admin/applicant/View";
import CreateCandidate from "./pages/admin/candidate/View";
import CreateRecruiter from "./pages/admin/create-recruiter/View";
import EditCandidate from "./pages/admin/applicant/EditCandidate";

// recruiter pages
import ViewRecruiterJobs from "./pages/recruiter/jobs";
import CreateCandidateByRecruiter from "./pages/recruiter/candidate/View";
import ViewRecruiterApplicants from "./pages/recruiter/applicant/View";
import EditRecruiterCandidate from "./pages/recruiter/applicant/EditCandidate";
import ViewJobById from "./pages/recruiter/jobs/ViewJobById";

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      { path: "", element: <Login /> },
      { path: "/*", element: <Page404 /> },
      {
        path: "admin",
        children: [
          { path: "", element: <Dashboard /> },
          { path: "bulk-upload", element: <BulkUpload /> },
          { path: "process", element: <Process /> },
          { path: "add-candidate", element: <CreateCandidate /> },
          { path: "process/:id", element: <View /> },
          { path: "process/:id/:jobId", element: <ViewJobs /> },
          { path: "applicant", element: <ViewApplicants /> },
          { path: "applicant/:id", element: <EditCandidate /> },
          { path: "jobs/edit/:id", element: <EditCandidate /> },
          { path: "create-recruiter", element: <CreateRecruiter /> },
        ],
      },
      {
        path: "auth",
        children: [
          // { path: "", element: <Dashboard /> },
          { path: "login", element: <Login /> },
          { path: "register", element: <Register /> },
          { path: "forgot-password", element: <ForgotPassword /> },
          { path: "/auth/reset-password", element: <ResetPassword /> },
        ],
      },
      {
        path: "recruiter",
        children: [
          { path: "jobs", element: <ViewRecruiterJobs /> },
          { path: "jobs/:jobId", element: <ViewJobById /> },
          { path: "jobs/edit/:id", element: <EditRecruiterCandidate /> },
          { path: "add-candidate", element: <CreateCandidateByRecruiter /> },
          { path: "applicant", element: <ViewRecruiterApplicants /> },
          { path: "applicant/:id", element: <EditRecruiterCandidate /> },
        ],
      },
    ],
  },
]);

axios.defaults.baseURL = process.env.REACT_APP_BE_SERVER;

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <ToastContainerCustom />
        <RouterProvider router={router} />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;

// TODO: add auth guard in all admin and recruiter
// TODO: add selected list tab in admin and recruiter side nav
