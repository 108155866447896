import React from "react";
import CreateRecruiterForm from "../../../components/admin/Recruiter/CreateRecruiterForm";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";
import withLayout from "../../../components/HOC/withLayout";
import AdminLayout from "../../../components/layouts/admin";
import { Box, Typography } from "@mui/material";

const View = () => {
  return (
    <>
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: 3, mt: -2 }}>
          <Typography variant="h4">Create Recruiter</Typography>
        </Box>
        <CreateRecruiterForm />
      </Box>
    </>
  );
};

export default withAuth(withLayout(View, AdminLayout), AUTH_ROLES.ADMIN);
