import { Draggable } from "react-beautiful-dnd";

// mui
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Rating,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";

// utils
import {
  Applicant_BY_JOB_SCHEMA,
  ApplicationStatus,
  User_Schema,
} from "../../../utils/constants";
import { ViewData } from "../../ResuableUI";
import useRejectApplication from "../../react-query/application/useRejectCandidate";
import CustomDialog from "../../CustomDialog";
import useModalState from "../../react-query/useModalState";
import CreateComment from "../CreateComment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import InputIcon from "@mui/icons-material/Input";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import StarIcon from "@mui/icons-material/Star";
import MoveCandidateForm from "./MoveCandidateForm";
import useRateApplicant from "../../react-query/applicant/useRateApplicant";
import ChangeRecruiterForm from "./ChangeRecruiterForm";

const PipelineRoundView = ({
  data,
  index,
  selectCandidate,
}: {
  data: Applicant_BY_JOB_SCHEMA;
  index: number;
  selectCandidate: (c: Applicant_BY_JOB_SCHEMA) => void;
}) => {
  const { data: userData } = useQuery<User_Schema | undefined>(["userData"]);

  const navigate = useNavigate();
  const { mutateAsync: rejectApplication, isLoading } = useRejectApplication();

  const { open, close, modalState } = useModalState();
  const {
    open: openMoveApplicant,
    close: closeMoveApplicant,
    modalState: modalStateMoveApplicant,
  } = useModalState();

  const {
    open: openChangeRecruiter,
    close: closeChangeRecruiter,
    modalState: modalStateChangeRecruiter,
  } = useModalState();

  const {
    open: openRateApplicant,
    close: closeRateApplicant,
    modalState: modalStateRateApplicant,
  } = useModalState();

  const [selectedCandidate, setSelectedCandidate] = useState<
    Applicant_BY_JOB_SCHEMA | undefined
  >();

  const [selectedEditCandidate, setSelectedEditCandidate] = useState<
    Applicant_BY_JOB_SCHEMA | undefined
  >();

  useEffect(() => {
    if (!modalState) {
      setSelectedCandidate(undefined);
    }
  }, [modalState]);

  useEffect(() => {
    if (!modalStateChangeRecruiter) {
      setSelectedEditCandidate(undefined);
    }
  }, [modalStateChangeRecruiter]);

  useEffect(() => {
    if (!modalStateMoveApplicant) {
      setSelectedEditCandidate(undefined);
    }
  }, [modalStateMoveApplicant]);

  // menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { mutate: rateApplicantMutate } = useRateApplicant(
    closeRateApplicant,
    selectedEditCandidate?.applicationId
  );

  const menuOptions: {
    icon: React.ReactElement;
    title: string;
    onClickHandler: () => void;
  }[] = [
    {
      icon: <EditIcon sx={{ mr: 2 }} color="primary" />,
      title: "Edit",
      onClickHandler: () => {
        if (selectedEditCandidate?.candidateId) {
          navigate(
            `/${userData?.role}/jobs/edit/${selectedEditCandidate.candidateId}`
          );
        }
      },
    },
    {
      icon: <InputIcon sx={{ mr: 2 }} color="primary" />,
      title: "Move/Change Applicant",
      onClickHandler: openMoveApplicant,
    },
    // {
    //   icon: <PublishedWithChangesIcon sx={{ mr: 2 }} color="primary" />,
    //   title: "Change Recruiter",
    //   onClickHandler: openChangeRecruiter,
    // },
    {
      icon: <StarHalfIcon sx={{ mr: 2 }} color="primary" />,
      title: "Rate Applicant",
      onClickHandler: openRateApplicant,
    },
  ];
  return (
    <>
      <Draggable key={data?._id} draggableId={data?._id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Box
              sx={{
                p: 3,
                my: 4,
                borderRadius: 1,
                bgcolor: "rgba(255, 255, 255,1)",
                border: data?.interactions?.length
                  ? "1px solid rgba(0,0,0,0.2)"
                  : "2px solid rgba(255, 0, 0, 0.6)",
                cursor: "pointer",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mt: -2,
                    mb: -1,
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1.6 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "rgba(0,0,0,0.7)" }}
                    >
                      {data?.candidate?.firstName} {data?.candidate?.lastName}
                    </Typography>

                    <>
                      {data?.candidate?.rating ? (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="h6"
                            sx={{ pr: 0.5, color: "rgba(0,0,0,0.68)" }}
                          >
                            {data?.candidate?.rating?.toFixed(1)}
                          </Typography>
                          <Rating
                            readOnly
                            precision={0.1}
                            name="customized-10"
                            defaultValue={1}
                            max={1}
                            size="medium"
                          />
                        </Box>
                      ) : null}
                    </>
                  </Box>
                  <Box>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        selectCandidate(data);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>

                    <IconButton
                      color="secondary"
                      sx={{ mr: -2 }}
                      onClick={(event) => {
                        setSelectedEditCandidate(data);
                        handleMenuClick(event);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>

                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      sx={{ border: "1px solid #ddd" }}
                    >
                      {menuOptions.map((option) => (
                        <MenuItem
                          key={option?.title}
                          onClick={() => {
                            option.onClickHandler();
                            handleMenuClose();
                          }}
                        >
                          {option?.icon} {option?.title}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Box>
                {ViewData("Phone :", data?.candidate?.phone || "")}
                {ViewData("Email :", data?.candidate?.email || "")}
                <Box sx={{ gap: 2, display: "flex" }}>
                  <Button
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                      open();
                      setSelectedCandidate({
                        ...data,
                        status: ApplicationStatus.REJECTED,
                      });
                    }}
                  >
                    Reject
                  </Button>

                  <Button
                    variant="outlined"
                    disabled={isLoading}
                    onClick={() => {
                      open();
                      setSelectedCandidate({
                        ...data,
                        status: ApplicationStatus.SELECTED,
                      });
                    }}
                  >
                    Select
                  </Button>
                </Box>
              </Box>
            </Box>
          </div>
        )}
      </Draggable>
      {selectedCandidate?._id ? (
        <CustomDialog
          title={"Add Comment"}
          onClose={close}
          open={modalState}
          hideFooter={true}
          hideClose
          maxWidth="sm"
        >
          <CreateComment
            applicant={selectedCandidate}
            rejectApplication={rejectApplication}
            rejected={false}
            status={selectedCandidate.status}
            close={close}
          />
        </CustomDialog>
      ) : null}

      {selectedEditCandidate?._id ? (
        <CustomDialog
          title={"Move/Change Applicant"}
          onClose={closeMoveApplicant}
          open={modalStateMoveApplicant}
          hideFooter={true}
          maxWidth="sm"
        >
          <MoveCandidateForm
            candidate={selectedEditCandidate}
            close={closeMoveApplicant}
            role={userData?.role}
          />
        </CustomDialog>
      ) : null}

      {selectedEditCandidate?._id ? (
        <CustomDialog
          title={"Change Recruiter"}
          onClose={closeChangeRecruiter}
          open={modalStateChangeRecruiter}
          hideFooter={true}
          maxWidth="sm"
        >
          <ChangeRecruiterForm
            candidate={selectedEditCandidate}
            close={closeChangeRecruiter}
          />
        </CustomDialog>
      ) : null}

      {selectedEditCandidate?._id ? (
        <CustomDialog
          title={"Rate Applicant"}
          onClose={closeRateApplicant}
          open={modalStateRateApplicant}
          hideFooter={true}
          maxWidth="sm"
        >
          <Box>
            <Typography component="legend">Rate Applicant</Typography>
            <Rating
              name="hover-feedback"
              precision={0.5}
              size="large"
              onChange={(event, newValue) => {
                rateApplicantMutate({ rating: newValue });
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
              }
            />
          </Box>
        </CustomDialog>
      ) : null}
    </>
  );
};

export default PipelineRoundView;
