import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Pipeline_Schema } from "../../../utils/constants";

const useCreatePipeline = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: Pipeline_Schema) =>
      axios.post("/pipeline", values).then((res) => res.data),
    {
      onSuccess: (pipeline: Pipeline_Schema) => {
        queryClient.setQueryData(["pipeline", pipeline.jobId], pipeline);
      },
    }
  );
};

export default useCreatePipeline;
