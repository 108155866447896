import React, { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import { User_Schema } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const AUTH_ROLES = {
  AUTH: "auth",
  UNAUTH: undefined,
  ADMIN: "admin",
  RECRUITER: "recruiter",
  USER: "user",
};
export const AUTH_REDIRECTS = {
  [AUTH_ROLES.ADMIN]: "/admin/process",
  [AUTH_ROLES.RECRUITER]: "/recruiter/jobs",
};

/**
 * @param {Component} Children -> Component to be added guard to
 * @param {string} [role] -> any of AUTH_ROLES, pass the role for which the route should be accessible
 * @param {string} [redirectTo="auth/login"] -> redirect path if not authenticated defaults to /auth/login
 * @return {Component}  return the component if user is authenticated else redirects the user to redirect URL
 */
function authComp(
  Children: React.ComponentType,
  role: string | undefined,
  redirectTo: string = "/"
) {
  const Comp = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const {
      data: userData,
      isLoading,
      isFetched,
    }: {
      data: User_Schema | undefined;
      isLoading: boolean;
      isFetched: boolean;
    } = useQuery<User_Schema>("userData");

    const getUserData = async () => {
      try {
        const user = await axios.get(`/auth/user`);
        queryClient.setQueryData("userData", {
          ...user.data,
          dataFetched: true,
        });
      } catch (err) {
        queryClient.setQueryData("userData", {
          dataFetched: true,
        });
      }
    };

    useEffect(() => {
      if (!isFetched && !!!userData) {
        getUserData();
      }
    }, [isFetched]);

    function getCompBasedOnRole() {
      if (userData && userData?.role) {
        if (role === userData.role) {
          return <Children />;
        } else {
          userData.role && navigate(AUTH_REDIRECTS[userData.role]);
          return null;
        }
      } else {
        if (role === AUTH_ROLES.UNAUTH || role === AUTH_ROLES.AUTH) {
          return <Children />;
        } else {
          navigate(redirectTo);
          return null;
        }
      }
    }

    return !userData?.dataFetched ? (
      <Box
        sx={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Box>
    ) : (
      getCompBasedOnRole()
    );
  };

  return Comp;
}

const withAuth = (
  WrapperComponent: React.ComponentType,
  role: string | undefined,
  redirectTo?: string
) => authComp(WrapperComponent, role, redirectTo);

export default withAuth;
