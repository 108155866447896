import { useQuery } from "react-query";
import axios from "axios";
import { Candidate_Schema } from "../../../utils/constants";

export const useAdvanceSearchCandidate = ({ values }: { values?: string }) => {
  const fetchCandidateDetails = () => {
    if (!values?.length) return [];
    return axios.get(`/candidate/search?${values}`).then((res) => res.data);
  };

  return useQuery<Candidate_Schema[]>(
    ["search-candidate"],
    fetchCandidateDetails,
    {
      refetchOnMount: true,
    }
  );
};
