import * as Yup from "yup";
import {
  Candidate_Schema,
  Pipeline_Schema,
  User_Schema,
} from "../../../utils/constants";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { JOB_DETAILS_SCHEMA } from "../../../components/admin/Job/CreateJobDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import useCreateApplicant from "../../../components/react-query/applicant/useCreateApplicant";
import useCreateMultiApplicant from "../../../components/react-query/applicant/useCreateMultiApplicant";
import { useQueryClient } from "react-query";

interface Pipeline {
  key: string;
  label: string;
  data?: any[] | undefined;
}
interface ApplicanInput {
  jobId: JOB_DETAILS_SCHEMA | null | string | undefined;
  status: Pipeline | null | string | undefined;
}

const ApplicantFormSchema = Yup.object().shape({
  jobId: STRING_VALIDATOR,
  status: STRING_VALIDATOR,
});

const CreateApplicantForm = ({
  candidate,
  jobs,
  close,
  candidates,
  multiCandidate,
}: {
  candidate?: Candidate_Schema | undefined;
  candidates?: Candidate_Schema[] | undefined;
  jobs: JOB_DETAILS_SCHEMA[] | undefined;
  multiCandidate?: boolean;
  close: () => void;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ApplicanInput>({
    resolver: yupResolver(ApplicantFormSchema),
    defaultValues: { jobId: null, status: null },
    mode: "onChange",
  });

  const queryClient = useQueryClient();

  const { mutate: createApplicantMutate } = useCreateApplicant(close);
  const { mutate: createMultiApplicantMutate } = useCreateMultiApplicant(close);

  const [pipelineData, setPipelineData] = useState<Pipeline_Schema>();

  const [jobValue, setJobValue] = useState<JOB_DETAILS_SCHEMA | null>();
  const [statusValue, setStatusValue] = useState<Pipeline | null>();
  const [recruiterValue, setRecruiterValue] = useState<User_Schema | null>();
  const [candidateValue, setCandidateValue] = useState<
    Candidate_Schema | undefined
  >(candidate);

  const userData: User_Schema | undefined = queryClient.getQueryData([
    "userData",
  ]);

  const onSubmit = (data: ApplicanInput) => {
    const responseData: {
      jobId: string | undefined;
      status: string | undefined;
      candidateId?: string | undefined;
      candidateIds?: string[] | undefined;
      assignee?: string | undefined;
    } = {
      jobId: jobValue?.jobId,
      status: statusValue?.key,
    };

    if (candidateValue?.candidateId) {
      responseData.candidateId = candidateValue?.candidateId;
    }

    responseData.assignee = userData?.userId;

    if (candidates && candidates.length) {
      responseData.candidateIds = candidates.map(
        (candidate: Candidate_Schema) => candidate?.candidateId
      );
    }

    if (multiCandidate) {
      createMultiApplicantMutate(responseData);
    } else {
      createApplicantMutate(responseData);
    }
  };

  useEffect(() => {
    if (jobValue?.jobId) {
      (async () => {
        const res = await axios.get(`/pipeline/${jobValue?.jobId}`);
        setPipelineData(res.data);
      })();
    }
  }, [jobValue]);

  return (
    <>
      {jobs?.length ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event: any, newValue: JOB_DETAILS_SCHEMA | null) => {
                  setJobValue(newValue);
                  setValue("jobId", newValue?.name, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                getOptionLabel={(option) => (option?.name ? option.name : "")}
                options={jobs}
                fullWidth
                value={jobValue || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please select a job"
                    {...register("jobId")}
                    error={Boolean(errors.jobId)}
                    helperText={
                      Boolean(errors.jobId) && "This field is required"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {pipelineData?.jobId && (
                <Autocomplete
                  onChange={(event: any, newValue: Pipeline | null) => {
                    setStatusValue(newValue);
                    setValue("status", newValue?.key, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  getOptionLabel={(option) => option?.label}
                  options={pipelineData?.pipeline}
                  fullWidth
                  value={statusValue || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Round"
                      {...register("status")}
                      error={Boolean(errors?.status)}
                      helperText={
                        Boolean(errors?.status) && "This field is required"
                      }
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            sx={{ my: 2 }}
          >
            Create Application
          </LoadingButton>
        </form>
      ) : null}
    </>
  );
};

export default CreateApplicantForm;
