import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export default function useLogout() {
  const queryClient = useQueryClient();

  return useMutation(() => axios.post("/auth/logout", {}), {
    onSuccess: () => {
      toast.success("Logout Successful");
      queryClient.setQueryData("userData", {
        dataFetched: true,
      });
      queryClient.clear();
    },
    onError: () => {
      toast.error("Something went wrong, please try again");
    },
  });
}
