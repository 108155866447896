import React, { useState } from "react";
import moment from "moment";

// mui
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import palette from "../../components/theme/palette";

// hoc
import withLayout from "../../components/HOC/withLayout";

// component
import AdminLayout from "../../components/layouts/admin";
import {
  AppWidgetSummary,
  AppWebsiteVisits,
  AppCurrentVisits,
} from "../../components/admin";
import RecruiterCandidateReport from "../../components/admin/Report/RecruiterReport";

// hooks
import useTitle from "../../components/hooks/useTitle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import withAuth, { AUTH_ROLES } from "../../components/HOC/withAuthGuard";
import useStatusReports from "../../components/react-query/reports/useStatusReports";
import useSourceWiseCandidateReport from "../../components/react-query/reports/useSourceWiseCandidateReport";
import CandidateSourceReport from "../../components/admin/Report/CandidateSourceReport";
import InteractionReport from "../../components/admin/Report/InteractionReport";
import RecruiterActivityReport from "../../components/admin/Report/RecruiterActivityReport";
import { ReportGroupBy } from "../../utils/constants";
import useCandidateMetaData from "../../components/react-query/candidate/useCandidateMetaData";
import CandidatelifeCycleReport from "../../components/admin/Report/CandidateLifycycleReport";

const Dashboard = () => {
  useTitle("Dashboard");
  // const [groupBy, setGroupBy] = useState<ReportGroupBy>(ReportGroupBy.MONTHLY);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const theme = useTheme();
  const { data: statusReport } = useStatusReports({
    groupBy: "monthly",
    start: startDate,
    end: endDate,
  });
  const monthlyReport = useStatusReports({
    start: `${moment().format("YYYY")}-01-01`,
    groupBy: "monthly",
  });
  const sourceReport = useSourceWiseCandidateReport({});
  const totalCandidateInDB = useCandidateMetaData({});

  return (
    <>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h4">Reports</Typography>
          <Box sx={{ display: "flex", gap: 3 }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Start date"
                  value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setStartDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="End date"
                  value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setEndDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              {/* <TextField
                select
                fullWidth
                value={groupBy}
                onChange={(e) => {
                  setGroupBy(e.target.value as ReportGroupBy);
                }}
              >
                {["daily", "weekly", "monthly", "yearly"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField> */}
            </Grid>
          </Box>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Total Applicants"
              total={totalCandidateInDB?.data || 0}
              icon={"bi:people-fill"}
              color="success"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Active Leads"
              total={
                statusReport?.reduce(
                  (prev: number, item: any) => prev + item.total,
                  0
                ) || 0
              }
              icon={"pepicons-pencil:people"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Selected Candidates"
              total={
                statusReport?.reduce(
                  (prev: number, item: any) => prev + item.selected,
                  0
                ) || 0
              }
              color="error"
              icon={"fluent-mdl2:leave-user"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="Rejected Candidates"
              total={
                statusReport?.reduce(
                  (prev: number, item: any) => prev + item.rejected,
                  0
                ) || 0
              }
              color="warning"
              icon={"tabler:user-x"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <AppWidgetSummary
              title="In-progress"
              total={
                statusReport?.reduce(
                  (prev: number, item: any) =>
                    prev + item.total - item.rejected - item.selected,
                  0
                ) || 0
              }
              color="info"
              icon={"mdi:people-add"}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <AppWebsiteVisits
              title={`${moment().format("YYYY")}'s Candidate Analysis`}
              subheader=""
              chartLabels={[...Array(12)]?.map(
                (item, index) =>
                  `${index < 9 ? "0" : ""}${index + 1}-02-${moment().format(
                    "YYYY"
                  )}`
              )}
              chartData={[
                {
                  name: "Rejected",
                  type: "area",
                  fill: "gradient",
                  data: [...Array(12)]?.map((item, index) => {
                    return (
                      monthlyReport?.data?.find(
                        (item: any) => item._id.month === index + 1
                      )?.rejected || 0
                    );
                  }),
                },
                {
                  name: "Selected",
                  type: "line",
                  fill: "solid",
                  data: [...Array(12)]?.map((item, index) => {
                    return (
                      monthlyReport?.data?.find(
                        (item: any) => item._id.month === index + 1
                      )?.selected || 0
                    );
                  }),
                },
                {
                  name: "Total",
                  type: "column",
                  fill: "solid",
                  data: [...Array(12)]?.map((item, index) => {
                    return (
                      monthlyReport?.data?.find(
                        (item: any) => item._id.month === index + 1
                      )?.total || 0
                    );
                  }),
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <AppCurrentVisits
              title="Source Report"
              chartData={
                sourceReport?.data?.map((item: any) => ({
                  label: item?._id?.source?.toUpperCase(),
                  value: item?.total,
                })) || []
              }
              chartColors={[
                theme.palette.primary.main,
                palette.chart.blue[0],
                palette.chart.violet[0],
                palette.chart.yellow[0],
              ]}
            />
          </Grid>
          <Grid item xs={12}>
            <CandidateSourceReport />
          </Grid>
          <Grid item xs={12}>
            <RecruiterCandidateReport />
          </Grid>
          <Grid item xs={12}>
            <InteractionReport />
          </Grid>

          <Grid item xs={12}>
            <RecruiterActivityReport />
          </Grid>

          <Grid item xs={12}>
            <CandidatelifeCycleReport />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withAuth(withLayout(Dashboard, AdminLayout), AUTH_ROLES.ADMIN);
