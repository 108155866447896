import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { JOB_DETAILS } from "../../admin/Job/CreateJobDetails";
import { Recruiter_Jobs_Schema } from "../../../utils/constants";
import { JOB } from "../jobs/useCreateJob";

const useGetJobsByRecruiter = ({
  skip = 0,
  limit = 20,
  active = true,
  name = "",
}: {
  skip?: number;
  limit?: number;
  active: boolean;
  name?: string;
}) => {
  const queryClient = useQueryClient();
  const fetchJobsData = () => {
    return axios
      .get(
        `/job/withApplication?skip=${skip}&limit=${limit}&active=${active}${
          name !== "" ? `&name=${name}` : ""
        }`
      )
      .then((res) => res.data || []);
  };

  return useQuery<JOB[]>(
    name === ""
      ? ["recruiter-applications", skip, skip + limit]
      : ["recruiter-applications", skip, skip + limit, name],
    fetchJobsData,
    {
      refetchOnMount: true,
      keepPreviousData: true,
    }
  );
};

export default useGetJobsByRecruiter;
