import { useEffect } from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import useModalState from "../../react-query/useModalState";
import CustomDialog from "../../CustomDialog";
import EditJobForm from "./EditJobForm";
import { JOB } from "../../react-query/jobs/useCreateJob";

const viewJobData = (
  key: string,
  value: string | boolean | number | undefined
) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6">{key}</Typography>
      <Typography>{value}</Typography>
      <Divider sx={{ mt: 2 }} />
    </Grid>
  );
};

const ViewJob = ({
  job,
  refetchJobs,
}: {
  job: JOB;
  refetchJobs: () => void;
}) => {
  const { modalState, open, close } = useModalState();

  useEffect(() => {
    if (modalState == false) {
      refetchJobs();
    }
  }, [modalState]);
  return (
    <>
      <Grid
        container
        spacing={3}
        sx={{
          bgcolor: "white",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: 1,
          p: 3.5,
          mt: 1,
          ml: 1,
          maxWidth: "80%",
          position: "relative",
          border: "1.4px solid #ddd",
        }}
      >
        <Box sx={{ position: "absolute", top: 12, right: 18 }}>
          <Button startIcon={<EditIcon />} onClick={open}>
            Edit
          </Button>
        </Box>
        {viewJobData("Job Role :", job?.name)}
        {/* {job?.active ? viewJobData("Active :", job?.active) : null} */}
        {job?.companyDescription && job?.companyDescription !== ""
          ? viewJobData("Company Description :", job?.companyDescription)
          : null}
        {job?.description
          ? viewJobData("Description :", job?.description)
          : null}
        {job?.salary ? viewJobData("Salary :", job?.salary) : null}
        {job?.openPosition
          ? viewJobData("Open Positions :", job.openPosition.toString())
          : null}
        {viewJobData("Years of Experience :", job?.yearOfExp)}
        {viewJobData("Expired :", moment(job?.expired).format("DD-MM-YYYY"))}
        {job?.noticePeriod
          ? viewJobData("Notice Period :", job?.noticePeriod)
          : null}
        {job?.openPosition
          ? viewJobData("Open Position :", job?.openPosition)
          : null}
        {job?.skills?.length
          ? viewJobData("Skills :", job?.skills?.join(", "))
          : null}
        {viewJobData("Job Description :", job?.requirement?.join(", "))}
        {/* {viewJobData("Responsibility :", job?.responsibility?.join(", "))} */}
        <Grid item xs={12} container spacing={2}>
          {job?.address?.addressLine1 ? (
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Location :
              </Typography>
              {/* <Typography variant="h6">Address Lane 1 :</Typography> */}
              <Typography>{job?.address?.addressLine1}</Typography>
              <Divider sx={{ mt: 2 }} />
            </Grid>
          ) : null}
          {/* {viewJobData("Address Lane 2 :", job?.address?.addressLine2)}
          {viewJobData("City :", job?.address?.city)}
          {viewJobData("State :", job?.address?.state)}
          {viewJobData("Country :", job?.address?.country)}
          {viewJobData(
            "Is Current :",
            job?.address?.isCurrent ? "True" : "False"
          )} */}
        </Grid>
      </Grid>

      <CustomDialog
        title={"Edit Job"}
        onClose={close}
        open={modalState}
        hideFooter={true}
        maxWidth="md"
      >
        <EditJobForm job={job} handleClose={close} />
      </CustomDialog>
    </>
  );
};

export default ViewJob;
