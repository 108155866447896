import React from "react";
import * as Yup from "yup";
import { useParams } from "react-router-dom";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";

// material ui
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// hocs & hooks
import useCreatePipeline from "../../react-query/pipeline/useCreatePipeline";
import { Pipeline_Schema } from "../../../utils/constants";

const AddRoundSchema = Yup.object().shape({
  key: STRING_VALIDATOR,
  label: STRING_VALIDATOR,
});

const CreatePipelineForm = ({ handleClose }: { handleClose: () => void }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddRoundSchema),
    defaultValues: { key: "", label: "" },
    mode: "onBlur",
  });

  const params = useParams();
  const { mutateAsync: createPipeline, isLoading: IsRoundGettingCreated } =
    useCreatePipeline();

  const onSubmit = async (values: { key: string; label: string }) => {
    try {
      const editedPipeline = await createPipeline({
        pipeline: [
          {
            key: values.key,
            label: values.label,
            data: undefined,
          },
        ],
        jobId: params?.jobId ? params.jobId : "",
        createdBy: "",
      } as Pipeline_Schema);
      handleClose();
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("key")}
              label="Key"
              error={Boolean(errors.key)}
              helperText={Boolean(errors.key) && errors.key?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              maxRows={2}
              fullWidth
              {...register("label")}
              label="Label"
              error={Boolean(errors.label)}
              helperText={Boolean(errors.label) && errors.label?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={IsRoundGettingCreated}
            >
              Add Round
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default CreatePipelineForm;
