import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import {
  Applicant_BY_JOB_SCHEMA,
  Candidate_Schema,
  Pipeline_Schema,
  User_Schema,
} from "../../../utils/constants";
import { useGetAllRecruiter } from "../../react-query/recruiter/useGetAllRecruiter";
import { JOB_DETAILS_SCHEMA } from "../Job/CreateJobDetails";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";
import { useGetAllJob } from "../../react-query/jobs/useGetAllJob";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMoveApplicant from "../../react-query/applicant/useMoveApplicant";
import axios from "axios";

interface Pipeline {
  key: string;
  label: string;
  data?: any[] | undefined;
}
interface ApplicanInput {
  jobId: JOB_DETAILS_SCHEMA | null | string | undefined;
  status: Pipeline | null | string | undefined;
  assignee: User_Schema | null | string | undefined;
}

const ApplicantFormSchema = Yup.object().shape({
  jobId: STRING_VALIDATOR,
  status: STRING_VALIDATOR,
  assignee: Yup.string(),
});

const MoveCandidateForm = ({
  candidate,
  close,
  role,
}: {
  candidate: Applicant_BY_JOB_SCHEMA;
  close: () => void;
  role: string | undefined;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ApplicanInput>({
    resolver: yupResolver(ApplicantFormSchema),
    defaultValues: { jobId: null, status: null },
    mode: "onChange",
  });

  const { data: jobData } = useGetAllJob();
  const { data: recruiterData } = useGetAllRecruiter();

  const [pipelineData, setPipelineData] = useState<Pipeline_Schema>();

  const [jobValue, setJobValue] = useState<JOB_DETAILS_SCHEMA | null>();
  const [statusValue, setStatusValue] = useState<Pipeline | null>();
  const [recruiterValue, setRecruiterValue] = useState<User_Schema | null>();
  const [candidateValue, setCandidateValue] =
    useState<Applicant_BY_JOB_SCHEMA>(candidate);

  const { mutate: moveApplicantMutate } = useMoveApplicant(
    close,
    candidate.applicationId
  );
  const onSubmit = (data: ApplicanInput) => {
    const responseData: {
      jobId: string | undefined;
      status: string | undefined;
      assignee?: string | undefined;
    } = {
      jobId: jobValue?.jobId,
      status: statusValue?.key,
    };

    if (recruiterValue?.userId) {
      responseData.assignee = recruiterValue?.userId;
    }

    moveApplicantMutate(responseData);
  };

  useEffect(() => {
    if (jobValue?.jobId) {
      (async () => {
        const res = await axios.get(`/pipeline/${jobValue?.jobId}`);
        setPipelineData(res.data);
      })();
    }
  }, [jobValue]);

  useEffect(() => {
    if (recruiterData?.length) {
      let currRecruiter = recruiterData.find(
        (d) => d.userId === candidate.assignee
      );
      setRecruiterValue(currRecruiter);
    }
  }, [recruiterData]);

  useEffect(() => {
    if (jobData?.length) {
      let currJob = jobData.find((d) => d.jobId === candidate.jobId);
      setJobValue(currJob);
    }
  }, [jobData]);

  return (
    <>
      {jobData?.length ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4}>
            {
              // role &&
              // role === "admin" &&
              recruiterData && recruiterData?.length ? (
                <Grid item xs={12}>
                  <Autocomplete
                    onChange={(event: any, newValue: User_Schema | null) => {
                      setRecruiterValue(newValue);
                      setValue("assignee", newValue?.userId, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                      });
                    }}
                    getOptionLabel={(option) =>
                      option?.firstName
                        ? `${option?.firstName} ${option?.lastName}`
                        : ""
                    }
                    options={recruiterData}
                    fullWidth
                    value={recruiterValue || null}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Please select a recruiter"
                        {...register("assignee")}
                        error={Boolean(errors.assignee)}
                        helperText={
                          Boolean(errors.assignee) && "This field is required"
                        }
                      />
                    )}
                  />
                </Grid>
              ) : null
            }
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event: any, newValue: JOB_DETAILS_SCHEMA | null) => {
                  setJobValue(newValue);
                  setValue("jobId", newValue?.name, {
                    shouldDirty: true,
                    shouldTouch: true,
                    shouldValidate: true,
                  });
                }}
                getOptionLabel={(option) => (option?.name ? option.name : "")}
                options={jobData}
                fullWidth
                value={jobValue || null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Please select a job"
                    {...register("jobId")}
                    error={Boolean(errors.jobId)}
                    helperText={
                      Boolean(errors.jobId) && "This field is required"
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              {pipelineData?.jobId && (
                <Autocomplete
                  onChange={(event: any, newValue: Pipeline | null) => {
                    setStatusValue(newValue);
                    setValue("status", newValue?.key, {
                      shouldDirty: true,
                      shouldTouch: true,
                      shouldValidate: true,
                    });
                  }}
                  getOptionLabel={(option) => option?.label}
                  options={pipelineData?.pipeline}
                  fullWidth
                  value={statusValue || null}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Round"
                      {...register("status")}
                      error={Boolean(errors?.status)}
                      helperText={
                        Boolean(errors?.status) && "This field is required"
                      }
                    />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <LoadingButton
            fullWidth
            type="submit"
            variant="contained"
            sx={{ my: 2 }}
          >
            Move Application
          </LoadingButton>
        </form>
      ) : null}
    </>
  );
};

export default MoveCandidateForm;
