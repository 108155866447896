import React from "react";

// form-hooks
import * as Yup from "yup";

import { useForm } from "react-hook-form";
import { EMAIL_VALIDATOR } from "../../utils/yup-validators";
import { yupResolver } from "@hookform/resolvers/yup";
import useForgetPassword from "../react-query/user/useForgetPassword";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField } from "@mui/material";

interface ForgetPasswardInput {
  email: string;
}

const ForgetPasswordSchema = Yup.object().shape({
  email: EMAIL_VALIDATOR,
});

const ForgetPasswordForm = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm<ForgetPasswardInput>({
    resolver: yupResolver(ForgetPasswordSchema),
  });

  const { mutate: forgetPasswordMutate } = useForgetPassword();
  const onSubmit = (values: ForgetPasswardInput) => {
    forgetPasswordMutate(values);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("email")}
              label="Email"
              type="email"
              error={Boolean(errors.email)}
              helperText={Boolean(errors.email) && errors.email?.message}
            />
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
          }}
        >
          Get Link
        </LoadingButton>
      </form>
    </Box>
  );
};

export default ForgetPasswordForm;
