import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//  mui
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// react-query
import useCreateProcess, {
  CREATE_PROCESS,
} from "../../react-query/process/useCreateProcess";

// utils
import { STRING_VALIDATOR } from "../../../utils/yup-validators";

const ProcessSchema = Yup.object().shape({
  name: STRING_VALIDATOR,
  createdBy: Yup.string(),
});

export default function CreateProcessForm({
  handleClose,
}: {
  handleClose: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CREATE_PROCESS>({
    resolver: yupResolver(ProcessSchema),
  });
  const { mutateAsync: createProcess } = useCreateProcess();

  const onSubmit = async (values: CREATE_PROCESS) => {
    try {
      const process = await createProcess({ ...values, createdBy: "" });
      handleClose();
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            label="Process name"
            fullWidth
            {...register("name")}
            variant="outlined"
            error={Boolean(errors.name)}
            helperText={Boolean(errors.name) && errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" variant="contained">
            Create Process
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
