import React, { useEffect, useState } from "react";
import moment from "moment";
import * as XLXS from "xlsx";

// component
import CustomLoader from "../../CustomLoader";
import SearchNotFound from "../../SearchNotFound";

// mui
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// hooks & HOCs
import axios from "axios";
import useRecruiterActivityReport from "../../react-query/reports/useRecruiterActivityReport";

const RecruiterActivityReport = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { data: reportData, isLoading } = useRecruiterActivityReport({
    start: startDate,
    end: endDate,
  });

  const handleDownloadActivity = async () => {
    try {
      // convert data to excel view
      let filteredArray = await reportData?.map((item: any) => {
        return {
          Source: item.candidate.source,
          "Assigned Date": moment
            .utc(item?.createdAt)
            .local()
            .format("DD-MM-YYYY"),
          "Recruiter Name":
            (item?.assignee?.firstName ? `${item?.assignee?.firstName} ` : "") +
            (item?.assignee?.lastName ? item?.assignee?.lastName : ""),
          Process: item.process.name,

          "Candidate Upload Date": moment
            .utc(item?.candidate?.createdAt)
            .local()
            .format("DD-MM-YYYY"),
          "Candidate Name":
            (item?.candidate?.firstName
              ? `${item?.candidate?.firstName} `
              : "") +
            (item?.candidate?.lastName ? item?.candidate?.lastName : ""),
          Email: item?.candidate?.email ? `${item?.candidate?.email} ` : "",
          "Contact No": item.candidate.phone,
          "Last Interaction": item?.interactions?.length
            ? item.interactions[item.interactions.length - 1].status
            : "NA",
          "Interaction Sub Category":
            item?.interactions?.length &&
            item.interactions[item.interactions.length - 1]?.subStatus
              ? item.interactions[item.interactions.length - 1].subStatus
              : "NA",
          "Last Interaction Date": moment
            .utc(item?.interaction?.createdAt)
            .local()
            .format("DD-MM-YYYY"),
          "No Of Interaction": item.interactions.length,
        };
      });

      const wb = XLXS.utils.book_new();
      const ws = XLXS.utils.json_to_sheet(filteredArray);
      XLXS.utils.book_append_sheet(wb, ws, "test");
      XLXS.writeFile(wb, `activity_${startDate}_${endDate}.csv`);
    } catch (error) {}
  };

  const columns = [
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.candidate?.source
          ? `${params?.row?.candidate?.source} `
          : "NA";
      },
    },
    {
      field: "recruiterName",
      headerName: "Recruiter Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          (params?.row?.assignee?.firstName
            ? `${params?.row?.assignee?.firstName} `
            : "") +
          (params?.row?.assignee?.lastName
            ? params?.row?.assignee?.lastName
            : "")
        );
      },
    },
    {
      field: "process",
      headerName: "Process",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.process?.name
          ? `${params?.row?.process?.name} `
          : "NA";
      },
    },

    {
      field: "candidateName",
      headerName: "Candidate Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          (params?.row?.candidate?.firstName
            ? `${params?.row?.candidate?.firstName} `
            : "") +
          (params?.row?.candidate?.lastName
            ? params?.row?.candidate?.lastName
            : "")
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.candidate?.email
          ? `${params?.row?.candidate?.email} `
          : "";
      },
    },
    {
      field: "phone",
      headerName: "Contact No",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.candidate?.phone
          ? `${params?.row?.candidate?.phone}`
          : "NA";
      },
    },
    {
      field: "lastInteraction",
      headerName: "Last Interactions",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.interactions?.length &&
          params?.row?.interactions[params?.row?.interactions?.length - 1]
            ?.status
          ? params?.row?.interactions[params?.row?.interactions?.length - 1]
              ?.status
          : "NA";
      },
    },
    // {
    //   field: "finalStatus",
    //   headerName: "Final status",
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params: any) => {
    //     return params?.row?.statusHistory?.status &&
    //       ["REJECTED", "SELECTED"].includes(
    //         params?.row?.statusHistory?.status?.toUpperCase()
    //       )
    //       ? params?.row?.statusHistory?.status?.toUpperCase()
    //       : "Other";
    //   },
    // },
    {
      field: "total Interactions",
      headerName: "Total Interactions",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.interactions?.length;
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Activity Report</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Grid container spacing={4} sx={{ p: 2 }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="Start date"
                  value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setStartDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="End date"
                  value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setEndDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button variant="contained" onClick={handleDownloadActivity}>
                Download
              </Button>
            </Grid>
          </Grid>
          {!!reportData && reportData?.length ? (
            <div
              style={{
                maxHeight: 600,
                marginTop: "1rem",
              }}
            >
              <DataGrid
                autoHeight
                rows={
                  reportData?.map((row: any, index: any) => {
                    return { id: row._id, ...row };
                  }) || []
                }
                columns={columns}
                // hideFooterPagination
                sx={{
                  paddingInline: "0.5rem",
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-columnHeaderRow": {
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                  },

                  "& .MuiDataGrid-row": {
                    "&:first-child": {
                      borderTop: "1px solid rgba(0,0,0,0.1)",
                    },
                  },
                }}
              />
            </div>
          ) : isLoading ? (
            <CustomLoader />
          ) : (
            <Box sx={{ height: "480px" }}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RecruiterActivityReport;
