import { Link, useNavigate } from "react-router-dom";

// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Container, Box } from "@mui/material";

// components
import withLayout from "./HOC/withLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
import useTitle from "./hooks/useTitle";

// ----------------------------------------------------------------------

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function Page404() {
  useTitle("404 Page Not Found");
  const navigate = useNavigate();
  return (
    <Container>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>

        <Typography sx={{ color: "text.secondary" }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve
          mistyped the URL? Be sure to check your spelling.
        </Typography>

        <Box
          component="img"
          src="/images/illustration_404.svg"
          sx={{ height: 260, mx: "auto", my: { xs: 5 } }}
        />

        <Link to="/" style={{ textDecoration: "none" }}>
          <Button
            size="large"
            variant="contained"
            onClick={() => navigate("/")}
          >
            Go to Home
          </Button>
        </Link>
      </ContentStyle>
    </Container>
  );
}

export default withLayout(Page404, LogoOnlyLayout);
