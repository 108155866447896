import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { MAPPER } from "./useCreateMapper";

export default function useMapper({
  limit = 20,
  skip = 0,
}: {
  skip: number;
  limit: number;
}) {
  const fetchMapperData = () => {
    return axios
      .get(`/mapper?limit=${limit}&skip=${skip}`)
      .then((res) => res.data || []);
  };
  const queryClient = useQueryClient();

  return useQuery<MAPPER[]>(["mapper", skip, skip + limit], fetchMapperData, {
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    onSuccess: (newProcesses: MAPPER[]) => {
      const previousProcesses: MAPPER[] | undefined = queryClient.getQueryData([
        "mappers",
      ]);
      let allProcesses = previousProcesses
        ? [...previousProcesses, ...newProcesses]
        : [...newProcesses];
      queryClient.setQueryData(["mappers"], [...allProcesses]);
    },
  });
}
