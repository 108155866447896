import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { PROCESS } from "./useCreateProcess";

export default function useProcess({
  limit = 12,
  skip = 0,
  active,
}: {
  skip: number;
  limit: number;
  active?: boolean;
}) {
  const fetchProcessData = () => {
    let url = `/process?limit=${limit}&skip=${skip}`;
    if (active !== undefined) {
      url = url + `&active=${active}`;
    }

    return axios.get(url).then((res) => res.data || []);
  };
  const queryClient = useQueryClient();

  return useQuery<PROCESS[]>(
    ["process", skip, skip + limit],
    fetchProcessData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (newProcesses: PROCESS[]) => {
        const previousProcesses: PROCESS[] | undefined =
          queryClient.getQueryData(["processes"]);
        let allProcesses = previousProcesses
          ? [...previousProcesses, ...newProcesses]
          : [...newProcesses];
        queryClient.setQueryData(["processes"], [...allProcesses]);
      },
    }
  );
}
