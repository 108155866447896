import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  hideClose?: boolean;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, hideClose = false, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        !!!hideClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null
      ) : null}
    </DialogTitle>
  );
}

export default function CustomDialog({
  title,
  open,
  setOpen,
  children,
  maxWidth,
  onClose,
  hideFooter,
  hideClose = false,
}: {
  title: string;
  open: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  maxWidth?: DialogProps["maxWidth"];
  onClose: () => void;
  hideFooter?: boolean;
  hideClose?: boolean;
}) {
  return (
    <>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={maxWidth || "xs"}
        sx={{
          "& .MuiDialog-paperWidthXs": {
            maxWidth: "420px",
          },
          "& .MuiDialog-paperWidthSm": {
            maxWidth: "480px",
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={onClose}
          hideClose={hideClose}
        >
          {title || ""}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {!hideFooter && (
          <DialogActions>
            <Button autoFocus onClick={onClose}>
              Close
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </>
  );
}
