import { useMutation } from "react-query";
import { CREATE_CANDIDATE } from "./useCreateCandidate";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Candidate_Schema } from "../../../utils/constants";

export interface CANDIDATE extends CREATE_CANDIDATE {
  candidateId: string;
}

const useEditCandidate = (id: string) => {
  const navigate = useNavigate();

  return useMutation(
    (values: CANDIDATE) =>
      axios.patch(`/candidate/${id}`, values).then((res) => res.data),
    {
      onSuccess: (newCandidates: Candidate_Schema) => {
        toast.success("Candidate updated successfully");
        navigate(-1);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useEditCandidate;
