import React, { useEffect, useState } from "react";
import moment from "moment";
import * as XLXS from "xlsx";

// component
import CustomLoader from "../../CustomLoader";
import SearchNotFound from "../../SearchNotFound";

// mui
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// hooks & HOCs
import useRecruiterCandidatesReport from "../../react-query/reports/useRecruiterCandidatesReport";
import { ReportGroupBy } from "../../../utils/constants";
import axios from "axios";

interface RecruiterDataInterface {
  _id: {
    day?: string;
    week?: string;
    month?: string;
    year?: string;
    assignee?: string;
  };
  selected?: number;
  rejected?: number;
  total?: number;
  assignee: {
    _id?: string;
    userId?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    role?: string;
    active?: boolean;
    __v: number;
  };
}

const RecruiterCandidateReport = () => {
  const [groupBy, setGroupBy] = useState<ReportGroupBy>(ReportGroupBy.MONTHLY);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { data: reportData, isLoading } = useRecruiterCandidatesReport({
    groupBy,
    start: startDate,
    end: endDate,
  });

  const handleDownloadRecruiter = async (data: RecruiterDataInterface[]) => {
    try {
      const filteredArray = data.map((obj: RecruiterDataInterface) => {
        let localStartDate = moment();
        let localEndDate = moment();
        if (obj?._id?.year) {
          localStartDate = localStartDate.year(Number(obj._id.year));
          localEndDate = localEndDate.year(Number(obj._id.year));
        }
        if (obj?._id?.month) {
          localStartDate = localStartDate.month(Number(obj._id.month) - 1);
          localEndDate = localEndDate.month(Number(obj._id.month));
        }
        if (obj?._id?.week) {
          localStartDate = localStartDate.week(Number(obj._id.week));
          localEndDate = localEndDate.week(Number(obj._id.week + 1));
        }
        if (obj?._id?.day) {
          localStartDate = localStartDate.dayOfYear(Number(obj._id.day));
          localEndDate = localEndDate.dayOfYear(Number(obj._id.day + 1));
        }
        const newObj = {
          "Start Date": localStartDate.format("DD-MM-YYYY"),
          "End Date": localEndDate.subtract(1, "day").format("DD-MM-YYYY"),
          Total: obj?.total,
          Selected: obj?.selected,
          Rejected: obj?.rejected,
          Name: obj?.assignee?.firstName + " " + obj?.assignee?.lastName,
          Phone: obj?.assignee?.phone,
          Email: obj?.assignee?.email,
        };

        return newObj;
      });

      const wb = XLXS.utils.book_new();
      const ws = XLXS.utils.json_to_sheet(filteredArray);
      XLXS.utils.book_append_sheet(wb, ws, "test");
      XLXS.writeFile(wb, `Recruiter_Report_${startDate}_${endDate}.csv`);
    } catch (error) {}
  };

  const columns = [
    {
      field: "time",
      headerName: "Duration",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        let startDate = moment();
        if (params?.row?._id?.year) {
          startDate = startDate.year(Number(params?.row._id.year));
        }
        if (params?.row?._id?.month) {
          startDate = startDate.month(Number(params?.row._id.month) - 1);
        }
        if (params?.row?._id?.week) {
          startDate = startDate.week(Number(params?.row._id.week));
        }
        if (params?.row?._id?.day) {
          startDate = startDate.dayOfYear(Number(params?.row._id.day));
        }
        return startDate.format("LL");
      },
    },
    {
      field: "name",
      headerName: "Recruiter Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          (params?.row?.assignee?.firstName
            ? `${params?.row?.assignee?.firstName} `
            : "") +
          (params?.row?.assignee?.lastName
            ? params?.row?.assignee?.lastName
            : "")
        );
      },
    },
    {
      field: "total",
      headerName: "Total Candidates",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "rejected",
      headerName: "Rejected",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "selected",
      headerName: "Selected",
      flex: 1,
      minWidth: 100,
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Recruiter Report</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Grid container spacing={4} sx={{ p: 2 }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="Start date"
                  value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setStartDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="End date"
                  value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setEndDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <TextField
                select
                fullWidth
                value={groupBy}
                onChange={(e) => {
                  setGroupBy(e.target.value as ReportGroupBy);
                }}
              >
                {["daily", "weekly", "monthly", "yearly"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              {reportData?.length ? (
                <Button
                  variant="contained"
                  onClick={() => handleDownloadRecruiter(reportData)}
                  sx={{ mt: 1, ml: 3 }}
                >
                  Download
                </Button>
              ) : null}
            </Grid>
          </Grid>
          {!!reportData && reportData?.length ? (
            <div
              style={{
                maxHeight: 600,
                marginTop: "1rem",
              }}
            >
              <DataGrid
                autoHeight
                rows={
                  reportData?.map((row: any, index: any) => {
                    return { id: index, ...row };
                  }) || []
                }
                columns={columns}
                // hideFooterPagination
                sx={{
                  paddingInline: "0.5rem",
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-columnHeaderRow": {
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                  },

                  "& .MuiDataGrid-row": {
                    "&:first-child": {
                      borderTop: "1px solid rgba(0,0,0,0.1)",
                    },
                  },
                }}
              />
            </div>
          ) : isLoading ? (
            <CustomLoader />
          ) : (
            <Box sx={{ height: "480px" }}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RecruiterCandidateReport;
