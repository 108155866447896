import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// mui
import {
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

// react-query
import { PROCESS } from "../../react-query/process/useCreateProcess";
import useEditProcess from "../../react-query/process/useEditProcess";

// component
import Iconify from "../../Iconify";
import CustomDialog from "../../CustomDialog";

// hoc & hooks
import useModalState from "../../react-query/useModalState";

function ProcessCard({
  process,
  openUpdateDialog,
}: {
  process: PROCESS;
  openUpdateDialog: (p: PROCESS) => void;
}) {
  const { mutateAsync: updateProcess } = useEditProcess(process.processId);

  const { name, jobIds, active, processId } = { ...process };
  const navigate = useNavigate();

  const handleViewProcess = () => {
    navigate(`/admin/process/${processId}`);
  };

  // delete Process Modal states
  const {
    modalState: IsOpenDeleteProcessDialog,
    open: handleOpenDeleteProcessDialog,
    close: handleCloseDeleteProcessDialog,
  } = useModalState();

  const deleteProcess = async () => {
    try {
      await updateProcess({ active: false } as PROCESS);
      handleCloseDeleteProcessDialog();
    } catch (error) {}
  };

  return (
    <Paper
      sx={{
        p: 2,
        margin: "auto",
        flexGrow: 1,
        boxShadow: (theme) => theme.shadows[8],
      }}
    >
      <Grid container>
        <Grid item xs>
          <Typography gutterBottom variant="subtitle1" component="div">
            {name}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary">
            {`ID: ${processId}`}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {`Total jobs : ${jobIds.length}`}
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Tooltip title={"Edit process"}>
              <IconButton
                color={"info"}
                onClick={() => {
                  openUpdateDialog(process);
                }}
              >
                <Iconify icon={"material-symbols:edit"} />
              </IconButton>
            </Tooltip>
            <Tooltip title={"View Jobs"}>
              <IconButton color={"warning"} onClick={handleViewProcess}>
                <Iconify icon={"mdi:eye"} />
              </IconButton>
            </Tooltip>
          </Grid>
          {active ? (
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
              <Tooltip title={"Delete process"}>
                <IconButton
                  color={"error"}
                  onClick={handleOpenDeleteProcessDialog}
                >
                  <Iconify icon={"ic:round-delete"} />
                </IconButton>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <CustomDialog
        title={`Delete Process - ${name}(ID: ${processId})`}
        onClose={handleCloseDeleteProcessDialog}
        open={IsOpenDeleteProcessDialog}
        hideFooter={true}
        maxWidth="xs"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">Are you sure?</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={2}
          >
            <Button variant="outlined" onClick={handleCloseDeleteProcessDialog}>
              Cancel
            </Button>
            <Button variant="contained" onClick={deleteProcess}>
              Delete
            </Button>
          </Grid>
        </Grid>
      </CustomDialog>
    </Paper>
  );
}

export default ProcessCard;
