import { useState } from "react";
import { toast } from "react-toastify";

// mui
import {
  Button,
  Grid,
  Typography,
  Tab,
  Tabs,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";

// hoc
import withLayout from "../../components/HOC/withLayout";
import useTitle from "../../components/hooks/useTitle";

// layout
import AdminLayout from "../../components/layouts/admin";

// hooks
import useValidateBulkCandidate from "../../components/react-query/candidate/useValidateBulkCandidate";

// components
import BulkUploadMapper from "../../components/BulkUpload/BulkUploadMapper";
import {
  CREATE_BULK_CANDIDATE,
  CREATE_CANDIDATE,
} from "../../components/react-query/candidate/useCreateCandidate";
import SaveCandidateDialog from "../../components/BulkUpload/SaveCandidateDialog";
import useModalState from "../../components/react-query/useModalState";
import CustomDialog from "../../components/CustomDialog";
import SearchNotFound from "../../components/SearchNotFound";
import { Candidate_Schema, FIELD } from "../../utils/constants";
import withAuth, { AUTH_ROLES } from "../../components/HOC/withAuthGuard";
import CustomLoader from "../../components/CustomLoader";
import { TabPanel, a11yProps } from "../../components/TabComponent";
import { useGetAllRecruiter } from "../../components/react-query/recruiter/useGetAllRecruiter";

const BulkUpload = () => {
  useTitle("Upload Candidates");

  const { data: recruiters, isLoading } = useGetAllRecruiter();

  const {
    mutateAsync: validateBulkCandidate,
    isLoading: IsValidatingBulkCandidate,
  } = useValidateBulkCandidate();

  const [candidates, setCandidates] = useState<CREATE_BULK_CANDIDATE[]>([]);
  const [excelCandidates, setExcelCandidates] = useState<any[]>([]);
  const [excelKeys, setExcelKeys] = useState<
    { excelKey: string; beKey: FIELD }[]
  >([]);
  const [inValidCandidates, setInValidCandidates] = useState<any[]>([]);
  const [validCandidates, setValidCandidates] = useState<any[]>([]);

  // modal for view candidate details
  const {
    modalState: candidateViewModalState,
    open: openCandidateModal,
    close: closeCandidateModal,
  } = useModalState();

  const updateCandidateForPreview = async (
    convertedCandidate: CREATE_CANDIDATE[]
  ) => {
    let previousCandidates: Candidate_Schema[] = [];
    let excelEmailKey = "";
    if (excelKeys.findIndex((e) => e.beKey === "email") !== -1) {
      let key = excelKeys.find((e) => e.beKey === "email")?.excelKey;
      excelEmailKey = key ? key : "";
    } else {
      toast.error("Email is not mapped");
    }

    let excelPhoneKey = "";
    if (excelKeys.findIndex((e) => e.beKey === "phone") !== -1) {
      let key = excelKeys.find((e) => e.beKey === "phone")?.excelKey;
      excelPhoneKey = key ? key : "";
    } else {
      toast.error("Phone number is not mapped");
    }
    let x: { old: Candidate_Schema[]; new: any[] };
    try {
      x = await validateBulkCandidate({
        candidates: convertedCandidate,
      });
      previousCandidates = x.old;
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Validation Failed");
      return;
    }

    await setValidCandidates(
      x.new.map((c) => {
        let localCandidate = excelCandidates.find(
          (con) =>
            c.email === con?.[excelEmailKey] || c.phone === con?.[excelPhoneKey]
        );
        return {
          ...localCandidate,
          assignee: c.assignee,
          assigneeDetails: c.assigneeDetails,
        };
      })
    );

    await setInValidCandidates(
      excelCandidates.filter(
        (c) =>
          previousCandidates?.findIndex((prev) => {
            if (c?.[excelEmailKey] && c?.[excelPhoneKey]) {
              return (
                c[excelEmailKey] === prev.email ||
                c[excelPhoneKey] === prev.phone
              );
            } else {
              return true;
            }
          }) !== -1
      )
    );
    await setCandidates(
      x.new.map((c) => {
        let localCandidate = convertedCandidate.find(
          (con) => c.email === con.email || c.phone === con.phone
        ) as CREATE_BULK_CANDIDATE;
        return {
          ...localCandidate,
          assignee: c.assignee,
        };
      })
    );
    await openCandidateModal();
  };

  // warning dialog for saving candidate
  const [isOpenCandidateSavingDialog, setIsOpenCandidateSavingDialog] =
    useState<boolean>(false);
  const candidateSavingWarningDialog = () => {
    setIsOpenCandidateSavingDialog(false);
    closeCandidateModal();
  };

  // tabs for showing valid/invalid Candidates
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Grid container spacing={4} sx={{ px: 4 }}>
      <Grid item xs={12}>
        <Typography variant="h4">Bulk upload</Typography>
      </Grid>
      <Grid item xs={12}>
        <BulkUploadMapper
          updateCandidates={updateCandidateForPreview}
          setExcelCandidates={setExcelCandidates}
          setExcelKeys={setExcelKeys}
        />
      </Grid>

      <CustomDialog
        title={"Preview Candidate"}
        onClose={closeCandidateModal}
        open={candidateViewModalState}
        hideFooter={true}
        maxWidth="lg"
      >
        <Grid
          container
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabValue} onChange={handleChange}>
                <Tab label="Valid Candidates" {...a11yProps(0)} />
                <Tab label="Duplicate Candidates" {...a11yProps(1)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              {validCandidates.length && excelKeys.length ? (
                <DataGrid
                  columns={[
                    {
                      field: "assigneeChange",
                      headerName: "Change Recruiter",
                      renderCell: (params: GridRenderCellParams) => (
                        <Autocomplete
                          onChange={async (event: any, newValue) => {
                            let excelEmailKey = "";
                            if (
                              excelKeys.findIndex(
                                (e) => e.beKey === "email"
                              ) !== -1
                            ) {
                              let key = excelKeys.find(
                                (e) => e.beKey === "email"
                              )?.excelKey;
                              excelEmailKey = key ? key : "";
                            }
                            let localValidCadidates = validCandidates.map(
                              (v) => {
                                if (
                                  v[excelEmailKey] ===
                                  params.row?.[excelEmailKey]
                                ) {
                                  return {
                                    ...v,
                                    assignee: newValue.userId,
                                    assigneeDetails: newValue,
                                  };
                                } else {
                                  return { ...v };
                                }
                              }
                            );

                            let updateLocalCandidates = candidates.map((v) => {
                              if (v.email === params.row?.[excelEmailKey]) {
                                return {
                                  ...v,
                                  assignee: newValue.userId,
                                };
                              } else {
                                return { ...v };
                              }
                            });

                            await setValidCandidates([...localValidCadidates]);
                            await setCandidates(updateLocalCandidates);
                          }}
                          sx={{ width: "100%" }}
                          value={params.row.assigneeDetails}
                          options={recruiters ? recruiters : []}
                          getOptionLabel={(option) =>
                            `${option.firstName} ${option.lastName}`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Choose a Recruiter"
                              variant="outlined"
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      ),
                      width: 200,
                      headerAlign: "center",
                      align: "center",
                    },
                    {
                      field: "assignee",
                      headerName: "Recruiter",
                      valueGetter: (params: GridValueGetterParams) =>
                        params.row?.assigneeDetails
                          ? params.row.assigneeDetails?.firstName +
                            " " +
                            params.row.assigneeDetails?.lastName
                          : "NA",
                      width: 200,
                      headerAlign: "center",
                      align: "center",
                    },
                    ...excelKeys.map(
                      (key) =>
                        ({
                          field: key.excelKey,
                          headerName: key.excelKey,
                          valueGetter: (params: GridValueGetterParams) =>
                            params.row?.[key.excelKey]
                              ? params.row[key.excelKey]
                              : "NA",
                          width: 200,
                          headerAlign: "center",
                          align: "center",
                        } as GridColDef<any>)
                    ),
                  ]}
                  rows={validCandidates.map((c, index) => ({
                    ...c,
                    id: index,
                  }))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                />
              ) : (
                <SearchNotFound searchQuery={"No Candidates uploaded"} />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {inValidCandidates.length && excelKeys.length ? (
                <DataGrid
                  columns={excelKeys.map((key) => ({
                    field: key.excelKey,
                    headerName: key.excelKey,
                    valueGetter: (params: GridValueGetterParams) =>
                      params.row?.[key.excelKey]
                        ? params.row[key.excelKey]
                        : "NA",
                    width: 200,
                    headerAlign: "center",
                    align: "center",
                  }))}
                  rows={inValidCandidates.map((c, index) => ({
                    ...c,
                    id: index,
                  }))}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                />
              ) : (
                <SearchNotFound searchQuery={"No Candidates uploaded"} />
              )}
            </TabPanel>
          </Grid>
        </Grid>

        {candidates.length && excelKeys.length ? (
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                setIsOpenCandidateSavingDialog(true);
              }}
            >
              Save Candidates
            </Button>
          </Grid>
        ) : null}
      </CustomDialog>

      <Grid item>
        <SaveCandidateDialog
          open={isOpenCandidateSavingDialog}
          handleClose={candidateSavingWarningDialog}
          candidates={candidates}
        />
      </Grid>
      {IsValidatingBulkCandidate ? (
        <Grid item>
          <CustomLoader />
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withAuth(withLayout(BulkUpload, AdminLayout), AUTH_ROLES.ADMIN);
