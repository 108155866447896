import React, { useState } from "react";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";
import SearchNotFound from "../../SearchNotFound";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { ViewData } from "../../ResuableUI";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useModalState from "../../react-query/useModalState";
import CustomDialog from "../../CustomDialog";
import ViewCandidateDetails from "../../../pages/admin/applicant/ViewCandidateDetails";
import { useQueryClient } from "react-query";

const ViewApplication = ({
  data,
}: {
  data: Applicant_BY_JOB_SCHEMA[] | undefined;
}) => {
  const queryClient = useQueryClient();
  const [selectedCandidate, setSelectedCandidate] =
    useState<Applicant_BY_JOB_SCHEMA | null>(null);

  const {
    modalState: candidateViewModalState,
    open: openCandidateModal,
    close: closeCandidateModal,
  } = useModalState();
  return (
    <>
      {data?.length ? (
        <>
          <Grid container spacing={3}>
            {data?.map((applicant) => (
              <Grid item xs={12} lg={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    p: 3,
                    borderRadius: 1.6,
                    border: "1px solid #ddd",
                  }}
                  key={applicant?._id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: -2,
                      mb: -1,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "rgba(0,0,0,0.7)" }}
                    >
                      {applicant?.candidate?.firstName}{" "}
                      {applicant?.candidate?.lastName}
                    </Typography>
                    <IconButton
                      color="primary"
                      sx={{ mr: -2 }}
                      onClick={async () => {
                        await queryClient.setQueryData(
                          ["candidate", applicant?.candidateId || ""],
                          { ...applicant.candidate }
                        );
                        await setSelectedCandidate({ ...applicant });
                        await openCandidateModal();
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Box>
                  {ViewData("Phone :", applicant?.candidate?.phone || "")}
                  {ViewData("Email :", applicant?.candidate?.email || "")}
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <SearchNotFound searchQuery={"No Results Found"} />
      )}

      {selectedCandidate ? (
        <CustomDialog
          title={`${
            selectedCandidate?.candidate?.firstName
              ? selectedCandidate?.candidate?.firstName
              : ""
          } ${
            selectedCandidate?.candidate?.lastName
              ? selectedCandidate?.candidate?.lastName
              : ""
          }`}
          onClose={closeCandidateModal}
          open={candidateViewModalState}
          hideFooter={true}
          maxWidth="lg"
        >
          {selectedCandidate?.candidate ? (
            <ViewCandidateDetails
              candidate={selectedCandidate.candidate}
              application={selectedCandidate}
            />
          ) : null}
        </CustomDialog>
      ) : null}
    </>
  );
};

export default ViewApplication;
