import React from "react";
// component
import Iconify from "../../Iconify";
import { NavItem } from "./NavSection";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);

const navConfig: NavItem[] = [
  {
    title: "Jobs",
    path: "/recruiter/jobs",
    icon: getIcon("material-symbols:patient-list-outline"),
    subPath: ["/recruiter/jobs/:jobId"],
  },
  {
    title: "Add Candidate",
    path: "/recruiter/add-candidate",
    icon: getIcon("mdi:user-add-outline"),
  },

  {
    title: "Manage Applicant",
    path: "/recruiter/applicant",
    icon: getIcon("ic:outline-people-alt"),
    subPath: ["/recruiter/applicant/:id"],
  },
];

export default navConfig;
