import {
  Applicant_BY_JOB_SCHEMA,
  Comment_Schema,
} from "../../../utils/constants";
import SearchNotFound from "../../../components/SearchNotFound";
import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";

const ViewApplicantComment = ({
  applications,
}: {
  applications: Applicant_BY_JOB_SCHEMA[];
}) => {
  return (
    <>
      {applications?.reduce(
        (prev, curr) =>
          prev + (curr.comments?.length ? curr.comments?.length : 0),
        0
      ) ? (
        applications?.map((applicant) => (
          <Grid container key={applicant?._id} spacing={1} sx={{ p: 3 }}>
            {applicant?.comments?.map((comment: Comment_Schema, index) => (
              <Grid item xs={12} key={index}>
                <Box>
                  <Typography>
                    <b>
                      {comment?.firstName ? `${comment?.firstName} ` : ""}
                      {comment?.lastName ? `${comment?.lastName} ` : ""}
                    </b>
                    <span style={{ fontSize: 14, color: "rgba(0,0,0,0.65)" }}>
                      {comment?.comment}
                    </span>
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ color: "rgba(0,0,0,0.65)" }}
                  >
                    ({moment.utc(comment?.createdAt).format("DD-MM-YYYY")})
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
              </Grid>
            ))}
          </Grid>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SearchNotFound searchQuery={"No Results Found"} />
        </Box>
      )}
    </>
  );
};

export default ViewApplicantComment;
