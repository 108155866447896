import axios from "axios";
import { useQuery } from "react-query";
import { Candidate_Schema } from "../../../utils/constants";

export default function useGetCandidateById(id?: string) {
  const fetchApplicantData = () => {
    return axios.get(`/candidate/${id}`).then((res) => res.data || []);
  };

  return useQuery<Candidate_Schema>(["candidate", id], fetchApplicantData, {
    refetchOnWindowFocus: false,
    cacheTime: Infinity,
  });
}
