import axios from "axios";
import moment from "moment";
import { useQuery, useQueryClient } from "react-query";

export default function useStatusReports({
  groupBy = "yearly",
  start = moment().subtract(1, "years").format("YYYY-MM-DD"),
  end = moment().format("YYYY-MM-DD"),
}: {
  groupBy?: string;
  start?: string;
  end?: string;
}) {
  const fetchStatusReportsData = () => {
    let url = `/application/report/status?groupBy=${groupBy}&start=${start}&end=${end}`;
    return axios.get(url).then((res) => res.data || []);
  };
  const queryClient = useQueryClient();

  return useQuery(["statusReport", groupBy, start], fetchStatusReportsData, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
}
