import axios from "axios";
import moment from "moment";
import { useQuery } from "react-query";

export default function useInteractionReport({
  start = moment().subtract(1, "years").format("YYYY-MM-DD"),
  end = moment().format("YYYY-MM-DD"),
}: {
  start?: string;
  end?: string;
}) {
  const fetchStatusReportsData = () => {
    let url = `/application/report/interaction?start=${start}&end=${end}`;
    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery(["RecruiterInteractionReport"], fetchStatusReportsData, {
    keepPreviousData: true,
    refetchOnMount: true,
  });
}
