import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";

// hooks
import useResponsive from "../../hooks/useResponsive";

// components
// import account from "../../components/_mock/account";
import Scrollbar from "../../Scrollbar";
import NavSection from "./NavSection";
import Logo from "../../Logo";
//
import navConfig from "./NavConfig";
import useLogout from "../../react-query/user/useLogout";
import { User_Schema } from "../../../utils/constants";
import { useQuery } from "react-query";
// import { useQueryClient } from "react-query";
// import { userAuthInterface } from "../../interfaces/userInterface";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }: { theme: any }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }: { theme: any }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[400],
}));

// ----------------------------------------------------------------------

interface Props {
  isOpenSidebar: boolean;
  onCloseSidebar: any;
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const {
    data: userData,
    isLoading,
    isFetched,
  }: {
    data: User_Schema | undefined;
    isLoading: boolean;
    isFetched: boolean;
  } = useQuery<User_Schema>("userData");
  const navigate = useNavigate();

  const isDesktop = useResponsive("up", "lg");

  const { mutate: logoutMutate } = useLogout();

  const renderContent = (
    <>
      <Scrollbar
        sx={{
          height: 1,
          "& .simplebar-content": {
            height: 1,
            display: "flex",
            flexDirection: "column",
          },
          bgcolor: "white",
        }}
      >
        <Box
          sx={{
            px: 2.5,
            py: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Logo />
        </Box>

        {userData?.email ? (
          <Box sx={{ mb: 5, mx: 2.5 }}>
            <Link underline="none" href="#">
              <AccountStyle>
                <Box sx={{ ml: 2 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{ color: "text.primary" }}
                  >
                    {userData?.email &&
                      `${userData?.firstName ? userData?.firstName : ""} ${
                        userData?.lastName ? userData?.lastName : ""
                      }`}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary", textOverflow: "ellipsis" }}
                  >
                    {userData?.email && userData.email}
                  </Typography>
                </Box>
              </AccountStyle>
            </Link>
          </Box>
        ) : null}

        <NavSection navConfig={navConfig} />

        <Box sx={{ flexGrow: 1 }} />

        <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
          <Stack
            alignItems="center"
            spacing={3}
            sx={{ pt: 5, borderRadius: 2, position: "relative" }}
          >
            {/* <Box
              component="img"
              src="/images/client.jpg"
              sx={{ width: 100, position: "absolute", top: -50 }}
            />

            <Box sx={{ textAlign: "center" }}>
              <Typography gutterBottom variant="h6">
                Get more?
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                From only $69
              </Typography>
            </Box> */}

            <Button
              // href="https://material-ui.com/store/items/minimal-dashboard/"
              // target="_blank"
              onClick={async () => {
                await logoutMutate();
              }}
              variant="contained"
              fullWidth
              color="secondary"
            >
              Logout
            </Button>
          </Stack>
        </Box>
      </Scrollbar>
    </>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "white",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
