import React from "react";
// component
import Iconify from "../../Iconify";
import { NavItem } from "./NavSection";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);

const navConfig: NavItem[] = [
  {
    title: "Process",
    path: "/admin/process",
    icon: getIcon("material-symbols:team-dashboard-outline"),
    subPath: ["/admin/process/:id", "/admin/process/:id/:jobId"],
  },
  {
    title: "Add Recruiter",
    path: "/admin/create-recruiter",
    icon: getIcon("healthicons:health-worker-form"),
  },
  {
    title: "Bulk upload",
    path: "/admin/bulk-upload",
    icon: getIcon("mdi:account-upload"),
  },
  {
    title: "Add Candidate",
    path: "/admin/add-candidate",
    icon: getIcon("mdi:user-add-outline"),
  },

  {
    title: "Manage Applicant",
    path: "/admin/applicant",
    icon: getIcon("ic:outline-people-alt"),
    subPath: ["/admin/applicant/:id"],
  },
  {
    title: "Report",
    path: "/admin",
    icon: getIcon("material-symbols:team-dashboard-outline"),
    subPath: ["/admin"],
  },
];

export default navConfig;
