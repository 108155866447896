import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const useUploadResume = (candidateId: string, refetch?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: FormData) =>
      axios
        .put(`/candidate/${candidateId}/resume`, values)
        .then((res) => res.data),
    {
      onSuccess: (res) => {
        if (refetch) {
          refetch();
        }
        queryClient.setQueryData(["candidate", candidateId], { ...res });

        toast.success("Resume uploaded successfully");
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useUploadResume;
