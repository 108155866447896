import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// layout
import AdminLayout from "../../../components/layouts/admin";

// mui
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";

// hocs & hooks
import withLayout from "../../../components/HOC/withLayout";
import { useGetJobDetails } from "../../../components/react-query/jobs/useGetJobDetails";
import { useGetApplicantByJobId } from "../../../components/react-query/applicant/useGetApplicantByJobId";
import { useGetPipelineByJob } from "../../../components/react-query/pipeline/useGetPipelineByJob";
import { useQuery } from "react-query";

// components
import CustomLoader from "../../../components/CustomLoader";
import ViewJob from "../../../components/admin/Job/ViewJob";
import SearchNotFound from "../../../components/SearchNotFound";
import JobPipelineView from "../../../components/admin/Job/JobPipelineView";
import ViewApplication from "../../../components/admin/Applicant/ViewApplication";
import { TabPanel, a11yProps } from "../../../components/TabComponent";

// utils
import {
  Applicant_BY_JOB_SCHEMA,
  ApplicationStatus,
  Pipeline_Schema,
} from "../../../utils/constants";
import useTitle from "../../../components/hooks/useTitle";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";

const View = () => {
  const [tabValue, setTabValue] = React.useState(0);

  const params = useParams();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const {
    isLoading,
    data: jobDetails,
    refetch: refetchJobs,
  } = useGetJobDetails(params.jobId);
  useTitle(jobDetails ? jobDetails?.name : "Pipeline view of Job");

  useGetApplicantByJobId(params?.jobId);
  const {
    isLoading: applicationLoading,
    data: applicationData,
    refetch: applicationRefetch,
  } = useQuery<Applicant_BY_JOB_SCHEMA[]>(["applicant", params?.jobId]);
  const [rejectedCandidates, setRejectedCandidates] =
    useState<Applicant_BY_JOB_SCHEMA[]>();
  const [selectedCandidates, setSelectedCandidates] = useState<
    Applicant_BY_JOB_SCHEMA[]
  >([]);

  useGetPipelineByJob(params?.jobId);
  const {
    isLoading: isPipelineLoading,
    isFetching: isPipelineFetching,
    data: pipelineData,
    refetch,
  } = useQuery<Pipeline_Schema>(["pipeline", params?.jobId]);

  const [pipelineOptimizeData, setPipelineOptimizeData] =
    useState<Pipeline_Schema>();

  useEffect(() => {
    if (pipelineData?.pipeline?.length) {
      let newPipelineData = { ...pipelineData };

      let localRejectedArray: Applicant_BY_JOB_SCHEMA[] = [];
      let localSelectedArray: Applicant_BY_JOB_SCHEMA[] = [];

      newPipelineData?.pipeline?.every((item) => (item.data = []));

      if (applicationData?.length) {
        applicationData?.map((data) => {
          if (data?.status === ApplicationStatus.REJECTED) {
            localRejectedArray.push(data);
          } else if (data?.status === ApplicationStatus.SELECTED) {
            localSelectedArray.push(data);
          } else {
            let searchIndex = newPipelineData?.pipeline?.findIndex((object) => {
              return object.key === data?.status;
            });
            if (searchIndex !== -1) {
              newPipelineData.pipeline[searchIndex].data?.push(data);
            }
          }
        });
      }

      setPipelineOptimizeData({ ...newPipelineData });
      setRejectedCandidates([...localRejectedArray]);
      setSelectedCandidates([...localSelectedArray]);
    }
  }, [applicationData, pipelineData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5">
            {jobDetails
              ? `${jobDetails.name} (ID: ${jobDetails.jobId})`
              : "Job"}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Jobs" {...a11yProps(0)} />
                <Tab label="Job Details" {...a11yProps(1)} />
                <Tab label="Rejected Candidates" {...a11yProps(2)} />
                <Tab label="Selected Candidates" {...a11yProps(3)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              {
                <JobPipelineView
                  pipelineOptimizeData={pipelineOptimizeData}
                  isLoading={isPipelineLoading}
                  isFetching={isPipelineFetching}
                />
              }
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={1}>
              {isLoading ? (
                <CustomLoader />
              ) : jobDetails?.name ? (
                <ViewJob job={jobDetails} refetchJobs={refetchJobs} />
              ) : (
                <SearchNotFound searchQuery={"No Results Found"} />
              )}
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={2}>
              <ViewApplication data={rejectedCandidates} />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={3}>
              <ViewApplication data={selectedCandidates} />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default withAuth(withLayout(View, AdminLayout), AUTH_ROLES.ADMIN);
