import { useQuery } from "react-query";
import { JOB_DETAILS_SCHEMA } from "../../admin/Job/CreateJobDetails";
import axios from "axios";

export const useGetAllJob = () => {
  const fetchJobDetails = () => {
    return axios.get(`/job?active=true`).then((res) => res.data);
  };

  return useQuery<JOB_DETAILS_SCHEMA[]>(["jobs"], fetchJobDetails, {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};
