import { useEffect } from "react";
import { BRAND } from "../../utils/constants";

export default function useTitle(title: string) {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${title} | ${BRAND}`;
    return () => {
      document.title = prevTitle;
    };
  });
}
