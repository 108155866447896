import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { JOB_DETAILS } from "../../admin/Job/CreateJobDetails";
import { JOB } from "./useCreateJob";

const useGetJobsByPagination = ({
  skip = 0,
  limit = 20,
  processId,
  active,
  withCandidateCount = false,
}: {
  skip: number;
  limit: number;
  processId?: string;
  active?: boolean;
  withCandidateCount?: boolean;
}) => {
  const queryClient = useQueryClient();
  const fetchJobsData = () => {
    return axios
      .get(
        `job?skip=${skip}&limit=${limit}${
          processId !== undefined ? `&processId=${processId}` : ""
        }${
          active !== undefined ? `&active=${active}` : ""
        }&withCandidateCount=${withCandidateCount.toString()}`
      )
      .then((res) => res.data || []);
  };

  return useQuery<JOB[]>(
    ["jobs", processId, skip, limit + skip],
    fetchJobsData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export default useGetJobsByPagination;
