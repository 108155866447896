import axios from "axios";
import { useMutation } from "react-query";

// utils
import { User_Schema } from "../../../utils/constants";
import { toast } from "react-toastify";

const useCreateRecruiter = (reset: () => void) => {
  return useMutation(
    (values: User_Schema) =>
      axios.post("/auth/signup", values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Recruiter created successfully");
        reset();
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useCreateRecruiter;
