import { useState } from "react";
import { Link, useLocation, matchPath } from "react-router-dom";

// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";
//
import Iconify from "../../Iconify";

// ----------------------------------------------------------------------

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

export interface NavItem {
  title: string;
  path: string;
  icon: React.ReactNode;
  info?: string;
  children?: NavItem[];
  subPath?: string[];
}

interface NavItemProps {
  item: NavItem;
  active: (item: NavItem) => boolean;
}

function NavItem({ item, active }: NavItemProps) {
  const theme = useTheme();

  const isActiveRoot: boolean = active(item);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState<boolean>(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  if (children) {
    return (
      <>
        <ListItemButton
          onClick={handleOpen}
          sx={{
            height: 48,
            position: "relative",
            textTransform: "capitalize",
            color: theme.palette.text.secondary,
            borderRadius: theme.shape.borderRadius,
            ...(isActiveRoot && activeRootStyle),
            ...theme.typography.body2,
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={
              open
                ? "eva:arrow-ios-downward-fill"
                : "eva:arrow-ios-forward-fill"
            }
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item: NavItem) => {
              const { title, path } = item;
              const isActiveSub = active(item);

              return (
                <Link to={path} style={{ textDecoration: "none" }}>
                  <ListItemButton
                    key={title}
                    sx={{
                      height: 48,
                      position: "relative",
                      textTransform: "capitalize",
                      color: theme.palette.text.secondary,
                      borderRadius: theme.shape.borderRadius,
                      ...(isActiveSub && activeSubStyle),
                      ...theme.typography.body2,
                    }}
                  >
                    <ListItemIconStyle>
                      <Box
                        component="span"
                        sx={{
                          width: 4,
                          height: 4,
                          display: "flex",
                          borderRadius: "50%",
                          alignItems: "center",
                          justifyContent: "center",
                          bgcolor: "text.disabled",
                          transition: (theme) =>
                            theme.transitions.create("transform"),
                          ...(isActiveSub && {
                            transform: "scale(2)",
                            bgcolor: "primary.main",
                          }),
                        }}
                      />
                    </ListItemIconStyle>
                    <ListItemText disableTypography primary={title} />
                  </ListItemButton>
                </Link>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Link to={path} style={{ textDecoration: "none" }}>
      <ListItemButton
        sx={{
          height: 48,
          position: "relative",
          textTransform: "capitalize",
          color: theme.palette.text.secondary,
          borderRadius: theme.shape.borderRadius,
          ...(isActiveRoot && activeRootStyle),
          ...theme.typography.body2,
        }}
      >
        <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        <ListItemText disableTypography primary={title} />
        {info && info}
      </ListItemButton>
    </Link>
  );
}

interface NavSectionProps {
  navConfig: NavItem[];
}

export default function NavSection({ navConfig, ...other }: NavSectionProps) {
  const { pathname } = useLocation();

  const matchSubPaths = (subPathList: string[]) => {
    let matchFound = false;

    subPathList?.map((subPath) =>
      matchPath(subPath, pathname) ? (matchFound = true) : null
    );

    return matchFound;
  };

  const match: (item: NavItem) => boolean = (item: NavItem) => {
    return item.path
      ? !!(
          pathname === item.path ||
          (item?.subPath?.length && matchSubPaths(item.subPath))
        )
      : false;
  };

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item: NavItem) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
