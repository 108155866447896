import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//  mui
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// react-query
import { PROCESS } from "../../react-query/process/useCreateProcess";
import useEditProcess from "../../react-query/process/useEditProcess";

// utils
import { STRING_VALIDATOR } from "../../../utils/yup-validators";

const ProcessSchema = Yup.object().shape({
  name: STRING_VALIDATOR,
  // active: Yup.boolean(),
});

export default function EditProcessForm({
  process,
  handleClose,
}: {
  process: PROCESS;
  handleClose: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<PROCESS>({
    defaultValues: {
      name: process.name,
      //  active: process.active
    },
    resolver: yupResolver(ProcessSchema),
  });
  const { mutateAsync: updateProcess } = useEditProcess(
    process.processId,
    true
  );

  const onSubmit = async (values: PROCESS) => {
    try {
      const process = await updateProcess({ ...values });
      handleClose();
    } catch (error) {}
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            label="Process name"
            fullWidth
            {...register("name")}
            variant="outlined"
            error={Boolean(errors.name)}
            helperText={Boolean(errors.name) && errors.name?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" variant="contained">
            Edit Process
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
