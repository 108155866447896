import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { Candidate_Schema } from "../../../utils/constants";

export default function useSearchCandidate({ name }: { name?: string }) {
  const fetchApplicantData = () => {
    let url = `/candidate?name=${name}&email=${name}&phone=${name}`;
    url = url + `&isAvailable=true`;

    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery<Candidate_Schema[]>(
    ["search", "candidate", name],
    fetchApplicantData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      enabled: Boolean(name?.length),
    }
  );
}
