import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { PROCESS } from "./useCreateProcess";

export default function useSearchProcess({ name }: { name?: string }) {
  const fetchProcessData = () => {
    let url = `/process?name=${name}`;
    
    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery<PROCESS[]>(["search", "process", name], fetchProcessData, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
