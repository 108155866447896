import * as Yup from "yup";

const ERR_REQ = "This Field is Required";

export const EMAIL_VALIDATOR = Yup.string()
  .email("Please enter a valid email")
  .max(255)
  .required(ERR_REQ);

export const PHONE_VALIDATOR = Yup.string()
  .matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "Please enter valid phone number"
  )
  .required(ERR_REQ);

export const PASSWORD_VALIDATOR = Yup.string()
  .required(ERR_REQ)
  .min(8, "Minimum Length Required is 8")
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
    "Must Contain At least 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
  );
export const CONFIRM_PASSWORD_VALIDATOR = Yup.string()
  .test("passwords-match", "Passwords must match", function (value) {
    return this.parent.password === value;
  })
  .required(ERR_REQ);
export const OTP_VALIDATOR = Yup.string()
  .required(ERR_REQ)
  .min(6, "OTP must be 6 digits long")
  .max(6, "OTP must be 6 digits long");

export const STRING_VALIDATOR = Yup.string().required(ERR_REQ);
export const YYYYMMDD_VALIDATOR = Yup.string()
  .required(ERR_REQ)
  .matches(
    /^\d{4}(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[01])$/,
    "Please Enter Valid Date"
  );
