import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

// utils
import {
  Applicant_BY_JOB_SCHEMA,
  Pipeline_Obj_Schema,
  Pipeline_Schema,
} from "../../../utils/constants";
import { useQuery, useQueryClient } from "react-query";

// mui
import { Box, Button, Container, IconButton, Tooltip } from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";

// component
import PipelineRoundView from "./PipelineRoundView";
import PipelineRoundHead from "./PipelineRoundHead";
import useModalState from "../../react-query/useModalState";
import ViewCandidateDetails from "../../../pages/admin/applicant/ViewCandidateDetails";
import CustomDialog from "../../CustomDialog";
import AddRoundPipelineForm from "./AddRoundPipelineForm";
import CreateComment from "../CreateComment";
import SortIcon from "@mui/icons-material/Sort";

export interface SortingListObj {
  date: string;
  interaction: string;
  key: string;
  sort: boolean;
}

const PipelineView = ({ data }: { data: Pipeline_Schema }) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const sortingListData: SortingListObj[] | undefined =
    queryClient.getQueryData(["sorting-list"]);

  const { data: pipelineData } = useQuery<Pipeline_Schema>([
    "pipeline",
    params?.jobId,
  ]);
  const [columns, setColumns] = useState<Pipeline_Obj_Schema[]>([]);

  const {
    open: openCommentModal,
    close: closeCommentModal,
    modalState: commentModalState,
  } = useModalState();

  useEffect(() => {
    pipelineData && setColumns(pipelineData?.pipeline);
  }, [pipelineData]);

  useEffect(() => {
    if (sortingListData?.length) return;

    const filterList: SortingListObj[] = [];
    pipelineData?.pipeline?.map((data) => {
      let localObj = { key: data?.key, interaction: "", date: "", sort: false };
      filterList.push(localObj);
    });
    queryClient.setQueryData(["sorting-list"], filterList);
  }, [columns]);

  // edit application query

  const [selectCandidate, setSelectCandidate] = useState<
    { applicant: Applicant_BY_JOB_SCHEMA; status: string } | undefined
  >();

  useEffect(() => {
    if (!commentModalState) {
      setSelectCandidate(undefined);
    }
  }, [commentModalState]);

  //
  const onDragEnd = async (result: any, columns: any, setColumns: any) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.data];
      const destItems = [...destColumn.data];

      let [removed] = sourceItems.splice(
        source.index,
        1
      ) as Applicant_BY_JOB_SCHEMA[];

      try {
        setSelectCandidate({
          applicant: removed,
          status: destColumn.key,
        });

        openCommentModal();
        if (selectCandidate === null) {
          destItems.splice(destination.index, 0, removed);
          setColumns({
            ...columns,
            [source.droppableId]: {
              ...sourceColumn,
              data: sourceItems,
            },
            [destination.droppableId]: {
              ...destColumn,
              data: destItems,
            },
          });
        }
      } catch (error) {
        return;
      }
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.data];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          data: copiedItems,
        },
      });
    }
  };

  // selected candidate
  const [selectedCandidate, setSelectedCandidate] =
    useState<Applicant_BY_JOB_SCHEMA | null>(null);

  const getSelectedCandiidate = async (c: Applicant_BY_JOB_SCHEMA) => {
    await queryClient.setQueryData(
      ["candidate", c?.candidate?.candidateId || ""],
      c.candidate
    );
    await setSelectedCandidate(c);
  };

  // modal for view candidate details
  const {
    modalState: candidateViewModalState,
    open: openCandidateModal,
    close: closeCandidateModal,
  } = useModalState();

  useEffect(() => {
    if (selectedCandidate) {
      openCandidateModal();
    }
  }, [selectedCandidate]);

  useEffect(() => {
    if (!!!candidateViewModalState) {
      setSelectedCandidate(null);
    }
  }, [candidateViewModalState]);

  // modal for add round
  const {
    modalState: addRoundModalState,
    open: openAddRoundModal,
    close: closeAddRoundModal,
  } = useModalState();

  const sortingListView = (
    sortData: SortingListObj,
    columnData: Applicant_BY_JOB_SCHEMA[] | undefined
  ) => {
    let filterColumn3: Applicant_BY_JOB_SCHEMA[] = [];
    if (columnData?.length) {
      if (sortData?.date && sortData?.interaction) {
        let localList = columnData?.filter((data) => {
          let index = data?.interactions?.findIndex(
            (data) => data.status === sortData.interaction
          );

          if (data?.interactions && index !== undefined) {
            return (
              data?.interactions[index]?.status === sortData?.interaction &&
              data?.createdAt?.slice(0, 10) === sortData?.date
            );
          }
        });
        filterColumn3 = [...localList];
      } else if (sortData?.date) {
        let localList = columnData?.filter(
          (data) => data?.createdAt?.slice(0, 10) === sortData?.date
        );

        filterColumn3 = [...localList];
      } else if (sortData?.interaction) {
        let localList = columnData?.filter((data) => {
          let index = data?.interactions?.findIndex(
            (data) => data.status === sortData.interaction
          );

          if (data?.interactions && index !== undefined) {
            console.log(
              "datadata",
              data?.interactions[index]?.status === sortData.interaction
            );
            return data?.interactions[index]?.status === sortData?.interaction;
          }
        });
        filterColumn3 = [...localList];
      }
    }
    return filterColumn3;
  };

  return (
    <Box>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        <Container>
          <Box sx={{ display: "flex", gap: 2 }}>
            {Object.entries(columns).map(
              ([columnId, column], pipelineIndex) => {
                const getSortingListIndex = sortingListData?.findIndex(
                  (data) => data.key === column.key
                );
                return (
                  <Droppable key={columnId} droppableId={columnId}>
                    {(provided, snapshot) => (
                      <Box
                        sx={{
                          p: 3,
                          mt: 3,
                          mx: 2,
                          borderRadius: 1,
                          border: `1px solid #ddd`,
                          bgcolor: "white.main",
                          minWidth: "24rem",
                          height: "90vh",
                          overflow: "hidden",
                          overflowY: "auto",
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          "&::-webkit-scrollbar": {
                            width: "0.2rem",
                            height: "0.2rem",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(0,0,0,0.3)",
                            outline: "1px solid slategrey",
                            borderRadius: 4,
                          },
                        }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {pipelineData?.pipeline?.length ? (
                          <PipelineRoundHead
                            column={column}
                            pipeline={pipelineData}
                            pipelineIndex={pipelineIndex}
                          />
                        ) : null}

                        {sortingListData?.length &&
                        getSortingListIndex !== undefined &&
                        sortingListData[getSortingListIndex]?.sort
                          ? sortingListView(
                              sortingListData[getSortingListIndex],
                              column?.data
                            )?.map((data: any, index: number) => (
                              <PipelineRoundView
                                key={data?._id}
                                data={data}
                                index={index}
                                selectCandidate={getSelectedCandiidate}
                              />
                            ))
                          : column?.data?.map((data, index) => (
                              <PipelineRoundView
                                key={data?._id}
                                data={data}
                                index={index}
                                selectCandidate={getSelectedCandiidate}
                              />
                            ))}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                );
              }
            )}

            <Box
              sx={{
                mt: 3,
                p: 3,
                mx: 3,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 1,
                border: `1px solid #ddd`,
                minWidth: "24rem",
                height: "90vh",
                overflow: "hidden",
                overflowY: "auto",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "white.main",
                },
              }}
              onClick={openAddRoundModal}
            >
              <Button
                variant="text"
                startIcon={<AddRoundedIcon />}
                sx={{ mb: 16 }}
              >
                Add Round
              </Button>
            </Box>
          </Box>
        </Container>
      </DragDropContext>

      {selectedCandidate ? (
        <CustomDialog
          title={`${
            selectedCandidate?.candidate?.firstName
              ? selectedCandidate?.candidate?.firstName
              : ""
          } ${
            selectedCandidate?.candidate?.lastName
              ? selectedCandidate?.candidate?.lastName
              : ""
          }`}
          onClose={closeCandidateModal}
          open={candidateViewModalState}
          hideFooter={true}
          maxWidth="lg"
        >
          {selectedCandidate?.candidate ? (
            <ViewCandidateDetails
              pipelineData={data}
              candidate={selectedCandidate.candidate}
              application={selectedCandidate}
            />
          ) : null}
        </CustomDialog>
      ) : null}

      <CustomDialog
        open={addRoundModalState}
        title={"Add Round"}
        onClose={closeAddRoundModal}
        hideFooter={true}
        maxWidth="xs"
      >
        {pipelineData ? (
          <AddRoundPipelineForm
            handleClose={closeAddRoundModal}
            pipeline={pipelineData}
            addIndex={pipelineData.pipeline.length}
          />
        ) : null}
      </CustomDialog>

      {selectCandidate?.applicant?._id ? (
        <CustomDialog
          title={"Add Comment"}
          onClose={closeCommentModal}
          open={commentModalState}
          hideFooter={true}
          maxWidth="sm"
          hideClose
        >
          <CreateComment
            applicant={selectCandidate?.applicant}
            rejected={false}
            close={closeCommentModal}
            status={selectCandidate?.status}
          />
        </CustomDialog>
      ) : null}
    </Box>
  );
};

export default PipelineView;
