import { useEffect, useState } from "react";
import * as XLXS from "xlsx";

// mui
import {
  Button,
  Chip,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Iconify from "../Iconify";
import SaveMappingDialog from "./SaveMappingDialog";
import { FIELD, fieldToLabelMapper } from "../../utils/constants";
import PreviewMappingsDialog from "./PreviewMappingsDialog";
import { MAPPER } from "../react-query/mapping/useCreateMapper";
import MapperSelectorDialog from "./MapperSelectorDialog";
import {
  convertExcelCandidatesToBeSchema,
  getObjectOfExcelKeytoBeKey,
} from "../../utils/functions";
import { CREATE_CANDIDATE } from "../react-query/candidate/useCreateCandidate";
import { toast } from "react-toastify";

function BulkUploadMapper({
  updateCandidates,
  setExcelCandidates,
  setExcelKeys,
}: {
  updateCandidates: (c: CREATE_CANDIDATE[]) => void;
  setExcelCandidates: React.Dispatch<React.SetStateAction<any[]>>;
  setExcelKeys: React.Dispatch<
    React.SetStateAction<
      {
        excelKey: string;
        beKey: FIELD;
      }[]
    >
  >;
}) {
  const [showMapperUI, setshowMapperUI] = useState<boolean>(false);

  const [allExcelKeys, setAllExcelKeys] = useState<string[]>([]);
  const [remainingMappingColumns, setRemainingMappingColumns] = useState<
    string[]
  >([]);

  // excel data that are uploaded converted to JSON with excel keys
  const [excelCandidateData, setExcelCandidateData] = useState<any[]>([]);

  // array used for mapping values of excel keys to be keys
  const [mappingValues, setMappingValues] = useState<
    { excelKey: string; beKey: FIELD }[]
  >([]);

  useEffect(() => {
    setExcelKeys([...mappingValues]);
  }, [mappingValues]);

  // read excel file
  const readExcelFile = (file: File) => {
    const promise = new Promise((resolve: (data: any[]) => void, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e?.target?.result;

        const wb = XLXS.read(bufferArray, { type: "buffer" });
        const wbRows = XLXS.read(bufferArray, { type: "buffer", sheetRows: 1 });

        const rowData: string[][] = XLXS.utils.sheet_to_json(
          wbRows.Sheets[wbRows.SheetNames[0]],
          {
            header: 1,
            raw: false,
          }
        );

        let map = new Map();
        let rows: string[] = [];
        // getting column header in array
        for (let i = 0; i < rowData[0].length; i++) {
          let item = rowData[0][i];
          if (!item) {
            item = "__EMPTY";
          }
          if (map.has(item)) {
            let mapValue = map.get(item);
            rows.push(`${item}_${mapValue}`);
            map.set(item, mapValue + 1);
          } else {
            rows.push(item);
            map.set(item, 1);
          }
        }

        // reading candidates
        const candidates = XLXS.utils.sheet_to_json(
          wb.Sheets[wb.SheetNames[0]],
          {
            raw: false,
          }
        );
        resolve([{ candidates, mapper: rows }]);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then(async (data: { candidates: any[]; mapper: string[] }[]) => {
      toast.success("Excel Uploaded");
      let remainingField: string[] = [];
      let savedMapping = mappingValues;

      data[0].mapper.forEach((mapperItem: string, index: number) => {
        let fieldIndex = savedMapping.findIndex(
          (item) => item.excelKey === mapperItem
        );
        if (fieldIndex === -1) {
          remainingField.push(mapperItem);
        }
      });
      setRemainingMappingColumns(remainingField);
      setAllExcelKeys(data[0].mapper);
      setExcelCandidateData(data[0].candidates);
      setExcelCandidates(data[0].candidates);
      setshowMapperUI(true);
    });
  };

  // manual mapper
  const [excelSelectedKey, setExcelSelectedKey] = useState<string>("");
  const [mappedSelectedKey, setMappedSelectedKey] = useState<string>("");
  const appendMapping = async () => {
    if (mappedSelectedKey === "" || excelSelectedKey === "") {
      return;
    }
    let mappinng: { excelKey: string; beKey: FIELD; label: string } = {
      label: fieldToLabelMapper[mappedSelectedKey as FIELD],
      beKey: mappedSelectedKey as FIELD,
      excelKey: excelSelectedKey,
    };
    await setMappingValues((prev) => [...prev, { ...mappinng }]);
    await setRemainingMappingColumns((prev) =>
      prev.filter((data) => data !== excelSelectedKey)
    );
    await setExcelSelectedKey("");
    await setMappedSelectedKey("");
  };
  const removeMapping = async (index: number) => {
    let data = mappingValues;
    await setRemainingMappingColumns((prev) => [...prev, data[index].excelKey]);
    data.splice(index, 1);
    await setMappingValues([...data]);
  };

  // dialog of save mapper
  const [isOpenSaveMapperDialog, setIsOpenSaveMapperDialog] =
    useState<boolean>(false);
  const closeSaveMapperDialog = () => {
    setIsOpenSaveMapperDialog(false);
  };

  // selecting saved mapper
  const [selectedMapper, setSelectedMapper] = useState<MAPPER | null>(null);
  const [isUsingSavedMapper, setIsUsingSavedMapper] = useState<boolean>(false);
  const [isOpenSelectionMapperDialog, setIsOpenSelectionMapperDialog] =
    useState<boolean>(false);
  const closeSelectionMapperDialog = () => {
    setIsOpenSelectionMapperDialog(false);
  };
  const useSelectedMapper = async (m: MAPPER) => {
    await setSelectedMapper(m);
    await setMappingValues(m.mappings);

    // remaining mapping values (manual set)
    let remainingField: string[] = [];
    let savedMapping = m.mappings;
    allExcelKeys.forEach((mapperItem: string, index: number) => {
      let fieldIndex = savedMapping.findIndex(
        (item) => item.excelKey === mapperItem
      );
      if (fieldIndex === -1) {
        remainingField.push(mapperItem);
      }
    });
    await setRemainingMappingColumns(remainingField);

    await setIsUsingSavedMapper(true);
    await setshowMapperUI(true);
    closeSelectionMapperDialog();
  };

  // preview mapping dialog
  const [isOpenMappingPreviewDialog, setIsOpenMappingPreviewDialog] =
    useState<boolean>(false);
  const closeMappingPreviewDialog = () => {
    setIsOpenMappingPreviewDialog(false);
  };

  // initiate preview of candiates
  const intiatePreviewCandidates = () => {
    // get object for excel key to be key mapping
    const excelToBeKeyMapper = getObjectOfExcelKeytoBeKey(mappingValues);

    // convert excel data in BE schema
    const c = convertExcelCandidatesToBeSchema(
      excelCandidateData,
      excelToBeKeyMapper
    );
    updateCandidates(c);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          startIcon={
            <Iconify icon="material-symbols:arrow-selector-tool-rounded" />
          }
          onClick={() => {
            setIsOpenSelectionMapperDialog(true);
          }}
        >
          Select Mapper
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          startIcon={<Iconify icon="mdi:eye" />}
          onClick={() => setIsOpenMappingPreviewDialog(true)}
        >
          Preview & Save Mapping
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          component="label"
          color="secondary"
          startIcon={<Iconify icon="material-symbols:drive-folder-upload" />}
        >
          Upload Excel
          <input
            // ref={inputFile}

            hidden
            accept=".xlsx, .csv"
            multiple={false}
            type="file"
            onClick={() => {}}
            onChange={async (e) => {
              let files = e.target?.files;
              files && (await readExcelFile(files[0]));
            }}
          />
        </Button>
      </Grid>

      {/* mapper UI */}
      <Grid item xs={12} container spacing={2}>
        <Grid
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">Manual Mapping</Typography>
          <Button
            variant="contained"
            startIcon={<Iconify icon="fluent:save-copy-20-filled" />}
            onClick={() => setIsOpenSaveMapperDialog(true)}
          >
            Save Mapping
          </Button>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          {mappingValues.map((item, index) => (
            <Grid
              item
              xs={12}
              md={6}
              key={index}
              display={"flex"}
              gap={2}
              alignItems={"center"}
            >
              <Chip label={item.excelKey} color="warning" /> -
              <Chip label={fieldToLabelMapper[item.beKey]} color="info" />
              <Tooltip title={"Remove this mapping"}>
                <IconButton
                  color="error"
                  onClick={() => {
                    removeMapping(index);
                  }}
                >
                  <Iconify icon="ic:baseline-delete" />
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            select
            label="Select Column of Excel"
            value={excelSelectedKey}
            helperText="Please select column name specified in excel"
            onChange={(e) => {
              setExcelSelectedKey(e.target.value);
            }}
          >
            {remainingMappingColumns.map((item: string, index) => (
              <MenuItem key={index + item} value={item}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            select
            label="Select Mapping"
            value={mappedSelectedKey}
            helperText="Please select mapping from BE"
            onChange={(e) => {
              setMappedSelectedKey(e.target.value);
            }}
          >
            {(Object.entries(fieldToLabelMapper) as [FIELD, string][]).map(
              ([key, label]: [FIELD, string], index: number) => (
                <MenuItem key={index + key} value={key}>
                  {label}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            onClick={appendMapping}
          >
            Append Mapping
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={intiatePreviewCandidates}>
          Preview Uploaded Data
        </Button>
      </Grid>

      {/* save manual mapper to database */}
      <SaveMappingDialog
        open={isOpenSaveMapperDialog}
        handleClose={closeSaveMapperDialog}
        mappings={mappingValues}
      />

      {/* preview current using mapper */}
      <PreviewMappingsDialog
        open={isOpenMappingPreviewDialog}
        handleClose={closeMappingPreviewDialog}
        mappings={mappingValues}
      />

      {/* select saved mapper */}
      <MapperSelectorDialog
        selectedMapper={selectedMapper}
        open={isOpenSelectionMapperDialog}
        handleClose={closeSelectionMapperDialog}
        handleSelectionMapper={useSelectedMapper}
      />
    </Grid>
  );
}

export default BulkUploadMapper;
