import axios from "axios";
import moment from "moment";
import { useQuery } from "react-query";

export default function useSourceWiseCandidateReport({
  groupBy = "yearly",
  start = moment().subtract(1, "years").format("YYYY-MM-DD"),
  end = moment().format("YYYY-MM-DD"),
}: {
  groupBy?: string;
  start?: string;
  end?: string;
}) {
  const fetchSourceReportsData = () => {
    let url = `/candidate/report/source?start=${start}&end=${end}&groupBy=${groupBy}`;
    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery(
    ["sourceReport", groupBy, start, end],
    fetchSourceReportsData,
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
    }
  );
}
