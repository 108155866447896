import { useState } from "react";
import { toast } from "react-toastify";

//  mui
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// react-query
import { CREATE_CANDIDATE } from "../react-query/candidate/useCreateCandidate";

// hooks
import useBulkUploadCandidate from "../react-query/candidate/useBulkUploadCandidate";
import { useNavigate } from "react-router-dom";
import { useGetAllJob } from "../react-query/jobs/useGetAllJob";
import { JOB_DETAILS_SCHEMA } from "../admin/Job/CreateJobDetails";
import { GENERIC_ERROR } from "../../utils/constants";

export default function SaveCandidateDialog({
  candidates,
  open,
  handleClose,
}: {
  candidates: CREATE_CANDIDATE[];
  open: boolean;
  handleClose: () => void;
}) {
  const { mutateAsync: createMapper } = useBulkUploadCandidate();
  const { data: jobData, isLoading: isJobLoading } = useGetAllJob();
  const [selectedJob, setSelectedJob] = useState<null | JOB_DETAILS_SCHEMA>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    await setLoading(true);
    try {
      let payload: any = { candidates: candidates };
      const added = await createMapper({ ...payload });
      toast.success("Candidates added");
      navigate(`/admin/applicant`);
      await setLoading(false);
      await handleClose();
    } catch (error: any) {
      toast.error(error?.response?.data?.message || GENERIC_ERROR);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{`Are you sure to save candidates?`}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText color={"error"}>
          ** This candidates will be saved permanently. After this operation, we
          can't undo this **
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <LoadingButton onClick={onSubmit} variant="contained" loading={loading}>
          Save Candidates
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
