import { useQuery } from "react-query";
import axios from "axios";
import { Candidate_Schema } from "../../../utils/constants";

export const useCandidateLifecycle = ({ values }: { values?: string }) => {
  const fetchCandidateDetails = () => {
    if (!values?.length) return [];
    return axios
      .get(`/candidate/report/lifecycle?${values}`)
      .then((res) => res.data);
  };

  return useQuery<any[]>(
    ["candidate-lifecycle", values],
    fetchCandidateDetails,
    {
      refetchOnMount: false,
      staleTime: 1000 * 60 * 60,
    }
  );
};
