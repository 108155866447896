import axios from "axios";
import React from "react";
import { QueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ResetPasswardInput } from "../../auth/ResetPasswordForm";

const useResetPassword = (token: string | null) => {
  const queryClient = new QueryClient();
  const navigate = useNavigate();

  return useMutation(
    (values: ResetPasswardInput) =>
      axios.patch(`/auth/reset-password?token=${token}`, values),
    {
      onSuccess: () => {
        toast.success("Password reset successfully");
        navigate("/");
      },
      onError: () => {
        toast.error("Something went wrong, please try again");
      },
    }
  );
};

export default useResetPassword;
