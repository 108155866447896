import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// component
import { JOB_DETAILS, JOB_DETAILS_SCHEMA } from "./CreateJobDetails";
import CustomLoader from "../../CustomLoader";
import SearchNotFound from "../../SearchNotFound";

// hocs & hooks
import useGetJobsByPagination from "../../react-query/jobs/useGetJobsByPagination";
import useModalState from "../../react-query/useModalState";
import CustomDialog from "../../CustomDialog";
import EditJobForm from "./EditJobForm";

// utils
import { PER_PAGE_JOBS } from "../../../utils/constants";

// mui
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useJobMetadata from "../../react-query/jobs/useJobMetadata";
import CloseIcon from "@mui/icons-material/Close";
import { JOB } from "../../react-query/jobs/useCreateJob";
import useDebounce from "../../hooks/useDebounce";
import useSearchJob from "../../react-query/jobs/useSearchJob";

const ViewJobsByProcess = ({
  active = true,
  jobCreateModalState,
}: {
  active: boolean;
  jobCreateModalState: boolean;
}) => {
  const params = useParams();
  const navigate = useNavigate();

  const { modalState, open, close } = useModalState();

  const [skipJobs, setSkipJobs] = useState(0);

  const {
    isLoading,
    data: getJobs,
    refetch: refetchJobs,
  } = useGetJobsByPagination({
    skip: skipJobs,
    limit: PER_PAGE_JOBS,
    processId: params?.id,
    active: active,
    withCandidateCount: true,
  });

  const { isLoading: isLoadingMetadata, data: totalJobs } = useJobMetadata({
    processId: params.id,
    active: active,
  });

  const [selectedJob, setSelectedJob] = useState<JOB | undefined>();

  const columns = [
    {
      field: "name",
      headerName: "Job Role",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.name ? `${params.row.name} (${params.row.jobId})` : "NA",
    },
    {
      field: "expired",
      headerName: "Expiry",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.expired
          ? moment(params?.row?.expired).format("DD-MM-YYYY")
          : "NA";
      },
    },
    {
      field: "salary",
      headerName: "Salary",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.salary ? params.row.salary : "NA",
    },
    {
      field: "active",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        const active = params?.row?.active;
        return (
          <Typography color={active ? "green" : "red"}>
            {active ? "Active" : "Deactive"}
          </Typography>
        );
      },
    },
    {
      field: "openPosition",
      headerName: "Open Positions",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.openPosition ? params.row.openPosition : "0",
    },

    {
      field: "candidates",
      headerName: "Candidates",
      flex: 1,
      minWidth: 75,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.candidates ? params.row.candidates : 0,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1.4,
      minWidth: 140,
      renderCell: (params: any) => {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title="View Job">
              <IconButton
                color="info"
                size="small"
                onClick={() => {
                  navigate(
                    `/admin/process/${params?.row?.processId}/${params?.row?.jobId}`
                  );
                }}
              >
                <VisibilityIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Job">
              <IconButton
                color="error"
                size="small"
                onClick={() => {
                  setSelectedJob(params?.row);
                  open();
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (modalState == false) {
      refetchJobs();
      setSelectedJob(undefined);
    }

    if (jobCreateModalState == false) {
      refetchJobs();
    }
  }, [modalState, jobCreateModalState]);

  // search jobs
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchText, 300);

  const { isLoading: isLoadingSearchJob, data: SearchJobs } = useSearchJob({
    name: debouncedSearchQuery,
    active: active,
  });

  return (
    <>
      <Box>
        <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Search Job
          </InputLabel>
          <OutlinedInput
            type={"text"}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchText("");
                  }}
                  edge="end"
                >
                  {searchText && <CloseIcon />}
                </IconButton>
              </InputAdornment>
            }
            label="Search Job"
          />
        </FormControl>
      </Box>
      {!!totalJobs && getJobs?.length ? (
        <div
          style={{
            maxHeight: 600,
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <DataGrid
            autoHeight
            rows={
              SearchJobs?.length
                ? SearchJobs?.map((row: JOB, index) => {
                    return { id: row?.jobId, ...row };
                  })
                : getJobs
                ? getJobs?.map((row: JOB, index) => {
                    return { id: row?.jobId, ...row };
                  })
                : []
            }
            columns={columns}
            hideFooterPagination
            sx={{
              paddingInline: "0.5rem",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                width: "100%",
                justifyContent: "center",
              },
              "& .MuiDataGrid-cell": {
                width: "100%",
                justifyContent: "center",
              },
              "& .MuiDataGrid-columnHeaderRow": {
                borderBottom: "1px solid rgba(0,0,0,0.2)",
              },

              "& .MuiDataGrid-row": {
                "&:first-child": {
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                },
              },
            }}
          />
          <Pagination
            count={
              totalJobs
                ? (totalJobs +
                    PER_PAGE_JOBS -
                    1 -
                    ((totalJobs + PER_PAGE_JOBS - 1) % PER_PAGE_JOBS)) /
                  PER_PAGE_JOBS
                : 0
            }
            page={+(skipJobs / PER_PAGE_JOBS + 1)}
            color="primary"
            sx={{
              marginTop: -5.5,
              "&.MuiPagination-root": {
                pb: 1.5,
              },
              display: "flex",
              justifyContent: "flex-end",
              mr: 4,
            }}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setSkipJobs((value - 1) * PER_PAGE_JOBS);
            }}
          />
        </div>
      ) : isLoading ? (
        <CustomLoader />
      ) : (
        <Box sx={{ height: "480px" }}>
          <SearchNotFound searchQuery={"No Results Found"} />
        </Box>
      )}

      {selectedJob?.name && (
        <CustomDialog
          title={"Edit Job"}
          onClose={close}
          open={modalState}
          hideFooter={true}
          maxWidth="md"
        >
          {selectedJob ? (
            <EditJobForm job={selectedJob} handleClose={close} />
          ) : null}
        </CustomDialog>
      )}
    </>
  );
};

export default ViewJobsByProcess;
