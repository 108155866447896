import moment from "moment";
import momentTz from "moment-timezone";

export const convertArrayObjToArrayStr = (arrayObj: any, itemName: string) => {
  return arrayObj.map((item: any) => item[itemName]);
};

export const convertStrArrayToObjArray = (arrayObj: any, itemName: string) => {
  return arrayObj.map((item: string) => ({ [itemName]: item }));
};

export const convertDateToDDMMYYYYFormat = (date: any) => {
  return moment(date).format("DD-MM-YYYY");
};

export const convertArrayFormatDate = (array: any) =>
  array.map((item: any) => {
    if (item?.start) {
      item.start = moment(item?.start, "DD-MM-YYYY", true).isValid()
        ? item.start
        : convertDateToDDMMYYYYFormat(item.start);
    }
    if (item?.end) {
      item.end = moment(item?.end, "DD-MM-YYYY", true).isValid()
        ? item.end
        : convertDateToDDMMYYYYFormat(item.end);
    }
    return item;
  });

export const utcConvertorDate = (date: string) => {
  const istDate = momentTz.tz(date, "DD-MM-YYYY", "Asia/Kolkata");
  const utcDate = istDate.clone().utc();
  return utcDate.toISOString();
};

// import moment from 'moment';

// const date = moment('2023-05-03T18:30:00.000Z').format('DD-MM-YYYY');

// console.log(date); // Output: 03-05-2023
