import * as XLXS from "xlsx";
import axios from "axios";

// component
import CustomLoader from "../../CustomLoader";
import SearchNotFound from "../../SearchNotFound";

// mui
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// hooks & HOCs
import useSourceWiseCandidateReport from "../../react-query/reports/useSourceWiseCandidateReport";
import { useState } from "react";
import moment from "moment";
import { ReportGroupBy } from "../../../utils/constants";

const CandidateSourceReport = () => {
  const [groupBy, setGroupBy] = useState<ReportGroupBy>(ReportGroupBy.MONTHLY);
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "years").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
  const { data: reportData, isLoading } = useSourceWiseCandidateReport({
    groupBy,
    start: startDate,
    end: endDate,
  });
  const downloadSourceWiseCandidate = async (source: string) => {
    try {
      let data = await axios.get(
        `candidate/search?source=${source}&start=${startDate}&end=${endDate}`
      );
      let filterData = data?.data?.map((item: any) => {
        delete item._id;
        delete item.updatedAt;
        return {
          ...item,
          createdAt: moment(item.createdAt).local().format("DD-MM-YYYY"),
          dob: moment(item.dob).isValid()
            ? moment(item.dob).format("DD-MM-YYYY")
            : "",
        };
      });
      const wb = XLXS.utils.book_new();
      const ws = XLXS.utils.json_to_sheet(filterData);
      XLXS.utils.book_append_sheet(wb, ws, "candidate-source-report");
      XLXS.writeFile(
        wb,
        `${source?.toUpperCase()}_${startDate}_${endDate}.csv`
      );
    } catch (error) {}
  };

  const columns = [
    {
      field: "_id",
      headerName: "Source",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?._id?.source?.toUpperCase();
      },
    },
    {
      field: "total",
      headerName: "Total Candidates",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "actions",
      headerName: "Download",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              downloadSourceWiseCandidate(params?.row?._id.source);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Candidate Source Report</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Grid container spacing={4} sx={{ p: 2 }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="Start date"
                  value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setStartDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="End date"
                  inputFormat="DD/MM/yyyy"
                  value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setEndDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={3} lg={3}>
              <TextField
                select
                fullWidth
                value={groupBy}
                onChange={(e) => {
                  setGroupBy(e.target.value as ReportGroupBy);
                }}
              >
                {["daily", "weekly", "monthly", "yearly"].map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {!!reportData && reportData?.length ? (
            <div
              style={{
                maxHeight: 600,
                marginTop: "1rem",
              }}
            >
              <DataGrid
                autoHeight
                rows={
                  reportData?.map((row: any, index: any) => {
                    return { id: index, ...row };
                  }) || []
                }
                columns={columns}
                sx={{
                  paddingInline: "0.5rem",
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-columnHeaderRow": {
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                  },

                  "& .MuiDataGrid-row": {
                    "&:first-child": {
                      borderTop: "1px solid rgba(0,0,0,0.1)",
                    },
                  },
                }}
              />
            </div>
          ) : isLoading ? (
            <CustomLoader />
          ) : (
            <Box sx={{ height: "480px" }}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CandidateSourceReport;
