import * as React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

// mui
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  IconButton,
  InputAdornment,
  Stack,
  Link as MuiLink,
  Box,
} from "@mui/material";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

// compnentts
import Logo from "../../components/Logo";

// hooks
import useTitle from "../../components/hooks/useTitle";
import { BRAND } from "../../utils/constants";
import { EMAIL_VALIDATOR, STRING_VALIDATOR } from "../../utils/yup-validators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useLogin from "../../components/react-query/user/useLogin";
import { toast } from "react-toastify";
import withAuth, {
  AUTH_REDIRECTS,
  AUTH_ROLES,
} from "../../components/HOC/withAuthGuard";

const HeaderStyle = styled("header")(({ theme }: { theme: any }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  right: "5rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "flex-end",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-end",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const LoginSchema = Yup.object().shape({
  email: EMAIL_VALIDATOR,
  password: STRING_VALIDATOR,
});

interface LoginInput {
  email: string;
  password: string;
}

const Login = () => {
  useTitle("Login");
  const theme = useTheme();
  let navigate = useNavigate();
  const login = useLogin();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<LoginInput>({
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit = async (data: LoginInput) => {
    try {
      const res = await login.mutateAsync({
        email: data.email,
        password: data.password,
      });
      toast.success("Login successful");
      res && navigate(AUTH_REDIRECTS[res.role]);
    } catch (err: any) {
      if (err.response) {
        toast.error(
          err?.response?.data?.message?.length
            ? err?.response?.data?.message?.join(",")
            : err?.response?.data?.message
        );
      }
    }
  };

  return (
    <>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{
            overflow: "hidden",
            "&:hover": {
              scale: 1.1,
            },
          }}
        >
          <img
            src="/images/login.jpg"
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            bgcolor: "white",
            borderTopLeftRadius: "0.5rem",
            borderBottomLeftRadius: "0.5rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
            [theme.breakpoints.up("md")]: {
              px: 16,
            },
          }}
          p="2rem 4rem"
        >
          <Box sx={{ mt: 4 }}>
            <FlashOnIcon
              sx={{ color: theme.palette.secondary.main }}
              fontSize="large"
            />
          </Box>
          <Typography variant="h5" gutterBottom>
            Sign in to {BRAND}
          </Typography>

          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            Enter your details below.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                type="email"
                {...register("email")}
                label="email"
                error={Boolean(errors.email)}
                helperText={Boolean(errors.email) && errors.email?.message}
              />

              <TextField
                label="Password"
                {...register("password")}
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(errors.password)}
                helperText={
                  Boolean(errors.password) && errors.password?.message
                }
              />
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ my: 2 }}
            >
              <FormControlLabel
                control={<Checkbox defaultChecked />}
                label="Remember me"
              />

              <MuiLink
                variant="subtitle2"
                sx={{ cursor: "pointer" }}
                underline="hover"
                onClick={() => navigate("/auth/forgot-password")}
              >
                Forgot password?
              </MuiLink>
            </Stack>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Login
            </Button>
          </form>

          {/* <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            Don’t have an account?{" "}
            <MuiLink
              sx={{ cursor: "pointer" }}
              variant="subtitle2"
              onClick={() => navigate("/auth/register")}
            >
              Get started
            </MuiLink>
          </Typography> */}
        </Grid>
      </Grid>
      <HeaderStyle>
        <Logo />
      </HeaderStyle>
    </>
  );
};

export default withAuth(Login, AUTH_ROLES.UNAUTH);
