import { useQuery } from "react-query";
import { PROCESS } from "./useCreateProcess";
import axios from "axios";

export const useGetProcessDetails = (id?: string) => {
  const fetchProcessDetails = () => {
    return axios.get(`/process/${id}`).then((res) => res.data);
  };

  return useQuery<PROCESS>(["process", id], fetchProcessDetails, {
    refetchOnMount: "always",
    keepPreviousData: true,
  });
};
