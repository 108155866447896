import * as React from "react";
import { useNavigate } from "react-router-dom";

// mui
import Button from "@mui/material/Button";
import { Link as MuiLink, Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

// hooks
import useTitle from "../../components/hooks/useTitle";
import ForgetPasswordForm from "../../components/auth/ForgetPasswordForm";
import withAuth, { AUTH_ROLES } from "../../components/HOC/withAuthGuard";

const ForgotPassword = () => {
  useTitle("Forgot Password");
  const theme = useTheme();
  let navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    React.useState<boolean>(false);
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <>
      <img
        src="/images/client.jpg"
        style={{
          objectFit: "cover",
          position: "absolute",
          inset: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -5,
          opacity: 0.6,
        }}
      />
      <Container
        maxWidth="lg"
        sx={{ width: "100vw", height: "100vh", position: "relative" }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", height: "100%" }}
        >
          <Grid
            item
            xs={0}
            md={6}
            sx={{
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
              height: "70%",
              bgcolor: "white",
              borderTopLeftRadius: "0.5rem",
              borderBottomLeftRadius: "0.5rem",
            }}
          >
            <img
              src="/images/forgot-password.png"
              width="100%"
              height="100%"
              style={{
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              height: "70%",
              bgcolor: "white.main",
              borderTopRightRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            p="2rem 4rem"
          >
            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
              Forgot your password?
            </Typography>
            <Typography sx={{ color: "text.secondary", mb: 5 }}>
              Please enter the e-mail address you use when creating your
              account, we'll send you instruction to reset your password.
            </Typography>
            <ForgetPasswordForm />
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              <MuiLink
                sx={{ cursor: "pointer" }}
                variant="subtitle2"
                onClick={() => navigate("/auth/login")}
              >
                Go to login page
              </MuiLink>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withAuth(ForgotPassword, AUTH_ROLES.UNAUTH);
