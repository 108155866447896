import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

export interface CREATE_PROCESS {
  name: string;
  createdBy: string;
}

export interface PROCESS extends CREATE_PROCESS {
  processId: string;
  active: boolean;
  jobIds: string[];
  createdAt: string;
  updatedAt?: string;
}

const useCreateProcess = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: CREATE_PROCESS) =>
      axios.post("/process", values).then((res) => res.data),
    {
      onSuccess: (newProcess: PROCESS) => {
        toast.success("Process added successfully");
        const previousProcesses: PROCESS[] | undefined =
          queryClient.getQueryData(["processes"]);
        let allProcesses = previousProcesses
          ? [{ ...newProcess }, ...previousProcesses]
          : [{ ...newProcess }];
        queryClient.setQueryData(["processes"], [...allProcesses]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useCreateProcess;
