import React from "react";
import { useParams } from "react-router-dom";

// layout
import withLayout from "../../../components/HOC/withLayout";
import AdminLayout from "../../../components/layouts/admin";

// material ui
import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// hocs & hooks
import useTitle from "../../../components/hooks/useTitle";
import useModalState from "../../../components/react-query/useModalState";
import { useGetProcessDetails } from "../../../components/react-query/process/useGetProcessDetails";

// component
import ViewProcessDetails from "../../../components/admin/process/ViewProcessDetails";
import CustomDialog from "../../../components/CustomDialog";
import CustomLoader from "../../../components/CustomLoader";
import ViewJobsByProcess from "../../../components/admin/Job/ViewJobsByProcess";
import { TabPanel, a11yProps } from "../../../components/TabComponent";
import CreateJobForm from "../../../components/admin/Job/CreateJobForm";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";

const View = () => {
  useTitle("Process");

  const params = useParams();

  const [tabValue, setTabValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { isLoading, data: processDetails } = useGetProcessDetails(params.id);

  const { modalState: jobCreateModalState, open, close } = useModalState();

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          xs={12}
          item
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5">
            {processDetails?.name
              ? `${processDetails?.name} (ID: ${processDetails?.processId})`
              : "Process"}
          </Typography>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              open();
            }}
          >
            Jobs
          </Button>
        </Grid>

        <Grid
          container
          item
          xs={12}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Active Jobs" {...a11yProps(0)} />
                <Tab label="Inactive Jobs" {...a11yProps(1)} />
                <Tab label="Process Details" {...a11yProps(2)} />
              </Tabs>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={0}>
              <ViewJobsByProcess
                active
                jobCreateModalState={jobCreateModalState}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={1}>
              <ViewJobsByProcess
                active={false}
                jobCreateModalState={jobCreateModalState}
              />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value={tabValue} index={2}>
              {isLoading ? (
                <CustomLoader />
              ) : (
                <ViewProcessDetails process={processDetails} />
              )}
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>

      <CustomDialog
        title={"Create Job"}
        onClose={close}
        open={jobCreateModalState}
        hideFooter={true}
        maxWidth="md"
      >
        <CreateJobForm handleClose={close} />
      </CustomDialog>
    </>
  );
};

export default withAuth(withLayout(View, AdminLayout), AUTH_ROLES.ADMIN);
