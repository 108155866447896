import axios from "axios";
import { useMutation } from "react-query";
import {
  Applicant_BY_JOB_SCHEMA,
  Comment_Schema,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { error } from "console";

const useEditApplication = (message: string, close: () => void = () => {}) => {
  return useMutation(
    (values: Applicant_BY_JOB_SCHEMA) =>
      axios
        .patch(`/application/${values.applicationId}`, values)
        .then((res) => res.data),
    {
      onSuccess: () => {
        toast.success(`Application ${message} successfully`);
        close();
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
        close();
      },
    }
  );
};

export default useEditApplication;
