import axios from "axios";
import { useQuery } from "react-query";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";

export const useGetApplicationById = (id: string) => {
  const fetchApplicantDetails = () => {
    return axios.get(`/application?candidateId=${id}`).then((res) => res.data);
  };

  return useQuery<Applicant_BY_JOB_SCHEMA[] | undefined>(
    ["applicant-comment", id],
    fetchApplicantDetails,
    {
      refetchOnMount: "always",
    }
  );
};
