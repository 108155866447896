import React from "react";

// form-hooks
import * as Yup from "yup";

import { useForm } from "react-hook-form";
import {
  EMAIL_VALIDATOR,
  PASSWORD_VALIDATOR,
  STRING_VALIDATOR,
} from "../../utils/yup-validators";
import { yupResolver } from "@hookform/resolvers/yup";
import useForgetPassword from "../react-query/user/useForgetPassword";
import { LoadingButton } from "@mui/lab";
import { Box, Grid, TextField } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import useResetPassword from "../react-query/user/useResetPassword";

export interface ResetPasswardInput {
  password: "";
  confirmPassword: "";
}

const ResetPasswordSchema = Yup.object().shape({
  password: PASSWORD_VALIDATOR,
  confirmPassword: PASSWORD_VALIDATOR,
});

const ResetPasswordForm = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  console.log("first", params, searchParams.get("token"));
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    formState: { errors },
  } = useForm<ResetPasswardInput>({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const { mutate: ResetPasswordMutate } = useResetPassword(
    searchParams.get("token")
  );
  const onSubmit = (values: ResetPasswardInput) => {
    ResetPasswordMutate(values);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("password")}
              label="Password"
              type="password"
              error={Boolean(errors.password)}
              helperText={Boolean(errors.password) && errors.password?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("confirmPassword")}
              label="Confirm Password"
              onChange={(e) => {
                if (e.target.value !== getValues("password")) {
                  setError("confirmPassword", {
                    type: "custom",
                    message: "Password does not match",
                  });
                } else {
                  clearErrors(["confirmPassword"]);
                }
              }}
              error={Boolean(errors.confirmPassword)}
              helperText={
                Boolean(errors.confirmPassword) &&
                errors.confirmPassword?.message
              }
            />
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
          }}
        >
          Reset Password
        </LoadingButton>
      </form>
    </Box>
  );
};

export default ResetPasswordForm;
