import moment from "moment";
import * as Yup from "yup";
import { useState } from "react";

// form
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// @mui
import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

// utils
import {
  EMAIL_VALIDATOR,
  PHONE_VALIDATOR,
  STRING_VALIDATOR,
} from "../../utils/yup-validators";

// components
import useCreateCandidate, {
  CREATE_CANDIDATE,
} from "../react-query/candidate/useCreateCandidate";
import {
  EDUCATION_FIELDS,
  EXPERIENCE_FIELDS,
  Experience,
  Gender,
  CLIENT_CITY_LIST,
  SOURCE_FIELDS,
} from "../../utils/constants";
import {
  convertArrayFormatDate,
  convertArrayObjToArrayStr,
} from "../../utils/convertors";

const CandidateSchema = Yup.object().shape({
  email: EMAIL_VALIDATOR,
  phone: PHONE_VALIDATOR,
  firstName: STRING_VALIDATOR,
  gender: Yup.string(),
  cctc: Yup.string(),
  altPhone: Yup.string(),
  yearOfExp: Yup.string(),
  preferredLocation: Yup.array().of(
    Yup.object().shape({
      location: Yup.string(),
    })
  ),
  recruiter: Yup.string(),
  noticePeriod: Yup.string(),
  address: Yup.array().of(
    Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      country: Yup.string(),
      pincode: Yup.string(),
      isCurrent: Yup.boolean(),
    })
  ),
  experience: Yup.array().of(
    Yup.object().shape({
      jobRole: Yup.string(),
      start: Yup.string(),
      end: Yup.string(),
      isCurrent: Yup.boolean(),
      jobDescription: Yup.string(),
      employer: Yup.string(),
    })
  ),
  source: Yup.string(),
  // skills: Yup.array().of(
  //   Yup.object().shape({
  //     skill: Yup.string(),
  //   })
  // ),
  // websites: Yup.array().of(
  //   Yup.object().shape({
  //     website: Yup.string(),
  //   })
  // ),
  expSalary: Yup.string(),
  // skypeId: Yup.string(),
  remark: Yup.string(),
  referral: Yup.string(),
});

function CreateCandidateForm() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
    getValues,
  } = useForm<CREATE_CANDIDATE>({
    resolver: yupResolver(CandidateSchema),
    defaultValues: {
      address: [
        {
          addressLine1: "",
        },
      ],
      preferredLocation: [{ location: "" }],
      education: [{ type: "" }],
    },
  });
  const {
    fields: EducationFields,
    append: AddEducationField,
    remove: RemoveEducationField,
  } = useFieldArray({
    control,
    name: "education",
  });
  // const {
  //   fields: ExperienceFields,
  //   append: AddExperienceField,
  //   remove: RemoveExperienceField,
  // } = useFieldArray({
  //   control,
  //   name: "experience",
  // });
  const {
    fields: AddressFields,
    append: AddAddressField,
    remove: RemoveAddressField,
  } = useFieldArray({
    control,
    name: "address",
  });

  const {
    fields: PreferredLocationFields,
    append: AddPreferredLocationField,
    remove: RemovePreferredLocationField,
  } = useFieldArray({
    control,
    name: "preferredLocation",
  });

  const sourceValue = watch("source");

  // const {
  //   fields: HobbiesFields,
  //   append: AddHobbiesField,
  //   remove: RemoveHobbiesField,
  // } = useFieldArray({
  //   control,
  //   name: "hobbies",
  // });

  // const {
  //   fields: SkillsFields,
  //   append: AddSkillsField,
  //   remove: RemoveSkillsField,
  // } = useFieldArray({
  //   control,
  //   name: "skills",
  // });

  // const {
  //   fields: WebsitesFields,
  //   append: AddWebsitesField,
  //   remove: RemoveWebsitesField,
  // } = useFieldArray({
  //   control,
  //   name: "websites",
  // });

  // const dob = watch("dob");

  const [resumeObj, setResumeObj] = useState<File | null>();

  const { mutate: createCandidateMutate } = useCreateCandidate();

  const onSubmit = (values: CREATE_CANDIDATE) => {
    let localValues = convertValuesInFormdata(values);
    createCandidateMutate(localValues);
  };

  const convertValuesInFormdata = (obj: any) => {
    let formData = new FormData();

    // obj.skills = convertArrayObjToArrayStr(obj.skills, "skill");
    // obj.websites = convertArrayObjToArrayStr(obj.websites, "website");
    // obj.hobbies = convertArrayObjToArrayStr(obj.hobbies, "hobby");
    obj.preferredLocation = convertArrayObjToArrayStr(
      obj.preferredLocation,
      "location"
    );

    if (obj.education?.length) {
      obj.education = convertArrayFormatDate(obj.education);
    }
    if (obj.experience?.length) {
      let tempObj = convertArrayFormatDate(obj.experience);

      obj.experience = tempObj?.map((obj: Experience) => {
        let localObj = [];
        localObj.push(obj.jobDescription);

        return { ...obj, jobDescription: localObj };
      });
    }

    formData.append("education", JSON.stringify(obj.education));
    delete obj.education;
    // formData.append("experience", JSON.stringify(obj.experience));
    // delete obj.experience;
    // formData.append("skills", JSON.stringify(obj.skills));
    // delete obj.skills;

    // formData.append("websites", JSON.stringify(obj.websites));
    // delete obj.websites;

    // formData.append("hobbies", JSON.stringify(obj.hobbies));
    // delete obj.hobbies;

    formData.append("address", JSON.stringify(obj.address));
    delete obj.address;

    formData.append("preferredLocation", JSON.stringify(obj.preferredLocation));
    delete obj.preferredLocation;

    Object.entries(obj).forEach(([key, value]: [key: string, value: any]) => {
      formData.append(key, value);
    });

    if (resumeObj?.name) {
      formData.append("file", resumeObj);
    }

    return formData;
  };

  return (
    <Box
      sx={{
        bgcolor: "rgba(255,255,255,0.99)",
        p: 4,
        ml: 0,
        borderRadius: 1,
        border: "1px solid #ddd",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("email")}
              label="Email"
              error={Boolean(errors.email)}
              helperText={Boolean(errors.email) && errors.email?.message}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("firstName")}
              label="Name"
              error={Boolean(errors.firstName)}
              helperText={
                Boolean(errors.firstName) && errors.firstName?.message
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("phone")}
              label="Phone Number"
              type="number"
              error={Boolean(errors.phone)}
              helperText={Boolean(errors.phone) && errors.phone?.message}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("cctc")}
              label="Current CTC"
              error={Boolean(errors.cctc)}
              helperText={Boolean(errors.cctc) && errors.cctc?.message}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("expSalary")}
              label="Expected CTC"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("noticePeriod")}
              label="Notice period"
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              select
              fullWidth
              defaultValue=""
              {...register("gender")}
              label="Gender"
              error={Boolean(errors.gender)}
              helperText={Boolean(errors.gender) && errors.gender?.message}
            >
              {Gender.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12}></Grid>

          {/* Address fields */}
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Current Location</Typography>
            </Grid>
            {AddressFields.map((field, index) => {
              // let isCurrent = watch(`address.${index}.isCurrent`);
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Current location"
                      {...register(`address.${index}.addressLine1`)}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Address Lane 2"
                      {...register(`address.${index}.addressLine2`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Pincode"
                      {...register(`address.${index}.pincode`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="City"
                      {...register(`address.${index}.city`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="State"
                      {...register(`address.${index}.state`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Country"
                      {...register(`address.${index}.country`)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <FormControlLabel
                      label="Is current Address?"
                      control={
                        <Checkbox
                          checked={isCurrent ? Boolean(isCurrent) : false}
                          {...register(`address.${index}.isCurrent`)}
                        />
                      }
                    />

                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      onClick={() => {
                        RemoveAddressField(index);
                      }}
                    >
                      Remove Address
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}
                </Grid>
              );
            })}
            {/* <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddAddressField({
                    addressLine1: "",
                    addressLine2: "",
                    pincode: "",
                    city: "",
                    state: "",
                    country: "",
                    isCurrent: false,
                  });
                }}
              >
                Add Address
              </Button>
            </Grid> */}
          </Grid>

          {/* Preferred address fields */}
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Preferred Location</Typography>
            </Grid>
            {PreferredLocationFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      select
                      label="Preferred location"
                      {...register(`preferredLocation.${index}.location`)}
                    >
                      {CLIENT_CITY_LIST.map((city) => (
                        <MenuItem key={city} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        RemovePreferredLocationField(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid> */}
                </Grid>
              );
            })}
            {/* <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddPreferredLocationField({
                    location: "",
                  });
                }}
              >
                Add Location
              </Button>
            </Grid> */}
          </Grid>

          {/* Education fields */}
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Education</Typography>
            </Grid>
            {EducationFields.map((field, index) => {
              // let start = watch(`education.${index}.start`);
              // let end = watch(`education.${index}.end`);
              // let isCurrent = watch(`education.${index}.isCurrent`);
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Select education"
                      {...register(`education.${index}.type`)}
                    >
                      {EDUCATION_FIELDS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>{" "}
                  </Grid>
                  {/* <Grid item xs={12} md={6} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Start date"
                        value={start ? start : null}
                        onChange={(newValue) => {
                          newValue &&
                            setValue(`education.${index}.start`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="End date"
                        value={end ? end : null}
                        onChange={(newValue) => {
                          newValue &&
                            setValue(`education.${index}.end`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Institute Name"
                      {...register(`education.${index}.institute`)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Obtained Marks"
                      {...register(`education.${index}.marks`)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Total Marks"
                      {...register(`education.${index}.totalMarks`)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    alignItems={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                  >
                    <FormControlLabel
                      label="Is current Education?"
                      control={
                        <Checkbox
                          checked={isCurrent ? Boolean(isCurrent) : false}
                          {...register(`education.${index}.isCurrent`)}
                        />
                      }
                    />

                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      onClick={() => {
                        RemoveEducationField(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid> */}
                </Grid>
              );
            })}
            {/* <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddEducationField({
                    type: "",
                    start: "",
                    end: "",
                    institute: "",
                    marks: "",
                    totalMarks: "",
                    isCurrent: false,
                  });
                }}
              >
                Add Education
              </Button>
            </Grid> */}
          </Grid>

          {/* year of experience */}
          <Grid item xs={12} md={6} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Experience</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                label="Select experience"
                {...register(`yearOfExp`)}
              >
                {EXPERIENCE_FIELDS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>{" "}
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>

          {/* Hobbies fields */}
          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Hobbies</Typography>
            </Grid>
            {HobbiesFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Hobby"
                      {...register(`hobbies.${index}.hobby`)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        RemoveHobbiesField(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddHobbiesField({
                    hobby: "",
                  });
                }}
              >
                Add Hobby
              </Button>
            </Grid>
          </Grid> */}

          {/* Experince fields */}
          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Experience</Typography>
            </Grid>
            {ExperienceFields.map((field, index) => {
              let start = watch(`experience.${index}.start`);
              let end = watch(`experience.${index}.end`);
              let isCurrent = watch(`experience.${index}.isCurrent`);
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"flex-end"}
                    sx={{ mt: -3 }}
                  >
                    <Typography>{`Experince ${index + 1}`}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Employer"
                      {...register(`experience.${index}.employer`)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="Start date"
                        value={start ? start : null}
                        onChange={(newValue) => {
                          newValue &&
                            setValue(`experience.${index}.start`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        label="End date"
                        value={end ? end : null}
                        onChange={(newValue) => {
                          newValue &&
                            setValue(`experience.${index}.end`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Job Role"
                      {...register(`experience.${index}.jobRole`)}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <FormControlLabel
                      label="Is current?"
                      control={
                        <Checkbox
                          checked={isCurrent ? Boolean(isCurrent) : false}
                          {...register(`experience.${index}.isCurrent`)}
                        />
                      }
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      label="Job Description"
                      minRows={3}
                      {...register(`experience.${index}.jobDescription`)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      onClick={() => {
                        RemoveExperienceField(index);
                      }}
                    >
                      {`Remove`}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddExperienceField({
                    jobRole: "",
                    isCurrent: false,
                    employer: "",
                    jobDescription: "",
                    start: "",
                    end: "",
                  });
                }}
              >
                Add Experince
              </Button>
            </Grid>
          </Grid> */}

          {/* Skills fields */}
          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Skills</Typography>
            </Grid>
            {SkillsFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Skill"
                      {...register(`skills.${index}.skill`)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        RemoveSkillsField(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddSkillsField({
                    skill: "",
                  });
                }}
              >
                Add Skill
              </Button>
            </Grid>
          </Grid> */}

          {/* Websites fields */}
          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Websites</Typography>
            </Grid>
            {WebsitesFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      fullWidth
                      label="Website"
                      {...register(`websites.${index}.website`)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <Button
                      startIcon={<DeleteIcon />}
                      variant="contained"
                      sx={{ mt: 1 }}
                      onClick={() => {
                        RemoveWebsitesField(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12} md={6} lg={4}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddWebsitesField({
                    website: "",
                  });
                }}
              >
                Add Website
              </Button>
            </Grid>
          </Grid> */}
          <Grid item xs={12} md={6} lg={4}>
            <TextField fullWidth {...register("recruiter")} label="Recruiter" />
          </Grid>

          {/* source */}
          <Grid item xs={12} md={6} lg={4}>
            <TextField select fullWidth label="Source" {...register(`source`)}>
              {SOURCE_FIELDS.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              {...register("source")}
              label="How did you hear about us?"
            />
          </Grid> */}

          {sourceValue === "Employee Referral" ? (
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("referral")}
                label="Employee ID/Name"
              />
            </Grid>
          ) : null}

          {/* <Grid item xs={12} md={6} lg={4}>
            <TextField fullWidth {...register("skypeId")} label="Skype Id" />
          </Grid> */}

          {/* status */}
          {/* <Grid item xs={12} md={6} lg={4}>
            <TextField
              select
              fullWidth
              {...register("status")}
              label="Status"
              defaultValue=""
              error={Boolean(errors.status)}
              helperText={Boolean(errors.status) && errors.status?.message}
            >
              {Candidate_Status?.map((option) => (
                <MenuItem key={option?.value} value={option?.value}>
                  {option?.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}

          <Grid item xs={12} md={6} lg={4}>
            <TextField fullWidth {...register("remark")} label="Remark" />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Typography sx={{ color: "rgba(0,0,0,0.65)" }}>Resume :</Typography>
            <input
              type="file"
              accept="application/pdf"
              onChange={(e) => {
                if (e?.target?.files?.length) {
                  setResumeObj(e?.target?.files[0]);
                }
              }}
            />
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{
            mt: 3,
            width: "32ch",
          }}
        >
          ADD CANDIDATE
        </LoadingButton>
      </form>
    </Box>
  );
}

export default CreateCandidateForm;
