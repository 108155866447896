import React, { useMemo, useState } from "react";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";
import withLayout from "../../../components/HOC/withLayout";
import RecruiterLayout from "../../../components/layouts/recruiter";
import useGetJobsByRecruiter from "../../../components/react-query/recruiter/useGetJobsByRecruiter";
import { PER_PAGE_JOBS } from "../../../utils/constants";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { JOB } from "../../../components/react-query/jobs/useCreateJob";
import moment from "moment";
import { DataGrid, GridValueGetterParams } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useDebounce from "../../../components/hooks/useDebounce";
import CustomLoader from "../../../components/CustomLoader";
import SearchNotFound from "../../../components/SearchNotFound";
import useJobMetadata from "../../../components/react-query/jobs/useJobMetadata";
import useTitle from "../../../components/hooks/useTitle";

const Index = () => {
  useTitle("Jobs");
  const navigate = useNavigate();

  const [skipJobs, setSkipJobs] = useState(0);

  const {
    isFetched,
    data: getJobsData,
    isLoading,
  } = useGetJobsByRecruiter({
    skip: skipJobs,
    limit: PER_PAGE_JOBS,
    active: true,
  });

  // search jobs
  const [searchText, setSearchText] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchText, 300);

  const { isLoading: isLoadingSearchJob, data: jobs } = useGetJobsByRecruiter({
    name: debouncedSearchQuery,
    active: true,
  });
  const SearchJobs = useMemo(() => {
    let local = jobs?.filter((data) => data.applications?.length !== 0);
    if (local?.length !== 0) {
      return local;
    }
    return getJobsData?.filter((data) => data.applications?.length !== 0);
  }, [jobs, getJobsData]);

  const { isLoading: isLoadingMetadata, data: totalJobs } = useJobMetadata({
    active: true,
  });

  const columns = [
    { field: "name", headerName: "Job Role", flex: 1, minWidth: 240 },
    {
      field: "expired",
      headerName: "Expired",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.expired
          ? moment(params?.row?.expired).format("DD-MM-YYYY")
          : "NA";
      },
    },
    {
      field: "salary",
      headerName: "Salary",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.salary ? params.row.salary : "NA",
    },
    // {
    //   field: "companyDescription",
    //   headerName: "Company Description",
    //   flex: 1,
    //   minWidth: 100,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     params.row?.companyDescription ? params.row.companyDescription : "NA",
    // },
    // {
    //   field: "active",
    //   headerName: "Status",
    //   flex: 1,
    //   minWidth: 100,
    //   renderCell: (params: any) => {
    //     const active = params?.row?.active;
    //     return (
    //       <Typography color={active ? "green" : "red"}>
    //         {active ? "Active" : "Deactive"}
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "yearOfExp",
      headerName: "Year of Experience",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.yearOfExp ? params.row.yearOfExp : "NA",
    },
    {
      field: "openPosition",
      headerName: "Open Position",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.openPosition ? params.row.openPosition : 0,
    },

    {
      field: "applications",
      headerName: "Candidates",
      flex: 1,
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams) =>
        params.row?.applications ? params.row.applications.length : 0,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1.4,
      minWidth: 140,
      renderCell: (params: any) => {
        return (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              color="info"
              onClick={() => {
                navigate(`/recruiter/jobs/${params?.row?.jobId}`);
              }}
              startIcon={<VisibilityIcon fontSize="small" />}
            >
              View
            </Button>
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <Box>
        <FormControl sx={{ m: 1, width: "35ch" }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            Search Job
          </InputLabel>
          <OutlinedInput
            type={"text"}
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setSearchText("");
                  }}
                  edge="end"
                >
                  {searchText && <CloseIcon />}
                </IconButton>
              </InputAdornment>
            }
            label="Search Job"
          />
        </FormControl>
      </Box>

      {getJobsData?.length ? (
        <div
          style={{
            maxHeight: 800,
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <DataGrid
            autoHeight
            rows={
              SearchJobs?.length
                ? SearchJobs?.map((row: JOB, index) => {
                    return { id: row?.jobId, ...row };
                  })
                : []
            }
            columns={columns}
            hideFooterPagination
            sx={{
              paddingInline: "0.5rem",
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                width: "100%",
                justifyContent: "center",
              },
              "& .MuiDataGrid-cell": {
                width: "100%",
                justifyContent: "center",
              },
              "& .MuiDataGrid-columnHeaderRow": {
                borderBottom: "1px solid rgba(0,0,0,0.2)",
              },

              "& .MuiDataGrid-row": {
                "&:first-child": {
                  borderTop: "1px solid rgba(0,0,0,0.1)",
                },
              },
            }}
          />
          <Pagination
            count={
              totalJobs
                ? (totalJobs +
                    PER_PAGE_JOBS -
                    1 -
                    ((totalJobs + PER_PAGE_JOBS - 1) % PER_PAGE_JOBS)) /
                  PER_PAGE_JOBS
                : 0
            }
            page={+(skipJobs / PER_PAGE_JOBS + 1)}
            color="primary"
            sx={{
              marginTop: -5.5,
              "&.MuiPagination-root": {
                pb: 1.5,
              },
              display: "flex",
              justifyContent: "flex-end",
              mr: 4,
            }}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              setSkipJobs((value - 1) * PER_PAGE_JOBS);
            }}
          />
        </div>
      ) : isLoading ? (
        <CustomLoader />
      ) : (
        <Box sx={{ height: "480px" }}>
          <SearchNotFound searchQuery={"No Results Found"} />
        </Box>
      )}
    </>
  );
};

export default withAuth(
  withLayout(Index, RecruiterLayout),
  AUTH_ROLES.RECRUITER
);
