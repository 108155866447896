import { JOB_DETAILS_SCHEMA } from "../components/admin/Job/CreateJobDetails";

export const BRAND: string = "Grassroots";
// export const BE_SERVER: string = "http://localhost:3001/";

// per api count number
export const PER_PAGE_JOBS = 20;

// interfacea
export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  state?: string;
  city?: string;
  country?: string;
  pincode?: string;
  isCurrent?: boolean;
}

export interface Education {
  type?: string;
  start?: string;
  end?: string;
  isCurrent?: boolean;
  institute?: string;
  marks?: string;
  totalMarks?: string;
}

export interface Create_Experience {
  jobRole?: string;
  start?: string;
  end?: string;
  isCurrent?: boolean;
  jobDescription?: string;
  employer?: string;
}

export interface Experience {
  jobRole?: string;
  start?: string;
  end?: string;
  isCurrent?: boolean;
  jobDescription?: string[];
  employer?: string;
}

// mapper values to be
// don't add anything here without going through code
export const enum FIELD {
  email = "email",
  firstName = "firstName",
  lastName = "lastName",
  gender = "gender",
  phone = "phone",
  dob = "dob",
  remark = "remark",
  cctc = "cctc",
  altPhone = "altPhone",
  yearOfExp = "yearOfExp",
  hobbies = "hobbies",
  recruiter = "recruiter",
  recruiterEmail = "recruiterEmail",
  noticePeriod = "noticePeriod",
  source = "source",
  skills = "skills",
  expSalary = "expSalary",
  skypeId = "skypeId",
  status = "status",
  "addressLine1" = "addressLine1",
  city = "city",
  state = "state",
  country = "country",
  "10th" = "10th",
  "12th" = "12th",
  graduation = "graduation",
  "graduation%" = "graduation%",
  graduationCollege = "graduationCollege",
  graduationPassout = "graduationPassout",
  pg = "pg",
  "pg%" = "pg%",
  pgCollege = "pgCollege",
  pgPassout = "pgPassout",
  extra = "extra",
  jobId = "jobId",
}
export const fieldToLabelMapper: { [item in FIELD]: string } = {
  email: "Email",
  firstName: "Firstname",
  lastName: "Lastname",
  gender: "Gender",
  phone: "Phone Number",
  dob: "Date of birth",
  remark: "Remark",
  cctc: "Current CTC",
  altPhone: "Alternative phone Number",
  yearOfExp: "Experience (in year)",
  hobbies: "Hobbies",
  recruiter: "Recruiter",
  recruiterEmail: "Recruiter Email",
  noticePeriod: "Notice Period",
  source: "Source",
  skills: "Skills",
  expSalary: "Expected CTC",
  skypeId: "Skype Id",
  status: "Status",
  addressLine1: "Address Line1",
  city: "City",
  state: "State",
  country: "Country",
  "10th": "10th Percentage",
  "12th": "12th, PUC or Diploma Percentage",
  graduation: "Graduation",
  "graduation%": "Graduation Percentage",
  graduationCollege: "Graduation College",
  graduationPassout: "Graduation Passout",
  pg: "Post Graduation",
  "pg%": "Post Graduation Percentage",
  pgCollege: "Post Graduation College",
  pgPassout: "Post Graduation Passout",
  extra: "Extra",
  jobId: "Job Id",
};
export interface Pipeline_Schema {
  pipelineId: string;
  jobId: string;
  createdBy: string;
  isDeleted: boolean;
  createdAt: Date;
  pipeline: Pipeline_Obj_Schema[];
}

export interface Pipeline_Obj_Schema {
  key: string;
  label: string;
  data: Applicant_BY_JOB_SCHEMA[] | undefined;
}
export interface Candidate_Schema {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  dob: string;
  cctc: string;
  altPhone: string;
  yearOfExp: string;
  address: Address[];
  preferredLocation: any[];
  education: any[];
  hobbies: any[];
  recruiter: string;
  experience: any[];
  noticePeriod: string;
  source: string;
  rating?: number;
  skills: any[];
  websites: any[];
  expSalary: string;
  skypeId: string;
  status: string;
  remark: string;
  _id: string;
  candidateId: string;
  resume?: any[];
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
}

export interface Applicant_BY_JOB_SCHEMA {
  _id: string;
  applicationId: string;
  candidateId: string;
  jobId: string;
  assignee: string;
  status: string;
  active: boolean;
  comments?: Comment_Schema[];
  interactions?: Interaction_Schema[];
  createdAt?: string;
  updatedAt?: string;
  __v?: number;
  candidate?: Candidate_Schema;
}

export enum ApplicationStatus {
  REJECTED = "rejected",
  SELECTED = "selected",
}

export interface Comment_Schema {
  comment: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
}

export interface Interaction_Schema {
  status?: InteractionStatus;
  subStatus?: string;
  description?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
}

export enum InteractionStatus {
  // NOT_CONNECTED = 'Not connected',
  CALLBACK = "Callback",
  RNR = "R & R",
  NEXT_ROUND = "Move to next Round",
  SELECT = "Select",
  SELECTED_NOT_JOINED = "Selected not joined",
  REJECT = "Reject",
  CLOSURE = "Onboarded",
  // OTHER = "Other",
}

// enums
export enum CandidateStatus {
  AVAILABLE = "available",
  INPROCESS = "in_process",
}

export const Gender = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
  { value: "Other", label: "Other" },
];

export const Candidate_Status = [
  { value: "available", label: "Available" },
  { value: "in_process", label: "In Process" },
];

export interface User_Schema {
  _id?: string;
  userId?: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role?: string;
  active: boolean;
  __v?: number;
  dataFetched?: boolean;
  confirmPassword?: string;
}

export interface Recruiter_Jobs_Schema {
  _id?: string;
  applications?: string;
  job: JOB_DETAILS_SCHEMA;
}

export enum ReportGroupBy {
  DAILY = "daily",
  WEEKLY = "weekly",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export const GENERIC_ERROR = "Something went wrong, please try again";

export const EDUCATION_FIELDS = [
  { label: "Graduate", value: "Graduate" },
  { label: "Post Graduate", value: "Post Graduate" },
  { label: "PUC", value: "PUC" },
  { label: "Diploma", value: "Diploma" },
  { label: "SSLC", value: "SSLC" },
];

export const EXPERIENCE_FIELDS = [
  { label: "0-6m", value: "0-6m" },
  { label: "6m- 1 year", value: "6m- 1 year" },
  { label: "1-2 years", value: "1-2 years" },
  { label: "2-5 years", value: "2-5 years" },
  { label: "5-7 years", value: "5-7 years" },
  { label: "7- 10 + years", value: "7- 10 + years" },
];

export const SOURCE_FIELDS = [
  { label: "ATIL", value: "ATIL" },
  { label: "APNA", value: "APNA" },
  { label: "Missed Call", value: "Missed Call" },
  { label: "Social Media", value: "Social Media" },
  { label: "Grassroots Website", value: "Grassroots Website" },
  { label: "Walk-In", value: "Walk-In" },
  { label: "Employee Referral", value: "Employee Referral" },
  { label: "HR Call", value: "HR Call" },
  { label: "Job Hai", value: "Job Hai" },
  { label: "Job Drive", value: "Job Drive" },
];

export const CLIENT_CITY_LIST = [
  "Bangalore DD 3rd floor",
  "Bangalore DD 4th floor",
  "Bangalore OMR",
  "Mysore",
  "Belagavi",
  "Noida UP",
];
