import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { JOB } from "./useCreateJob";

export default function useSearchJob({
  name,
  active,
}: {
  name?: string;
  active: boolean;
}) {
  const fetchJobData = () => {
    let url = `job?name=${name}&active=${active}`;

    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery<JOB[]>(["search", "job", name], fetchJobData, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
}
