import * as XLXS from "xlsx";
import axios from "axios";

// component
import CustomLoader from "../../CustomLoader";
import SearchNotFound from "../../SearchNotFound";

// mui
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// hooks & HOCs
import moment from "moment";
import { useEffect, useState } from "react";
import { useCandidateLifecycle } from "../../react-query/candidate/useCandidateLifycycle";

const CandidatelifeCycleReport = () => {
  const [startDate, setStartDate] = useState(
    moment().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const { data: Candidates, isLoading } = useCandidateLifecycle({
    values: `start=${startDate}&end=${endDate}`,
  });
  const downloadCandidateLifeCycle = async () => {
    try {
      // let data = await axios.get(
      //   `candidate/report/lifecycle?start=${startDate}&end=${endDate}`
      // );
      let filterData: any[] = Candidates
        ? Candidates?.map((item: any) => {
            let pipelineMap = new Map<string, string>();
            pipelineMap.set("rejected", "Rejected");
            pipelineMap.set("selected", "Selected");
            item?.pipeline?.pipeline?.forEach((p: any) => {
              pipelineMap.set(p.key, p.label);
            });
            let statusObj: any = {};
            item?.statusHistory?.forEach((s: any, index: number) => {
              statusObj[`Status_${index + 1}`] = pipelineMap.has(s.status)
                ? pipelineMap.get(s.status)
                : s.status?.toUpperCase();
              statusObj[`Status_${index + 1}_Date`] = moment(
                s.createdAt
              ).format("DD-MM-YYYY");
            });
            return {
              Name:
                (item?.firstName ? item?.firstName : "") +
                " " +
                (item?.lastName ? item?.lastName : ""),
              Email: item?.email,
              Phone: item?.phone,
              Source: item?.source ? item.source : "NA",
              Job: item?.job?.name,
              ...statusObj,
            };
          })
        : [];
      const wb = XLXS.utils.book_new();
      const ws = XLXS.utils.json_to_sheet(filterData);
      XLXS.utils.book_append_sheet(wb, ws, "test");
      XLXS.writeFile(
        wb,
        `${moment(startDate, "YYYY-MM-DD").format("DD/MM/YYYY")}_${moment(
          endDate,
          "YYYY-MM-DD"
        ).format("DD/MM/YYYY")}_LIFECYCLE.csv`
      );
    } catch (error) {}
  };

  const columns = [
    {
      field: "candidateName",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return (
          (params?.row?.firstName ? params?.row?.firstName : "") +
          " " +
          (params?.row?.lastName ? params?.row?.lastName : "")
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "source",
      headerName: "Source",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.source ? params.row.source : "NA";
      },
    },
    {
      field: "job",
      headerName: "job",
      flex: 1,
      minWidth: 100,
      renderCell: (params: any) => {
        return params?.row?.job?.name;
      },
    },
  ];

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4">Candidate Life cycle Report</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            width: "100%",
            marginTop: "1rem",
            overflowY: "auto",
            background: "white",
            borderRadius: "0.5rem",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
          }}
        >
          <Grid container spacing={4} sx={{ p: 2 }}>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="Start date"
                  value={startDate ? moment(startDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setStartDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={3}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  inputFormat="DD/MM/yyyy"
                  label="End date"
                  value={endDate ? moment(endDate, "YYYY-MM-DD") : null}
                  onChange={(newValue: any) => {
                    newValue && setEndDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <Button variant="contained" onClick={downloadCandidateLifeCycle}>
                Download
              </Button>
            </Grid>
          </Grid>

          {!!Candidates && Candidates?.length ? (
            <div
              style={{
                maxHeight: 600,
                marginTop: "1rem",
              }}
            >
              <DataGrid
                autoHeight
                rows={
                  Candidates?.map((row: any, index: any) => {
                    return { id: index, ...row };
                  }) || []
                }
                columns={columns}
                sx={{
                  paddingInline: "0.5rem",
                  "& .MuiDataGrid-columnHeaderTitleContainer": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-cell": {
                    width: "100%",
                    justifyContent: "center",
                  },
                  "& .MuiDataGrid-columnHeaderRow": {
                    borderBottom: "1px solid rgba(0,0,0,0.2)",
                  },

                  "& .MuiDataGrid-row": {
                    "&:first-child": {
                      borderTop: "1px solid rgba(0,0,0,0.1)",
                    },
                  },
                }}
              />
            </div>
          ) : isLoading ? (
            <CustomLoader />
          ) : (
            <Box sx={{ height: "480px" }}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CandidatelifeCycleReport;
