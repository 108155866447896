import { useQuery } from "react-query";
import axios from "axios";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";

export const useApplication = ({
  jobId,
  skip,
  limit,
  candidate,
  candidateId,
}: {
  limit?: number;
  jobId?: string;
  skip?: number;
  candidate?: boolean;
  candidateId?: string;
}) => {
  let query = "/application?";
  let queryKey: string[] = ["application"];

  if (jobId !== undefined) {
    query = query + `&jobId=${jobId}`;
    queryKey = jobId ? [...queryKey, "job", jobId] : queryKey;
  }
  if (candidateId !== undefined) {
    query = query + `&candidateId=${candidateId}`;
    queryKey = candidateId ? [...queryKey, "candidate", candidateId] : queryKey;
  }
  if (candidate !== undefined) {
    query = query + `&candidate=${candidate}`;
  }
  if (skip !== undefined) {
    query = query + `&skip=${skip}`;
    queryKey = skip ? [...queryKey, skip.toString()] : queryKey;
  }
  if (limit !== undefined) {
    query = query + `&limit=${limit}`;
    queryKey = limit ? [...queryKey, limit.toString()] : queryKey;
  }

  const fetchApplications = () => {
    return axios.get(query).then((res) => res.data);
  };

  return useQuery<Applicant_BY_JOB_SCHEMA[]>(queryKey, fetchApplications, {
    refetchOnMount: "always",
    keepPreviousData: true,
  });
};
