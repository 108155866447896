import PropTypes from "prop-types";
// material
import { Box, Paper, Typography } from "@mui/material";

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
};

export default function SearchNotFound({ searchQuery = "", ...other }) {
  return (
    <Paper {...other}>
      <Box sx={{ py: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center", pt: 4, pb: 2 }}>
          <img src="/images/no-data-found.png" style={{ height: "260px" }} />
        </Box>
        {/* <Typography gutterBottom align="center" variant="subtitle1">
          Not found
        </Typography> */}
        <Typography
          variant="body2"
          align="center"
          sx={{ color: "rgba(0,0,0,0.75)" }}
        >
          &nbsp;
          <strong>&quot;{searchQuery}&quot;</strong>.
        </Typography>
      </Box>
    </Paper>
  );
}
