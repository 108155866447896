import { useEffect, useState } from "react";

// mui
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";

// utils
import {
  Applicant_BY_JOB_SCHEMA,
  Candidate_Schema,
} from "../../../utils/constants";

// component
import { TabPanel, a11yProps } from "../../../components/TabComponent";
import GetCandidateKeyView from "./GetCandidateKeyView";
import SearchNotFound from "../../../components/SearchNotFound";
import PdfViewer from "../../../components/PdfViewer";
import Iconify from "../../../components/Iconify";
import useUploadResume from "../../../components/react-query/candidate/useUploadResume";
import ViewApplicantComment from "./ViewApplicantComment";
import CustomLoader from "../../../components/CustomLoader";
import useGetCandidateById from "../../../components/react-query/candidate/useGetCandidateById";

const ViewCandidateDetails = ({
  candidate,
  applicant,
  hideComment,
}: {
  candidate: Candidate_Schema;
  applicant?: Applicant_BY_JOB_SCHEMA;
  hideComment?: boolean;
}) => {
  // tabs
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const {
    isLoading,
    isFetching,
    data: candidateData,
    refetch,
  } = useGetCandidateById(candidate.candidateId);

  // re-upload resume
  const { mutateAsync: uploadResume } = useUploadResume(
    candidate.candidateId,
    refetch
  );

  if (isLoading || isFetching) {
    return <CustomLoader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Personal Details" {...a11yProps(0)} />
              <Tab label="Education" {...a11yProps(1)} />
              <Tab label="Professional Details" {...a11yProps(2)} />
              <Tab label="Resume" {...a11yProps(3)} />
              {!hideComment && <Tab label="Interaction" {...a11yProps(4)} />}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              <GetCandidateKeyView
                title="Firstname"
                value={candidateData?.firstName}
                type="string"
                divider
              />
              <GetCandidateKeyView
                title="Lastname"
                value={candidateData?.lastName}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Email"
                value={candidateData?.email}
                type="string"
                divider
              />
              <GetCandidateKeyView
                title="Phone number"
                value={candidateData?.phone}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Gender"
                value={candidateData?.gender}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Date of Birth"
                value={candidateData?.dob}
                type="DD-MM-yyyy"
                divider
              />

              <GetCandidateKeyView
                title="Alternative Phone number"
                value={candidateData?.altPhone}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Hobbies"
                value={candidateData?.hobbies}
                type="stringArray"
                divider
              />

              <GetCandidateKeyView
                title="Skype Id"
                value={candidateData?.skypeId}
                type="string"
                divider
              />
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {candidateData?.education?.length ? (
                <GetCandidateKeyView
                  title="Education"
                  value={candidateData?.education}
                  type="educationArray"
                  divider
                />
              ) : (
                <Box sx={{ width: "100%" }}>
                  <SearchNotFound searchQuery={"No Results Found"} />
                </Box>
              )}
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              <GetCandidateKeyView
                title="Experience (in Year)"
                value={candidateData?.yearOfExp}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Preffered Location"
                value={candidateData?.preferredLocation}
                type="stringArray"
                divider
              />

              <GetCandidateKeyView
                title="Current CTC"
                value={candidateData?.cctc}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Expected CTC"
                value={candidateData?.expSalary}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Notice Period"
                value={candidateData?.noticePeriod}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Source"
                value={candidateData?.source}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Skills"
                value={candidateData?.skills}
                type="stringArray"
                divider
              />

              <GetCandidateKeyView
                title="Experience"
                value={candidateData?.experience}
                type="experienceArray"
                divider
              />
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<Iconify icon="material-symbols:upload" />}
                  >
                    Upload
                    <input
                      hidden
                      accept=".pdf"
                      multiple={false}
                      type="file"
                      onClick={() => {}}
                      onChange={async (e) => {
                        let files = e.target?.files;
                        if (files) {
                          let formData = new FormData();
                          formData.append("file", files[0]);
                          await uploadResume(formData);
                        }
                      }}
                    />
                  </Button>
                </Grid>
                {candidateData?.resume?.length ? (
                  <PdfViewer
                    filePath={`${process.env.REACT_APP_BE_SERVER}candidate/resume/${candidateData?.resume?.[0]?.filename}`}
                  />
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchNotFound searchQuery={"No Results Found"} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid
              container
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {applicant?._id && <ViewApplicantComment applicant={applicant} />}
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewCandidateDetails;
