import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { Candidate_Schema } from "../../../utils/constants";

export default function useGetAllApplicant({
  limit = 12,
  skip = 0,
  available,
}: {
  skip: number;
  limit: number;
  available?: boolean;
}) {
  const fetchApplicantData = () => {
    let url = `/candidate?skip=${skip}&limit=${limit}`;
    if (available !== undefined) {
      url = url + `&isAvailable=${available}`;
    }

    return axios.get(url).then((res) => res.data || []);
  };

  const queryClient = useQueryClient();

  return useQuery<Candidate_Schema[]>(
    ["applicants", skip, skip + limit],
    fetchApplicantData,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: (newCandidates: Candidate_Schema[]) => {
        const previousCandidates: Candidate_Schema[] | undefined =
          queryClient.getQueryData(["applicants"]);
        let allCandidates = previousCandidates
          ? [...previousCandidates, ...newCandidates]
          : [...newCandidates];
        queryClient.setQueryData(["applicants"], [...allCandidates]);

        const previousMultiCandidates: Candidate_Schema[] | undefined =
          queryClient.getQueryData(["multi-applicants"]);

        let allMultiCandidates = previousMultiCandidates
          ? [...previousMultiCandidates]
          : [];
        queryClient.setQueryData(["multi-applicants"], [...allMultiCandidates]);
      },
    }
  );
}
