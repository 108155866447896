import React, { useEffect, useState } from "react";

// layout
import withLayout from "../../../components/HOC/withLayout";
import AdminLayout from "../../../components/layouts/admin";
import useTitle from "../../../components/hooks/useTitle";
import { useNavigate, useParams } from "react-router-dom";
import useGetCandidateById from "../../../components/react-query/candidate/useGetCandidateById";
import CustomLoader from "../../../components/CustomLoader";
import EditCandidateForm from "../../../components/candidate/EditCandidateForm";
import { Candidate_Schema, Experience } from "../../../utils/constants";
import { convertStrArrayToObjArray } from "../../../utils/convertors";
import { Button, Grid } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";

const convertValuesForForm = (candidate: Candidate_Schema) => {
  let localCandidate: any = candidate;

  localCandidate.skills = convertStrArrayToObjArray(
    localCandidate?.skills,
    "skill"
  );
  localCandidate.websites = convertStrArrayToObjArray(
    localCandidate?.websites,
    "website"
  );
  localCandidate.hobbies = convertStrArrayToObjArray(
    localCandidate?.hobbies,
    "hobby"
  );
  localCandidate.preferredLocation = convertStrArrayToObjArray(
    localCandidate?.preferredLocation,
    "location"
  );

  if (localCandidate.experience?.length) {
    localCandidate.experience = localCandidate?.experience?.map(
      (data: Experience) => {
        return {
          ...data,
          jobDescription:
            (data?.jobDescription && data?.jobDescription[0]) || "",
        };
      }
    );
  }

  return localCandidate;
};

const EditCandidate = () => {
  const params = useParams();
  const navigate = useNavigate();

  useTitle("Edit");

  const {
    isLoading,
    isFetching,
    data: candidateData,
    refetch,
  } = useGetCandidateById(params?.id);

  if (isLoading || isFetching) {
    return <CustomLoader />;
  }

  return (
    <>
      <Grid container spacing={3} sx={{ px: 3 }}>
        <Grid item xs={12}>
          <Button onClick={() => navigate(-1)}>
            <ChevronLeftIcon /> Back
          </Button>
        </Grid>
        <Grid item xs={12}>
          {!!candidateData?._id && (
            <EditCandidateForm
              candidate={convertValuesForForm(candidateData)}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default withAuth(
  withLayout(EditCandidate, AdminLayout),
  AUTH_ROLES.ADMIN
);
