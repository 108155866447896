import React from "react";
import {
  Applicant_BY_JOB_SCHEMA,
  Comment_Schema,
} from "../../../utils/constants";
import { useGetApplicationById } from "../../../components/react-query/application/useGetApplicationById";
import CustomLoader from "../../../components/CustomLoader";
import SearchNotFound from "../../../components/SearchNotFound";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { capitalize } from "lodash";
import moment from "moment";

const ViewApplicantComment = ({
  applicant,
}: {
  applicant: Applicant_BY_JOB_SCHEMA;
}) => {
  const { isLoading: applicantLoading, data: applicantData } =
    useGetApplicationById(applicant?.candidateId);
  return (
    <>
      {applicantLoading ? (
        <CustomLoader />
      ) : applicantData?.length ? (
        applicantData?.map((applicant) => (
          <Grid
            container
            key={applicant?._id}
            spacing={1}
            sx={{ p: 3, width: "70%" }}
          >
            <Grid item xs={12}>
              <Typography variant="h5">
                {capitalize(applicant?.status)} :
              </Typography>
            </Grid>
            {applicant?.comments?.map((comment: Comment_Schema, index) => (
              <Grid item xs={12} key={index}>
                <Box>
                  <Typography>
                    {comment?.comment}{" "}
                    <span
                      style={{ fontWeight: 600, color: "rgba(0,0,0,0.65)" }}
                    >
                      ({moment.utc(comment?.createdAt).format("DD-MM-YYYY")})
                    </span>
                  </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
              </Grid>
            ))}
          </Grid>
        ))
      ) : (
        <SearchNotFound searchQuery={"No Results Found"} />
      )}
    </>
  );
};

export default ViewApplicantComment;
