import axios from "axios";
import { useMutation } from "react-query";
import {
  JOB_DETAILS,
  JOB_DETAILS_SCHEMA,
} from "../../admin/Job/CreateJobDetails";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";

export interface JOB extends JOB_DETAILS_SCHEMA {
  jobId: string;
  applications?: Applicant_BY_JOB_SCHEMA[];
}

const useCreateJob = () => {
  return useMutation((values: JOB_DETAILS) =>
    axios.post("/job", values).then((res) => res.data)
  );
};

export default useCreateJob;
