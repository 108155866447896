import axios from "axios";
import { useQuery } from "react-query";
import { Pipeline_Schema } from "../../../utils/constants";

export const useGetPipelineByJob = (id: string | undefined) => {
  const fetchPipelineByJobIdDetails = () => {
    return axios.get(`/pipeline/${id}`).then((res) => res.data);
  };

  return useQuery<Pipeline_Schema>(
    ["pipeline", id],
    fetchPipelineByJobIdDetails,
    {
      refetchOnMount: "always",
      keepPreviousData: true,
      retry: false,
    }
  );
};
