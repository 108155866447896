//  mui
import { Chip, Grid, Typography } from "@mui/material";
import { MAPPING } from "../react-query/mapping/useCreateMapper";
import { fieldToLabelMapper } from "../../utils/constants";

export default function PreviewMappings({ mappings }: { mappings: MAPPING[] }) {
  return (
    <Grid container spacing={2}>
      {mappings.map((item, index) => (
        <Grid
          item
          xs={12}
          md={6}
          key={index}
          display={"flex"}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-around"}
        >
          <Chip label={item.excelKey} color="warning" />
          <Typography>-</Typography>
          <Chip label={fieldToLabelMapper[item.beKey]} color="info" />
        </Grid>
      ))}
    </Grid>
  );
}
