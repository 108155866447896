import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { PROCESS } from "./useCreateProcess";
import { toast } from "react-toastify";

const useEditProcess = (processId: string, deleted?: boolean) => {
  const queryClient = useQueryClient();

  return useMutation(
    (values: PROCESS) =>
      axios.patch(`/process/${processId}`, values).then((res) => res.data),
    {
      onSuccess: (updatedData) => {
        toast.success(
          !deleted
            ? "Process removed successfully"
            : "Process updated successfully"
        );
        let processes: PROCESS[] | undefined =
          queryClient.getQueryData("processes");
        let updatedProcesses: PROCESS[] = [];
        if (updatedData.active === false) {
          updatedProcesses = processes
            ? processes?.filter(
                (p: PROCESS, index) => p.processId !== updatedData.processId
              )
            : [];
        } else {
          updatedProcesses = processes
            ? processes?.map((p: PROCESS, index) =>
                p.processId === updatedData.processId
                  ? { ...updatedData }
                  : { ...p }
              )
            : [];
        }
        queryClient.setQueryData(["processes"], [...updatedProcesses]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useEditProcess;
