import { useParams } from "react-router-dom";
import * as Yup from "yup";

// form
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";

// hocs & hooks
import { JOB_DETAILS } from "./CreateJobDetails";

// material ui
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import useCreateJob from "../../react-query/jobs/useCreateJob";

const JobSchema = Yup.object().shape({
  name: STRING_VALIDATOR,
  // companyDescription: Yup.string(),
  // description: Yup.string(),
  yearOfExp: STRING_VALIDATOR,
  requirement: Yup.array(),
  responsibility: Yup.array(),
  skills: Yup.array(),
  expired: STRING_VALIDATOR,
  address: Yup.object().shape({
    addressLine1: Yup.string(),
    addressLine2: Yup.string(),
    state: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    pincode: Yup.string(),
  }),
  salary: Yup.string(),
  noticePeriod: Yup.string(),
});

const CreateJobForm = ({ handleClose }: { handleClose: () => void }) => {
  const params = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<JOB_DETAILS>({
    resolver: yupResolver(JobSchema),
  });

  const {
    fields: RequirementFields,
    append: AddRequirementField,
    remove: RemoveRequirementField,
  } = useFieldArray({
    control,
    name: "requirement",
  });

  const {
    fields: ResponsibilityFields,
    append: AddResponsibilityField,
    remove: RemoveResponsibilityField,
  } = useFieldArray({
    control,
    name: "responsibility",
  });

  const {
    fields: SkillsFields,
    append: AddSkillsField,
    remove: RemoveSkillsField,
  } = useFieldArray({
    control,
    name: "skills",
  });

  const expired = watch("expired");

  const { mutateAsync: createJob } = useCreateJob();
  const reverseArrayConverter = (array: any[], key: string) => {
    return array?.map((item: any) => item?.[key]);
  };
  const onSubmit = async (values: JOB_DETAILS) => {
    try {
      const job = await createJob({
        ...values,
        requirement: reverseArrayConverter(
          values?.requirement || [],
          "requirement"
        ) || [{ requirement: "" }],
        responsibility: reverseArrayConverter(
          values?.responsibility || [],
          "responsibility"
        ) || [{ responsibility: "" }],
        skills: reverseArrayConverter(values?.skills || [], "skills") || [
          { skill: "" },
        ],

        processId: params?.id ? params.id : "",
        createdBy: "",
      });
      handleClose();
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register("name")}
              label="Job Role"
              error={Boolean(errors.name)}
              helperText={Boolean(errors.name) && errors.name?.message}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              multiline
              maxRows={2}
              fullWidth
              {...register("description")}
              label="Description"
              error={Boolean(errors.description)}
              helperText={
                Boolean(errors.description) && errors.description?.message
              }
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              multiline
              maxRows={4}
              fullWidth
              {...register("companyDescription")}
              label="Company Description"
              error={Boolean(errors.companyDescription)}
              helperText={
                Boolean(errors.companyDescription) &&
                errors.companyDescription?.message
              }
            />
          </Grid> */}

          {/* Requirement fields */}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Job Description</Typography>
            </Grid>
            {RequirementFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Description"
                      {...register(`requirement.${index}.requirement`)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveRequirementField(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddRequirementField({
                    requirement: "",
                  });
                }}
              >
                Add Description
              </Button>
            </Grid>
          </Grid>

          {/* responsibility fields */}
          {/* <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Responsibility</Typography>
            </Grid>
            {ResponsibilityFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Responsibility"
                      {...register(`responsibility.${index}.responsibility`)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveResponsibilityField(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddResponsibilityField({
                    responsibility: "",
                  });
                }}
              >
                Add Responsibility
              </Button>
            </Grid>
          </Grid> */}

          {/* skills fields */}
          <Grid item xs={12} container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Skills</Typography>
            </Grid>
            {SkillsFields.map((field, index) => {
              return (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      label="Skill"
                      {...register(`skills.${index}.skills`)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Tooltip title="Delete">
                      <IconButton
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveSkillsField(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  AddSkillsField({
                    skills: "",
                  });
                }}
              >
                Add Skill
              </Button>
            </Grid>
          </Grid>

          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Location</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                {...register("address.addressLine1")}
                label="Location"
                error={Boolean(errors?.address?.addressLine1)}
                helperText={
                  Boolean(errors?.address?.addressLine1) &&
                  errors?.address?.addressLine1?.message
                }
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                fullWidth
                {...register("address.addressLine2")}
                label="Address Line 2"
                error={Boolean(errors?.address?.addressLine2)}
                helperText={
                  Boolean(errors?.address?.addressLine2) &&
                  errors?.address?.addressLine2?.message
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                {...register("address.city")}
                label="City"
                error={Boolean(errors?.address?.city)}
                helperText={
                  Boolean(errors?.address?.city) &&
                  errors?.address?.city?.message
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                {...register("address.state")}
                label="State"
                error={Boolean(errors?.address?.state)}
                helperText={
                  Boolean(errors?.address?.state) &&
                  errors?.address?.state?.message
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                {...register("address.country")}
                label="Country"
                error={Boolean(errors?.address?.country)}
                helperText={
                  Boolean(errors?.address?.country) &&
                  errors?.address?.country?.message
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                {...register("address.pincode")}
                label="pincode"
                error={Boolean(errors?.address?.pincode)}
                helperText={
                  Boolean(errors?.address?.pincode) &&
                  errors?.address?.pincode?.message
                }
              />
            </Grid> */}
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              type="text"
              {...register("yearOfExp")}
              label="Years of Experience"
              error={Boolean(errors.yearOfExp)}
              helperText={
                Boolean(errors.yearOfExp) && errors.yearOfExp?.message
              }
            />
          </Grid>

          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Expiry Date"
                inputFormat="DD/MM/yyyy"
                value={expired ? moment(expired, "YYYY-MM-DD") : null}
                onChange={(newValue) => {
                  newValue &&
                    setValue("expired", newValue?.format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    error={Boolean(errors.expired)}
                    helperText={
                      Boolean(errors.expired) && errors.expired?.message
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register("salary")}
              label="Salary"
              error={Boolean(errors.salary)}
              helperText={Boolean(errors.salary) && errors.salary?.message}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              {...register("noticePeriod")}
              label="Notice Period"
              error={Boolean(errors.noticePeriod)}
              helperText={
                Boolean(errors.noticePeriod) && errors.noticePeriod?.message
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              type="number"
              defaultValue={1}
              {...register("openPosition")}
              label="Open Positions"
              error={Boolean(errors.openPosition)}
              helperText={
                Boolean(errors.openPosition) && errors.openPosition?.message
              }
            />
          </Grid>
        </Grid>

        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          sx={{ my: 2 }}
        >
          Create Job
        </LoadingButton>
      </form>
    </>
  );
};

export default CreateJobForm;
