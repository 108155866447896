import axios from "axios";
import { useQuery } from "react-query";

const useJobMetadata = ({
  skip,
  limit,
  processId,
  active,
}: {
  skip?: number;
  limit?: number;
  processId?: string;
  active?: boolean;
}) => {
  const fetchJobMetadata = () => {
    return axios
      .get(
        `job/metadata?${skip !== undefined ? `skip=${skip}` : ""}${
          limit !== undefined ? `&limit=${limit}` : ""
        }${processId !== undefined ? `&processId=${processId}` : ""}${
          active !== undefined ? `&active=${active}` : ""
        }`
      )
      .then((res) => res.data || []);
  };

  return useQuery<number>(
    ["jobMetaData", processId, active],
    fetchJobMetadata,
    {
      keepPreviousData: true,
      refetchOnMount: "always",
    }
  );
};

export default useJobMetadata;
