// hocs & hooks
import moment from "moment";
import { PROCESS } from "../../react-query/process/useCreateProcess";

// mui
import { Box, Divider, Grid, Typography } from "@mui/material";

const viewGridItem = (key: string, value: string | undefined) => {
  return (
    <Box>
      <Typography variant="h6">{key}</Typography>
      <Typography sx={{ fontSize: "1.2rem" }}>{value}</Typography>
      <Divider sx={{ my: 2 }} />
    </Box>
  );
};

const ViewProcessDetails = ({ process }: { process?: PROCESS }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          spacing: 3,
          p: 3,
          bgcolor: "white",
          borderRadius: 1,
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          width: "50%",
          border: "1.5px solid #ddd",
        }}
      >
        {process?.name && viewGridItem("Name :", process?.name)}
        {process?.processId && viewGridItem("Id :", process?.processId)}
        {process?.active &&
          viewGridItem("Active :", process?.active ? "True" : "False")}
        {process?.createdBy && viewGridItem("Created By :", process?.createdBy)}
        {process?.createdAt &&
          viewGridItem(
            "Created At :",
            moment(process?.createdAt).format("DD-MM-YYYY")
          )}
      </Box>
    </>
  );
};

export default ViewProcessDetails;
