import axios from "axios";
import { useQuery } from "react-query";

const useCandidateMetaData = ({
  skip,
  limit,
}: {
  skip?: number;
  limit?: number;
}) => {
  const fetchJobMetadata = () => {
    return axios
      .get(
        `candidate/metadata?${skip !== undefined ? `skip=${skip}` : ""}${
          limit !== undefined ? `&limit=${limit}` : ""
        }`
      )
      .then((res) => res.data || []);
  };

  return useQuery<number>(["candidateMetaData"], fetchJobMetadata, {
    keepPreviousData: true,
    refetchOnMount: "always",
  });
};

export default useCandidateMetaData;
