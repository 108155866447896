import React from "react";

interface IProps {
  WrappedComponent: React.ComponentType;
  Layout: React.ComponentType<{ children: React.ReactNode }>;
}

const withLayout = (
  WrappedComponent: React.ComponentType,
  Layout: React.ComponentType<{ children: React.ReactNode }>
) => {
  const hocComponent = () => {
    return (
      <Layout>
        <WrappedComponent />
      </Layout>
    );
  };

  return hocComponent;
};

export default withLayout;
