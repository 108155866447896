import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//  mui
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// react-query

// utils
import { STRING_VALIDATOR } from "../../utils/yup-validators";
import {
  Applicant_BY_JOB_SCHEMA,
  Comment_Schema,
  User_Schema,
} from "../../utils/constants";
import useEditApplication from "../react-query/application/useEditApplication";
import { useQuery, useQueryClient } from "react-query";

interface CreateComment {
  comment: string;
}

const CommentSchema = Yup.object().shape({
  comment: STRING_VALIDATOR,
});

export default function CreateComment({
  rejectApplication,
  applicant,
  rejected,
  status,
  close,
}: {
  rejectApplication?: (applicationId: string) => void;
  applicant: Applicant_BY_JOB_SCHEMA;
  rejected: boolean;
  status?: string;
  close: () => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateComment>({
    resolver: yupResolver(CommentSchema),
  });

  const queryClient = useQueryClient();

  const {
    data: userData,
  }: {
    data: User_Schema | undefined;
    isLoading: boolean;
    isFetched: boolean;
  } = useQuery<User_Schema>("userData");
  const { mutateAsync: updateApplication } = useEditApplication(
    rejected ? "rejected" : "updated",
    close
  );

  const onSubmit = async (values: CreateComment) => {
    let prevComments: Comment_Schema[] | undefined = applicant?.comments;

    prevComments?.push({
      comment: values.comment,
      userId: userData?.userId || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
    } as Comment_Schema);

    if (prevComments?.length) {
      let oldApplications: Applicant_BY_JOB_SCHEMA[] | undefined =
        queryClient.getQueryData(["applicant", applicant?.jobId]);
      try {
        let newApplication = await updateApplication({
          applicationId: applicant.applicationId,
          status: rejected ? "rejected" : status,
          comments: prevComments,
        } as Applicant_BY_JOB_SCHEMA);
        let updatedApplications: Applicant_BY_JOB_SCHEMA[] = oldApplications
          ? oldApplications?.map((application) => {
              if (application.applicationId === newApplication.applicationId) {
                return { ...application, ...newApplication };
              } else {
                return { ...application };
              }
            })
          : [];
        queryClient.setQueryData(
          ["applicant", applicant.jobId],
          [...updatedApplications]
        );
      } catch (error) {
        oldApplications &&
          queryClient.setQueryData(
            ["applicant", applicant.jobId],
            [...oldApplications]
          );
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            autoFocus
            label="Comment"
            fullWidth
            {...register("comment")}
            variant="outlined"
            error={Boolean(errors.comment)}
            helperText={Boolean(errors.comment) && errors.comment?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" variant="contained">
            Add Comment
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
}
