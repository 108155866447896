import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { FIELD } from "../../../utils/constants";

export interface MAPPING {
  excelKey: string;
  beKey: FIELD;
}

export interface CREATE_MAPPER {
  name: string;
  mappings: MAPPING[];
}

export interface MAPPER extends CREATE_MAPPER {
  mapperId: string;
  active: boolean;
  jobIds: string[];
  createdAt: string;
}

const useCreateMapper = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: CREATE_MAPPER) =>
      axios.post("/mapper", values).then((res) => res.data),
    {
      onSuccess: (newProcess: MAPPER) => {},
    }
  );
};

export default useCreateMapper;
