import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const useCreateMultiApplicant = (close: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (
      values:
        | {
            jobId: string;
            status: string;
            candidateIds: string[];
            assignee: string;
          }
        | {}
    ) => axios.post("/application/many", values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Multiple Application added successful");
        close();

        queryClient.setQueryData(["multi-applicants"], []);
        queryClient.resetQueries(["applicants"]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useCreateMultiApplicant;
