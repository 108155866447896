import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import { useAdvanceSearchCandidate } from "../../react-query/candidate/useAdvanceSearchCandidate";

type Inputs = {
  search: {
    key: string;
    value: string;
  }[];
};

const SearchSchema = Yup.object().shape({
  search: Yup.array().of(
    Yup.object().shape({
      key: STRING_VALIDATOR,
      value: STRING_VALIDATOR,
    })
  ),
});

const CreateAdvanceSearch = ({
  showAdvanceSearch,
}: {
  showAdvanceSearch: boolean;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    resolver: yupResolver(SearchSchema),
  });

  const { fields, append, remove } = useFieldArray({
    name: "search",
    control,
  });

  const [searchKeys, setSeachKeys] = useState<string>("");
  const { refetch, remove: removeSearch } = useAdvanceSearchCandidate({
    values: searchKeys,
  });

  const onSubmit = async (values: Inputs) => {
    const localValues = [...values?.search];

    if (!localValues?.length) {
      removeSearch();
      return setSeachKeys("");
    }

    const queryString = localValues
      ?.map((obj: { key: string; value: string }) => {
        return `${encodeURIComponent(obj.key)}=${encodeURIComponent(
          obj.value
        )}`;
      })
      .join("&");

    setSeachKeys(queryString);
  };

  useEffect(() => {
    if (searchKeys.length) {
      refetch();
    }

    if (showAdvanceSearch == false) {
      setSeachKeys("");
    }
  }, [searchKeys, showAdvanceSearch]);
  return (
    <Box sx={{ ml: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {showAdvanceSearch ? (
          <Grid container spacing={2} sx={{ width: "80%" }}>
            {fields.map((field, index) => {
              return (
                <Grid item container spacing={2} xs={12} key={field.id}>
                  <Grid item xs={5}>
                    <FormControl
                      fullWidth
                      error={Boolean(errors?.search?.[index]?.key)}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Search Key
                      </InputLabel>
                      <Select
                        {...register(`search.${index}.key` as const, {
                          required: true,
                        })}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={getValues(`search.${index}.key`)}
                        label="Search Key"
                        onChange={(e: any) => {
                          setValue(`search.${index}.key`, e.target.value, {
                            shouldValidate: true,
                          });
                        }}
                      >
                        <MenuItem value={"email"}>Email</MenuItem>
                        <MenuItem value={"firstName"}>First Name</MenuItem>
                        <MenuItem value={"lastName"}>Last Name</MenuItem>
                        <MenuItem value={"gender"}>
                          Gender (male/female)
                        </MenuItem>
                        <MenuItem value={"phone"}>Phone</MenuItem>
                        <MenuItem value={"dob"}>DOB (YYYY-MM-DD)</MenuItem>
                        <MenuItem value={"cctc"}>CCTC</MenuItem>
                        <MenuItem value={"altPhone"}>
                          Alternative Phone
                        </MenuItem>
                        <MenuItem value={"yearOfExp"}>
                          Year Of Experience
                        </MenuItem>
                        <MenuItem value={"recruiter"}>Recruiter</MenuItem>
                        <MenuItem value={"rating"}>Rating</MenuItem>
                        <MenuItem value={"noticePeriod"}>
                          Notice Period
                        </MenuItem>
                        <MenuItem value={"source"}>Source</MenuItem>
                        <MenuItem value={"expSalary"}>Expected Salary</MenuItem>
                        <MenuItem value={"remark"}>Remark</MenuItem>
                      </Select>
                      <FormHelperText>
                        {errors?.search?.[index]?.key &&
                          errors?.search?.[index]?.key?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      {...register(`search.${index}.value` as const, {
                        required: true,
                      })}
                      placeholder="enter value"
                      label="Value"
                      fullWidth
                      error={Boolean(errors?.search?.[index]?.value)}
                      helperText={
                        Boolean(errors?.search?.[index]?.value) &&
                        errors?.search?.[index]?.value?.message
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Tooltip title="Delete">
                      <IconButton
                        type="button"
                        color="error"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => {
                  append({
                    key: "",
                    value: "",
                  });
                }}
              >
                Add search key
              </Button>

              <Button
                variant="contained"
                sx={{ ml: 3 }}
                onClick={() => {
                  setSeachKeys("");
                  removeSearch();
                  remove();
                }}
              >
                Clear
              </Button>
            </Grid>

            <Grid item xs={3}>
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                sx={{ my: 2 }}
              >
                Search Candidate
              </LoadingButton>
            </Grid>
          </Grid>
        ) : null}
      </form>
    </Box>
  );
};

export default CreateAdvanceSearch;
