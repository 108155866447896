import * as Yup from "yup";
import {
  CLIENT_CITY_LIST,
  Candidate_Schema,
  EDUCATION_FIELDS,
  EXPERIENCE_FIELDS,
  Experience,
  Gender,
  SOURCE_FIELDS,
} from "../../utils/constants";
import {
  convertArrayFormatDate,
  convertArrayObjToArrayStr,
  utcConvertorDate,
} from "../../utils/convertors";
import { LoadingButton } from "@mui/lab";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

// utils
import {
  EMAIL_VALIDATOR,
  PHONE_VALIDATOR,
  STRING_VALIDATOR,
} from "../../utils/yup-validators";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CREATE_CANDIDATE } from "../react-query/candidate/useCreateCandidate";
import { useState } from "react";
import useEditCandidate from "../react-query/candidate/useEditCandidate";
import useUploadResume from "../react-query/candidate/useUploadResume";
import { useQueryClient } from "react-query";

const CandidateSchema = Yup.object().shape({
  email: EMAIL_VALIDATOR,
  phone: PHONE_VALIDATOR,
  // dob: Yup.string(),
  firstName: STRING_VALIDATOR,
  // lastName: STRING_VALIDATOR,
  gender: Yup.string(),
  cctc: Yup.string(),
  // altPhone: Yup.string(),
  yearOfExp: Yup.string(),
  preferredLocation: Yup.array().of(
    Yup.object().shape({
      location: Yup.string(),
    })
  ),
  // hobbies: Yup.array().of(
  //   Yup.object().shape({
  //     hobby: Yup.string(),
  //   })
  // ),
  recruiter: Yup.string(),
  noticePeriod: Yup.string(),
  address: Yup.array().of(
    Yup.object().shape({
      addressLine1: Yup.string(),
      addressLine2: Yup.string(),
      state: Yup.string(),
      city: Yup.string(),
      country: Yup.string(),
      pincode: Yup.string(),
      isCurrent: Yup.boolean(),
    })
  ),
  experience: Yup.array().of(
    Yup.object().shape({
      jobRole: Yup.string(),
      start: Yup.string(),
      end: Yup.string(),
      isCurrent: Yup.boolean(),
      jobDescription: Yup.string(),
      employer: Yup.string(),
    })
  ),
  source: Yup.string(),
  // skills: Yup.array().of(
  //   Yup.object().shape({
  //     skill: Yup.string(),
  //   })
  // ),
  // websites: Yup.array().of(
  //   Yup.object().shape({
  //     website: Yup.string(),
  //   })
  // ),
  expSalary: Yup.string(),
  // skypeId: Yup.string(),
  // status: STRING_VALIDATOR,
  remark: Yup.string(),
  referral: Yup.string(),
});

const convertValuesForEditForm = (obj: any) => {
  obj.skills = obj?.skills?.length
    ? convertArrayObjToArrayStr(obj.skills, "skill")
    : obj?.skills;
  obj.websites = convertArrayObjToArrayStr(obj.websites, "website");
  obj.hobbies = convertArrayObjToArrayStr(obj.hobbies, "hobby");
  obj.preferredLocation = obj?.preferredLocation?.length
    ? convertArrayObjToArrayStr(obj.preferredLocation, "location")
    : obj?.preferredLocation;

  if (obj.education?.length) {
    obj.education = convertArrayFormatDate(obj.education);
  }
  if (obj.experience?.length) {
    let tempObj = convertArrayFormatDate(obj.experience);

    obj.experience = tempObj?.map((obj: Experience) => {
      let localObj = [];
      localObj.push(obj.jobDescription);

      return { ...obj, jobDescription: localObj };
    });
  }
  return obj;
};

const EditCandidateForm = ({ candidate }: { candidate: Candidate_Schema }) => {
  const defaultValues = {
    email: candidate?.email || "",
    phone: candidate?.phone || "",
    dob: candidate?.dob || "",
    firstName: candidate?.firstName || "",
    lastName: candidate?.lastName || "",
    gender: candidate?.gender || "",
    cctc: candidate?.cctc || "",
    altPhone: candidate?.altPhone || "",
    yearOfExp: candidate?.yearOfExp || "",
    preferredLocation:
      candidate?.preferredLocation?.length === 0
        ? [{ location: "" }]
        : candidate.preferredLocation,
    hobbies: candidate?.hobbies || [{ hobby: "" }],
    recruiter: candidate?.recruiter || "",
    noticePeriod: candidate?.noticePeriod || "",
    address:
      candidate?.address?.length === 0
        ? [
            {
              addressLine1: "",
            },
          ]
        : candidate.address,
    experience: candidate?.experience || [
      {
        jobRole: "",
        start: "",
        end: "",
        isCurrent: false,
        jobDescription: "",
        employer: "",
      },
    ],
    education: candidate?.education?.length
      ? candidate.education
      : [
          {
            // start: "",
            // institute: "",
            // isCurrent: false,
            // marks: "",
            // end: "",
            // totalMarks: "",
            type: "",
          },
        ],
    source: candidate?.source || "",
    skills: candidate?.skills || [{ skill: "" }],
    websites: candidate?.websites || [{ website: "" }],
    expSalary: candidate?.expSalary || "",
    skypeId: candidate?.skypeId || "",
    status: candidate?.status || "",
    remark: candidate?.remark || "",
  };

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
    control,
  } = useForm<CREATE_CANDIDATE>({
    resolver: yupResolver(CandidateSchema),
    defaultValues: { ...defaultValues },
    mode: "onBlur",
  });

  const {
    fields: EducationFields,
    append: AddEducationField,
    remove: RemoveEducationField,
  } = useFieldArray({
    control,
    name: "education",
  });

  const {
    fields: ExperienceFields,
    append: AddExperienceField,
    remove: RemoveExperienceField,
  } = useFieldArray({
    control,
    name: "experience",
  });
  const {
    fields: AddressFields,
    append: AddAddressField,
    remove: RemoveAddressField,
  } = useFieldArray({
    control,
    name: "address",
  });

  const {
    fields: PreferredLocationFields,
    append: AddPreferredLocationField,
    remove: RemovePreferredLocationField,
  } = useFieldArray({
    control,
    name: "preferredLocation",
  });

  // const {
  //   fields: HobbiesFields,
  //   append: AddHobbiesField,
  //   remove: RemoveHobbiesField,
  // } = useFieldArray({
  //   control,
  //   name: "hobbies",
  // });

  // const {
  //   fields: SkillsFields,
  //   append: AddSkillsField,
  //   remove: RemoveSkillsField,
  // } = useFieldArray({
  //   control,
  //   name: "skills",
  // });

  // const {
  //   fields: WebsitesFields,
  //   append: AddWebsitesField,
  //   remove: RemoveWebsitesField,
  // } = useFieldArray({
  //   control,
  //   name: "websites",
  // });

  // const dob = watch("dob");

  const sourceValue = watch("source");

  const [resumeObj, setResumeObj] = useState<File | null>();

  const { mutate: editCandidateMutate } = useEditCandidate(
    candidate.candidateId
  );

  const queryClient = useQueryClient();

  const { mutateAsync: uploadResume } = useUploadResume(candidate.candidateId);

  const onSubmit = async (values: CREATE_CANDIDATE) => {
    let convertedValues = await convertValuesForEditForm(values);

    await editCandidateMutate(convertedValues);

    //  replace applicant with updated candidate
    const previousCandidates: Candidate_Schema[] | undefined =
      queryClient.getQueryData(["applicants"]);

    if (previousCandidates?.length) {
      const index = previousCandidates.findIndex(
        (candidateData: Candidate_Schema) =>
          candidateData.candidateId === candidate.candidateId
      );

      if (index !== -1) {
        previousCandidates[index] = {
          ...previousCandidates[index],
          ...convertedValues,
        };
      }

      queryClient.setQueryData(["applicants"], [...previousCandidates]);
    }

    // upload resume
    if (resumeObj) {
      let formData = new FormData();
      formData.append("file", resumeObj);
      await uploadResume(formData);
    }
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: "rgba(255,255,255,0.99)",
          p: 4,
          ml: 0,
          borderRadius: 1,
          border: "1px solid #ddd",
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("email")}
                label="Email"
                error={Boolean(errors.email)}
                helperText={Boolean(errors.email) && errors.email?.message}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("firstName")}
                label="Name"
                error={Boolean(errors.firstName)}
                helperText={
                  Boolean(errors.firstName) && errors.firstName?.message
                }
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("lastName")}
                label="Lastname"
                error={Boolean(errors.lastName)}
                helperText={
                  Boolean(errors.lastName) && errors.lastName?.message
                }
              />
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("phone")}
                label="Phone Number"
                type="number"
                error={Boolean(errors.phone)}
                helperText={Boolean(errors.phone) && errors.phone?.message}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("altPhone")}
                label="Alternative Phone Number"
                error={Boolean(errors.altPhone)}
                helperText={
                  Boolean(errors.altPhone) && errors.altPhone?.message
                }
              />
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("cctc")}
                label="Current CTC"
                error={Boolean(errors.cctc)}
                helperText={Boolean(errors.cctc) && errors.cctc?.message}
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("expSalary")}
                label="Expected CTC"
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("noticePeriod")}
                label="Notice period"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                select
                fullWidth
                defaultValue={getValues("gender") || ""}
                {...register("gender")}
                label="Gender"
                error={Boolean(errors.gender)}
                helperText={Boolean(errors.gender) && errors.gender?.message}
              >
                {Gender.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid xs={12}></Grid>

            {/* Address fields */}
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Current Location</Typography>
              </Grid>
              {AddressFields.map((field, index) => {
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Current location"
                        {...register(`address.${index}.addressLine1`)}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Address Lane 2"
                        {...register(`address.${index}.addressLine2`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Pincode"
                        {...register(`address.${index}.pincode`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="City"
                        {...register(`address.${index}.city`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="State"
                        {...register(`address.${index}.state`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Country"
                        {...register(`address.${index}.country`)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <FormControlLabel
                        label="Is current Address?"
                        control={
                          <Checkbox
                            checked={isCurrent ? Boolean(isCurrent) : false}
                            {...register(`address.${index}.isCurrent`)}
                          />
                        }
                      />

                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        onClick={() => {
                          RemoveAddressField(index);
                        }}
                      >
                        Remove Address
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid> */}
                  </Grid>
                );
              })}
              {/* <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddAddressField({
                      addressLine1: "",
                      addressLine2: "",
                      pincode: "",
                      city: "",
                      state: "",
                      country: "",
                      isCurrent: false,
                    });
                  }}
                >
                  Add Address
                </Button>
              </Grid> */}
            </Grid>

            {/* Preferred address fields */}
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Preferred Location</Typography>
              </Grid>
              {PreferredLocationFields.map((field, index) => {
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        defaultValue={getValues(
                          `preferredLocation.${index}.location`
                        )}
                        label="Preferred location"
                        {...register(`preferredLocation.${index}.location`)}
                      >
                        {CLIENT_CITY_LIST.map((city) => (
                          <MenuItem key={city} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    {/* <Grid item xs={12} md={6} lg={4}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemovePreferredLocationField(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid> */}
                  </Grid>
                );
              })}
              {/* <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddPreferredLocationField({
                      location: "",
                    });
                  }}
                >
                  Add Location
                </Button>
              </Grid> */}
            </Grid>

            {/* Education fields */}
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Education</Typography>
              </Grid>
              {EducationFields.map((field, index) => {
                // let start = watch(`education.${index}.start`);
                // let end = watch(`education.${index}.end`);
                // let isCurrent = watch(`education.${index}.isCurrent`);
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        defaultValue={
                          getValues(`education.${index}.type`) || ""
                        }
                        label="Select education"
                        {...register(`education.${index}.type`)}
                      >
                        {EDUCATION_FIELDS.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {/* <Grid item xs={12} md={6} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Start date"
                          value={start ? utcConvertorDate(start) : null}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            newValue &&
                              setValue(`education.${index}.start`, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="End date"
                          value={end ? utcConvertorDate(end) : null}
                          inputFormat="DD/MM/YYYY"
                          onChange={(newValue) => {
                            newValue &&
                              setValue(`education.${index}.end`, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Institute Name"
                        {...register(`education.${index}.institute`)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Obtained Marks"
                        {...register(`education.${index}.marks`)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Total Marks"
                        {...register(`education.${index}.totalMarks`)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      alignItems={"center"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <FormControlLabel
                        label="Is current Education?"
                        control={
                          <Checkbox
                            checked={isCurrent ? Boolean(isCurrent) : false}
                            {...register(`education.${index}.isCurrent`)}
                          />
                        }
                      />

                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        onClick={() => {
                          RemoveEducationField(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid> */}
                  </Grid>
                );
              })}
              {/* <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddEducationField({
                      type: "",
                      start: "",
                      end: "",
                      institute: "",
                      marks: "",
                      totalMarks: "",
                      isCurrent: false,
                    });
                  }}
                >
                  Add Education
                </Button>
              </Grid> */}
            </Grid>

            {/* Hobbies fields */}
            {/* <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Hobbies</Typography>
              </Grid>
              {HobbiesFields.map((field, index) => {
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Hobby"
                        {...register(`hobbies.${index}.hobby`)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveHobbiesField(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddHobbiesField({
                      hobby: "",
                    });
                  }}
                >
                  Add Hobby
                </Button>
              </Grid>
            </Grid> */}

            {/* Experince fields */}
            {/* <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Experience</Typography>
              </Grid>
              {ExperienceFields.map((field, index) => {
                let start = watch(`experience.${index}.start`);
                let end = watch(`experience.${index}.end`);
                let isCurrent = watch(`experience.${index}.isCurrent`);
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                      sx={{ mt: -3 }}
                    >
                      <Typography>{`Experince ${index + 1}`}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Employer"
                        {...register(`experience.${index}.employer`)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="Start date"
                          inputFormat="DD/MM/YYYY"
                          value={start ? utcConvertorDate(start) : null}
                          onChange={(newValue) => {
                            newValue &&
                              setValue(`experience.${index}.start`, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          label="End date"
                          inputFormat="DD/MM/YYYY"
                          value={end ? utcConvertorDate(end) : null}
                          onChange={(newValue) => {
                            newValue &&
                              setValue(`experience.${index}.end`, newValue);
                          }}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Job Role"
                        {...register(`experience.${index}.jobRole`)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <FormControlLabel
                        label="Is current?"
                        control={
                          <Checkbox
                            checked={isCurrent ? Boolean(isCurrent) : false}
                            {...register(`experience.${index}.isCurrent`)}
                          />
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        label="Job Description"
                        minRows={3}
                        {...register(`experience.${index}.jobDescription`)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        onClick={() => {
                          RemoveExperienceField(index);
                        }}
                      >
                        {`Remove`}
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddExperienceField({
                      jobRole: "",
                      isCurrent: false,
                      employer: "",
                      jobDescription: "",
                      start: "",
                      end: "",
                    });
                  }}
                >
                  Add Experince
                </Button>
              </Grid>
            </Grid> */}

            {/* year of experience */}
            <Grid item xs={12} md={6} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Experience</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  select
                  fullWidth
                  defaultValue={getValues("yearOfExp")}
                  label="Select experience"
                  {...register(`yearOfExp`)}
                >
                  {EXPERIENCE_FIELDS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>{" "}
              </Grid>
            </Grid>

            {/* Skills fields */}
            {/* <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Skills</Typography>
              </Grid>
              {SkillsFields.map((field, index) => {
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Skill"
                        {...register(`skills.${index}.skill`)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveSkillsField(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddSkillsField({
                      skill: "",
                    });
                  }}
                >
                  Add Skill
                </Button>
              </Grid>
            </Grid> */}

            {/* Websites fields */}
            {/* <Grid item xs={12} container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Websites</Typography>
              </Grid>
              {WebsitesFields.map((field, index) => {
                return (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        fullWidth
                        label="Website"
                        {...register(`websites.${index}.website`)}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <Button
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        sx={{ mt: 1 }}
                        onClick={() => {
                          RemoveWebsitesField(index);
                        }}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
              <Grid item xs={12} md={6} lg={4}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    AddWebsitesField({
                      website: "",
                    });
                  }}
                >
                  Add Website
                </Button>
              </Grid>
            </Grid> */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("recruiter")}
                label="Recruiter"
              />
            </Grid>

            {/* source */}
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                select
                fullWidth
                defaultValue={getValues("source")}
                label="Source"
                {...register(`source`)}
              >
                {SOURCE_FIELDS.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* <Grid item xs={12} md={6} lg={4}>
              <TextField
                fullWidth
                {...register("source")}
                label="How did you hear about us?"
              />
            </Grid> */}

            {sourceValue === "Employee Referral" ? (
              <Grid item xs={12} md={6} lg={4}>
                <TextField
                  fullWidth
                  {...register("referral")}
                  label="Employee ID/Name"
                />
              </Grid>
            ) : null}

            {/* <Grid item xs={12} md={6} lg={4}>
              <TextField fullWidth {...register("skypeId")} label="Skype Id" />
            </Grid> */}

            {/* <Grid item xs={12} md={6} lg={4}>
              <TextField
                select
                fullWidth
                {...register("status")}
                label="Status"
                defaultValue={getValues("status") || ""}
                error={Boolean(errors.status)}
                helperText={Boolean(errors.status) && errors.status?.message}
              >
                {Candidate_Status?.map((option) => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid> */}

            <Grid item xs={12} md={6} lg={4}>
              <TextField fullWidth {...register("remark")} label="Remark" />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Typography sx={{ color: "rgba(0,0,0,0.65)" }}>
                Resume :{" "}
                <span style={{ color: "rgba(0,0,0,0.85)" }}>
                  {resumeObj?.name
                    ? resumeObj?.name
                    : candidate?.resume?.length
                    ? candidate?.resume[0]?.originalname
                    : "No Resume attached"}
                </span>
              </Typography>
              <input
                type="file"
                accept="application/pdf"
                onChange={async (e) => {
                  if (e?.target?.files?.length) {
                    setResumeObj(e?.target?.files[0]);
                  }
                }}
              />
            </Grid>
          </Grid>
          <LoadingButton
            type="submit"
            variant="contained"
            sx={{
              mt: 3,
              width: "32ch",
            }}
          >
            EDIT CANDIDATE
          </LoadingButton>
        </form>
      </Box>
    </>
  );
};

export default EditCandidateForm;
