import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

// Fix for showing only one toast at a time
const { success, warning, error } = toast;
toast.success = function (...args) {
  this && this.dismiss();
  return success(...args);
};
toast.error = function (...args) {
  this && this.dismiss();
  return error(...args);
};
toast.warning = function (...args) {
  this && this.dismiss();
  return warning(...args);
};

export default function ToastContainerCustom() {
  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        theme="colored"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
