import React, { useEffect, useState } from "react";

// form-hooks
import * as Yup from "yup";
import {
  EMAIL_VALIDATOR,
  PHONE_VALIDATOR,
  STRING_VALIDATOR,
} from "../../../utils/yup-validators";
import { useForm } from "react-hook-form";
import { User_Schema } from "../../../utils/constants";
import { yupResolver } from "@hookform/resolvers/yup";

// material ui
import { Box, Grid, TextField } from "@mui/material";
import useCreateRecruiter from "../../react-query/recruiter/useCreateRecruiter";
import { LoadingButton } from "@mui/lab";
import { getValue } from "@testing-library/user-event/dist/utils";

const SignUpSchema = Yup.object().shape({
  password: STRING_VALIDATOR,
  confirmPassword: STRING_VALIDATOR,
  firstName: STRING_VALIDATOR,
  lastName: STRING_VALIDATOR,
  phone: PHONE_VALIDATOR,
  email: EMAIL_VALIDATOR,
});

const CreateRecruiterForm = () => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useForm<User_Schema>({
    resolver: yupResolver(SignUpSchema),
  });

  const { mutate: createRecruiterMutate } = useCreateRecruiter(reset);

  const onSubmit = (values: User_Schema) => {
    let localData = { ...values };
    localData.role = "recruiter";
    createRecruiterMutate(localData);
  };

  return (
    <Box
      sx={{
        bgcolor: "rgba(255,255,255,0.99)",
        p: 4,
        ml: 0,
        borderRadius: 1,
        border: "1px solid #ddd",
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
        width: "62ch",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("firstName")}
              label="First Name"
              error={Boolean(errors.firstName)}
              helperText={
                Boolean(errors.firstName) && errors.firstName?.message
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("lastName")}
              label="Last Name"
              error={Boolean(errors.lastName)}
              helperText={Boolean(errors.lastName) && errors.lastName?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("email")}
              label="Email"
              type="email"
              error={Boolean(errors.email)}
              helperText={Boolean(errors.email) && errors.email?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("phone")}
              label="Phone"
              error={Boolean(errors.phone)}
              helperText={Boolean(errors.phone) && errors.phone?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("password")}
              label="Password"
              type="password"
              error={Boolean(errors.password)}
              helperText={Boolean(errors.password) && errors.password?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("confirmPassword")}
              label="Confirm Password"
              onChange={(e) => {
                if (e.target.value !== getValues("password")) {
                  setError("confirmPassword", {
                    type: "custom",
                    message: "Password does not match",
                  });
                } else {
                  clearErrors(["confirmPassword"]);
                }
              }}
              error={Boolean(errors.confirmPassword)}
              helperText={
                Boolean(errors.confirmPassword) &&
                errors.confirmPassword?.message
              }
            />
          </Grid>
        </Grid>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{
            mt: 3,
          }}
        >
          Create Recruiter
        </LoadingButton>
      </form>
    </Box>
  );
};

export default CreateRecruiterForm;
