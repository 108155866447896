import { useState } from "react";

// mui
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  MenuItem,
} from "@mui/material";

// utils
import {
  Applicant_BY_JOB_SCHEMA,
  Candidate_Schema,
  Comment_Schema,
  InteractionStatus,
  Interaction_Schema,
  Pipeline_Schema,
  User_Schema,
} from "../../../utils/constants";

// component
import { TabPanel, a11yProps } from "../../../components/TabComponent";
import GetCandidateKeyView from "./GetCandidateKeyView";
import SearchNotFound from "../../../components/SearchNotFound";
import PdfViewer from "../../../components/PdfViewer";
import Iconify from "../../../components/Iconify";
import useUploadResume from "../../../components/react-query/candidate/useUploadResume";
import ViewApplicantComment from "./ViewApplicantComment";
import CustomLoader from "../../../components/CustomLoader";
import { useQuery, useQueryClient } from "react-query";
import { useApplication } from "../../../components/react-query/application/useApplication";
import ViewApplicationInteraction from "./ViewApplicationInteraction";
import useEditApplication from "../../../components/react-query/application/useEditApplication";
import useMoveApplicant from "../../../components/react-query/applicant/useMoveApplicant";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const ViewCandidateDetails = ({
  candidate,
  pipelineData,
  application,
  hideComment,
}: {
  candidate: Candidate_Schema;
  application?: Applicant_BY_JOB_SCHEMA;
  hideComment?: boolean;
  pipelineData?: Pipeline_Schema;
}) => {
  const queryClient = useQueryClient();
  const params = useParams();
  // tabs
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const { isLoading, data: candidateData } = useQuery<Candidate_Schema>([
    "candidate",
    candidate.candidateId,
  ]);

  const { isLoading: isApplicationLoading, data: applications } =
    useApplication({ candidateId: candidate.candidateId });

  // re-upload resume
  const { mutateAsync: uploadResume } = useUploadResume(candidate.candidateId);

  const {
    data: userData,
  }: {
    data: User_Schema | undefined;
    isLoading: boolean;
    isFetched: boolean;
  } = useQuery<User_Schema>("userData");
  const { mutateAsync: updateApplication } =
    useEditApplication("comment added");

  const [comment, setComment] = useState<string>("");
  const [interaction, setInteraction] = useState<string>("");
  const [interactionStatus, setInteractionStatus] = useState<string>("");
  const [interactionSubStatus, setInteractionSubStatus] = useState<string>("");

  const addComment = async () => {
    if (comment === "") {
      return;
    }
    let prevComments: Comment_Schema[] | undefined = application?.comments;

    prevComments?.push({
      comment: comment,
      userId: userData?.userId || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
    } as Comment_Schema);

    prevComments?.length &&
      application &&
      (await updateApplication({
        applicationId: application.applicationId,
        comments: prevComments,
      } as Applicant_BY_JOB_SCHEMA));

    await setComment("");
  };

  const addInteraction = async () => {
    if (interaction === "" || interactionStatus === "") {
      return;
    }
    let prevComments: Interaction_Schema[] = application?.interactions
      ? application?.interactions
      : [];

    prevComments?.push({
      description: interaction,
      status: interactionStatus as InteractionStatus,
      subStatus: interactionSubStatus,
      userId: userData?.userId || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
    });

    prevComments?.length &&
      application &&
      (await updateApplication({
        applicationId: application.applicationId,
        interactions: prevComments,
      } as Applicant_BY_JOB_SCHEMA));

    await setInteraction("");
  };

  if (isLoading) {
    return <CustomLoader />;
  }

  const findStatus = () => {
    if (application && pipelineData) {
      const findDataInObject = (objects: any, data: string) => {
        for (let index = 0; index < objects.length; index++) {
          if (objects[index].key === data) {
            return index;
          }
        }

        return null;
      };

      const index = findDataInObject(
        pipelineData?.pipeline,
        application.status
      );

      if (index !== null && index !== pipelineData?.pipeline?.length - 1) {
        return pipelineData?.pipeline[index + 1].key;
      } else {
        return null;
      }
    }
  };

  const handleMoveRoundClick = () => {
    if (application) {
      let statusValue = findStatus();

      if (statusValue !== null) {
        const commentObj = {
          comment: `Moved to ${statusValue}`,
          userId: userData?.userId || "",
          firstName: userData?.firstName || "",
          lastName: userData?.lastName || "",
        };
        const commentsList = application?.comments
          ? application.comments.concat([commentObj])
          : [commentObj];
        const values = {
          applicationId: application.applicationId,
          status: statusValue,
          comments: commentsList,
        };
        axios
          .patch(`/application/${application.applicationId}`, values)
          .then((res) => {
            queryClient.invalidateQueries(["applicant", params?.jobId]);
          })
          .catch((err: any) => toast.error("Something went wrong"));
      } else {
        toast.warn("Can't move to next round!");
      }
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleChange}>
              <Tab label="Personal Details" {...a11yProps(0)} />
              <Tab label="Professional Details" {...a11yProps(1)} />
              <Tab label="Resume" {...a11yProps(2)} />
              {!hideComment && <Tab label="Comment" {...a11yProps(3)} />}
              {!hideComment && <Tab label="Interaction" {...a11yProps(4)} />}
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TabPanel value={tabValue} index={0}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              <GetCandidateKeyView
                title="Name"
                value={candidateData?.firstName}
                type="string"
                divider
              />
              <GetCandidateKeyView
                title="Lastname"
                value={candidateData?.lastName}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Email"
                value={candidateData?.email}
                type="string"
                divider
              />
              <GetCandidateKeyView
                title="Phone number"
                value={candidateData?.phone}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Gender"
                value={candidateData?.gender}
                type="string"
                divider
              />

              {/* <GetCandidateKeyView
                title="Date of Birth"
                value={candidateData?.dob}
                type="DD-MM-yyyy"
                divider
              /> */}

              {/* <GetCandidateKeyView
                title="Alternative Phone number"
                value={candidateData?.altPhone}
                type="string"
                divider
              /> */}

              {/* <GetCandidateKeyView
                title="Hobbies"
                value={candidateData?.hobbies}
                type="stringArray"
                divider
              /> */}

              {/* <GetCandidateKeyView
                title="Skype Id"
                value={candidateData?.skypeId}
                type="string"
                divider
              /> */}
            </Grid>
          </TabPanel>
          {/* <TabPanel value={tabValue} index={1}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {candidateData?.education?.length ? (
                <GetCandidateKeyView
                  title="Education"
                  value={candidateData?.education}
                  type="educationArray"
                  divider
                />
              ) : (
                <Box sx={{ width: "100%" }}>
                  <SearchNotFound searchQuery={"No Results Found"} />
                </Box>
              )}
            </Grid>
          </TabPanel> */}

          <TabPanel value={tabValue} index={1}>
            <Grid
              container
              spacing={3}
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                p: 3.5,
                mt: 1,
                ml: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {candidateData?.education?.length ? (
                <GetCandidateKeyView
                  title="Education"
                  value={candidateData?.education}
                  type="educationArray"
                  divider
                />
              ) : null}

              <GetCandidateKeyView
                title="Experience (in Year)"
                value={candidateData?.yearOfExp}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Preffered Location"
                value={candidateData?.preferredLocation}
                type="stringArray"
                divider
              />

              <GetCandidateKeyView
                title="Current CTC"
                value={candidateData?.cctc}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Expected CTC"
                value={candidateData?.expSalary}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Notice Period"
                value={candidateData?.noticePeriod}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Source"
                value={candidateData?.source}
                type="string"
                divider
              />

              <GetCandidateKeyView
                title="Skills"
                value={candidateData?.skills}
                type="stringArray"
                divider
              />

              <GetCandidateKeyView
                title="Experience"
                value={candidateData?.experience}
                type="experienceArray"
                divider
              />
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"flex-end"}
                  gap={2}
                >
                  <Button
                    variant="contained"
                    component="label"
                    startIcon={<Iconify icon="material-symbols:upload" />}
                  >
                    Upload
                    <input
                      hidden
                      accept=".pdf"
                      multiple={false}
                      type="file"
                      onClick={() => {}}
                      onChange={async (e) => {
                        let files = e.target?.files;
                        if (files) {
                          let formData = new FormData();
                          formData.append("file", files[0]);
                          await uploadResume(formData);
                        }
                      }}
                    />
                  </Button>
                </Grid>
                {candidateData?.resume?.length ? (
                  <PdfViewer
                    filePath={`${process.env.REACT_APP_BE_SERVER}candidate/resume/${candidateData?.resume?.[0]?.filename}`}
                  />
                ) : (
                  <Box sx={{ width: "100%" }}>
                    <SearchNotFound searchQuery={"No Results Found"} />
                  </Box>
                )}
              </Grid>
            </Grid>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <Grid
              container
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {application ? (
                <>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      container
                      spacing={4}
                      sx={{ p: 2 }}
                      display={"flex"}
                      alignItems={"flex-end"}
                    >
                      <Grid item xs={12} md={10}>
                        <TextField
                          fullWidth
                          multiline
                          value={comment}
                          rows={4}
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          label="Comment"
                          placeholder="Enter comment"
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <Button variant="contained" onClick={addComment}>
                          Add Comment
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <ViewApplicantComment applications={[application]} />
                    </Grid>
                  </Grid>
                </>
              ) : applications ? (
                <ViewApplicantComment applications={applications} />
              ) : null}
            </Grid>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <Grid
              container
              sx={{
                bgcolor: "white",
                boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                border: "1.2px solid #ddd",
                borderRadius: 1,
                maxWidth: "100%",
                position: "relative",
              }}
            >
              {application ? (
                <Grid
                  item
                  xs={12}
                  container
                  spacing={3}
                  sx={{ p: 2 }}
                  display={"flex"}
                >
                  <Grid item xs={12} md={9}>
                    <TextField
                      fullWidth
                      multiline
                      disabled={true}
                      value={interaction}
                      rows={4}
                      onChange={(e) => {
                        setInteraction(e.target.value);
                      }}
                      label="Interaction"
                      placeholder="Enter interaction"
                    />
                  </Grid>
                  <Grid item xs={12} md={3} container spacing={1}>
                    <Grid item xs={12}>
                      {/* <Button
                        variant="contained"
                        fullWidth
                        size="large"
                        sx={{ mb: 2 }}
                        disabled={findStatus() === null ? true : false}
                        onClick={handleMoveRoundClick}
                      >
                        Move to Next Round
                      </Button> */}
                      <TextField
                        fullWidth
                        value={interactionStatus}
                        select
                        onChange={(e) => {
                          setInteractionStatus(e.target.value);
                          setInteraction(e.target.value);
                          setInteractionSubStatus("");

                          if (e.target.value === InteractionStatus.NEXT_ROUND)
                            handleMoveRoundClick();
                        }}
                        label="Interaction status"
                        placeholder="select interaction status"
                      >
                        {Object.values(InteractionStatus).map((v: string) => (
                          <MenuItem key={v} value={v}>
                            {v}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {interactionStatus === InteractionStatus.REJECT ||
                    interactionStatus ===
                      InteractionStatus.SELECTED_NOT_JOINED ? (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          value={interactionSubStatus}
                          select
                          onChange={(e) => {
                            setInteractionSubStatus(e.target.value);
                          }}
                          label="Sub category"
                          placeholder="select sub-category"
                        >
                          {interactionStatus === InteractionStatus.REJECT
                            ? [
                                "Skill Set",
                                "Domain",
                                "Salary",
                                "Location",
                                "WFH",
                                "Not Interested",
                                "Comms",
                              ].map((v: string) => (
                                <MenuItem key={v} value={v}>
                                  {v}
                                </MenuItem>
                              ))
                            : [
                                "Salary",
                                "Location",
                                "No Show",
                                "Better Offer",
                              ].map((v: string) => (
                                <MenuItem key={v} value={v}>
                                  {v}
                                </MenuItem>
                              ))}
                        </TextField>
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      onClick={addInteraction}
                      disabled={
                        findStatus() === null &&
                        interactionStatus === InteractionStatus.NEXT_ROUND
                          ? true
                          : false
                      }
                    >
                      Add Interaction
                    </Button>
                  </Grid>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                {applications && (
                  <ViewApplicationInteraction
                    applications={applications.map((app) =>
                      application?.applicationId
                        ? app.applicationId === application?.applicationId
                          ? { ...application }
                          : { ...app }
                        : { ...app }
                    )}
                  />
                )}
              </Grid>
            </Grid>
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default ViewCandidateDetails;
