import axios from "axios";
import { useMutation, useQueryClient } from "react-query";

// utils
import {
  Address,
  CandidateStatus,
  Create_Experience,
  Education,
  Experience,
  User_Schema,
} from "../../../utils/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export interface CREATE_CANDIDATE {
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  phone: string;
  dob: string;
  cctc?: string;
  altPhone?: string;
  yearOfExp?: string;
  address?: Address[];
  preferredLocation?: {
    location?: string;
  }[];
  education: Education[];
  hobbies: { hobby?: string }[];
  experience: Create_Experience[];
  skills: { skill?: string }[];
  websites: { website?: string }[];
  recruiter: string;
  noticePeriod: string;
  source: string;
  expSalary: string;
  skypeId: string;
  status: string;
  remark: string;
  referral?: string;
}

export interface CREATE_BULK_CANDIDATE extends CREATE_CANDIDATE {
  assignee?: string;
  assigneeDetails?: any;
  jobId?: string;
}

export interface CANDIDATE extends CREATE_CANDIDATE {
  candidateId: string;
}

const useCreateCandidate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const userData: User_Schema | undefined = queryClient.getQueryData([
    "userData",
  ]);

  return useMutation(
    (values: FormData) =>
      axios.post("/candidate", values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Candidate Added Successfully");
        navigate(`/${userData?.role}/applicant`);
        queryClient.resetQueries(["applicants"]);
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useCreateCandidate;
