import axios from "axios";
import { useMutation } from "react-query";
import { JOB } from "./useCreateJob";
import { toast } from "react-toastify";

const useEditJob = (jobId: string) => {
  return useMutation(
    (values: JOB) =>
      axios.patch(`/job/${jobId}`, values).then((res) => res.data),
    {
      onSuccess: () => {
        toast.success("Job updated successfully");
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useEditJob;
