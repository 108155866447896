import { useRef, useState } from "react";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

//  mui
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";

// react-query
import useCreateMapper, {
  CREATE_MAPPER,
  MAPPING,
} from "../react-query/mapping/useCreateMapper";

// components
import PreviewMappings from "./PreviewMappings";
import NoDataPage from "../NoDataPage";
import { TabPanel, a11yProps } from "../TabComponent";

// utils
import { STRING_VALIDATOR } from "../../utils/yup-validators";
import { fieldToLabelMapper } from "../../utils/constants";

const MappingSchema = Yup.object().shape({
  name: STRING_VALIDATOR,
  mappings: Yup.array().of(
    Yup.object().shape({
      beKey: Yup.string(),
      excelKey: Yup.string(),
    })
  ),
});

export default function PreviewMappingsDialog({
  mappings,
  open,
  handleClose,
}: {
  mappings: MAPPING[];
  open: boolean;
  handleClose: () => void;
}) {
  // tabs for preview & save
  const [tabValue, setTabValue] = useState(0);
  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  // save mappings
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CREATE_MAPPER>({
    defaultValues: { mappings: mappings },
    resolver: yupResolver(MappingSchema),
  });
  const { mutateAsync: createMapper } = useCreateMapper();

  const onSubmit = async (values: CREATE_MAPPER) => {
    try {
      const mapper = await createMapper({ ...values, mappings: [...mappings] });
      handleClose();
    } catch (error) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Preview Mappings`}</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={tabValue} onChange={handleTabValueChange}>
              <Tab label="Preview Mappings" {...a11yProps(0)} />
              <Tab label="Save mapping" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            {mappings ? (
              <Box>
                <PreviewMappings mappings={mappings} />
              </Box>
            ) : (
              <NoDataPage title="No Mappings" />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)();
              }}
              ref={formRef}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    label="Mapper name"
                    fullWidth
                    {...register("name")}
                    variant="standard"
                    error={Boolean(errors.name)}
                    helperText={Boolean(errors.name) && errors.name?.message}
                  />
                </Grid>
                <Grid item xs={12} container spacing={1}>
                  {mappings.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      key={index}
                      display={"flex"}
                      gap={2}
                      alignItems={"center"}
                      justifyContent={"space-around"}
                    >
                      <Chip label={item.excelKey} color="warning" />
                      <Chip
                        label={fieldToLabelMapper[item.beKey]}
                        color="info"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </form>
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            formRef?.current?.requestSubmit();
          }}
        >
          Save Mapper
        </Button>
      </DialogActions>
    </Dialog>
  );
}
