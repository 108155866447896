import { useQuery } from "react-query";
import axios from "axios";
import { User_Schema } from "../../../utils/constants";

export const useGetAllRecruiter = () => {
  const fetchRecruiterDetails = () => {
    return axios.get(`/user?role=recruiter`).then((res) => res.data);
  };

  return useQuery<User_Schema[]>(["recruiters"], fetchRecruiterDetails, {
    cacheTime: 10 * 60 * 1000,
    refetchOnMount: false,
  });
};
