import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";
import { toast } from "react-toastify";

const useRejectApplication = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (applicationId: string) =>
      axios
        .patch(`/application/${applicationId}/reject`)
        .then((res) => res.data),
    {
      onSuccess: (newApplication) => {
        let applications: Applicant_BY_JOB_SCHEMA[] | undefined =
          queryClient.getQueryData(["applicant", newApplication.jobId]);
        if (applications) {
          queryClient.setQueryData(
            ["applicant", newApplication.jobId],
            applications.map((app) => {
              if (app.applicationId === newApplication.applicationId) {
                return { ...app, ...newApplication };
              } else {
                return { ...app };
              }
            })
          );
        } else {
          queryClient.setQueryData(
            ["applicant", newApplication.jobId],
            [newApplication]
          );
        }

        // toggleCommentAddedCheck && toggleCommentAddedCheck();
        // toast.dismiss();
        // toast.success("Comment added successfully");
      },
      onError: async (error: any) => {
        const response = Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message?.join()
          : error?.response?.data?.message;
        await toast.error(response);
      },
    }
  );
};

export default useRejectApplication;
