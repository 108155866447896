import {
  Applicant_BY_JOB_SCHEMA,
  Interaction_Schema,
} from "../../../utils/constants";
import SearchNotFound from "../../../components/SearchNotFound";
import { Box, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";

const ViewApplicationInteraction = ({
  applications,
}: {
  applications: Applicant_BY_JOB_SCHEMA[];
}) => {
  return (
    <>
      {applications?.reduce(
        (prev, curr) =>
          prev + (curr.interactions?.length ? curr.interactions?.length : 0),
        0
      ) ? (
        applications?.map((applicant) => (
          <Grid container key={applicant?._id} spacing={1} sx={{ p: 3 }}>
            {applicant?.interactions?.map(
              (interaction: Interaction_Schema, index) => (
                <Grid item xs={12} key={index}>
                  <Box>
                    <Typography>
                      <b>
                        {interaction?.firstName
                          ? `${interaction?.firstName} `
                          : ""}
                        {interaction?.lastName
                          ? `${interaction?.lastName} `
                          : ""}
                      </b>
                      <span style={{ fontSize: 14, color: "rgba(0,0,0,0.65)" }}>
                        {interaction?.status}
                      </span>
                    </Typography>

                    {interaction?.description ? (
                      <Typography>{`${interaction?.description}${
                        interaction?.subStatus
                          ? ` (${interaction?.subStatus})`
                          : ""
                      }`}</Typography>
                    ) : null}
                    <Typography
                      variant="caption"
                      sx={{ color: "rgba(0,0,0,0.65)" }}
                    >
                      ({moment.utc(interaction?.createdAt).format("DD-MM-YYYY")}
                      )
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              )
            )}
          </Grid>
        ))
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SearchNotFound searchQuery={"No Results Found"} />
        </Box>
      )}
    </>
  );
};

export default ViewApplicationInteraction;
