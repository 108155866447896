// @mui
import PropTypes from "prop-types";
import { alpha, styled, useTheme } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";

// utils
import { fShortenNumber } from "../../utils/formatNumber";

// components
import Iconify from "../Iconify";
import palette from "../theme/palette";

// ----------------------------------------------------------------------

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

interface AppWidgetSummaryProps {
  title: string;
  total: number;
  icon: string;
  color?: "primary" | "info" | "error" | "warning" | "success";
  sx?: object;
}

export default function AppWidgetSummary({
  title,
  total,
  icon,
  color = "primary",
  sx = {},
  ...other
}: AppWidgetSummaryProps) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        py: 3,
        boxShadow: 0,
        textAlign: "center",
        color: palette[color].darker,
        bgcolor: palette[color].lighter,
        ...sx,
      }}
      {...other}
    >
      <IconWrapperStyle
        sx={{
          color: (theme) => theme.palette[color].dark,
          backgroundImage: (theme) =>
            `linear-gradient(135deg, ${alpha(
              theme.palette[color].dark,
              0
            )} 0%, ${alpha(theme.palette[color].dark, 0.24)} 100%)`,
        }}
      >
        <Iconify icon={icon} width={24} height={24} />
      </IconWrapperStyle>

      <Typography variant="h3" sx={{ mt: -1 }}>
        {fShortenNumber(total)}
      </Typography>

      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        {title}
      </Typography>
    </Card>
  );
}
