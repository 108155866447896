import React, { useEffect, useState } from "react";
import CustomLoader from "../../../components/CustomLoader";
import SearchNotFound from "../../../components/SearchNotFound";
import ViewApplicant from "./ViewApplicant";

// layout
import withLayout from "../../../components/HOC/withLayout";
import RecruiterLayout from "../../../components/layouts/recruiter";
import useTitle from "../../../components/hooks/useTitle";
import useGetAllApplicant from "../../../components/react-query/applicant/useGetAllApplicant";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useQuery, useQueryClient } from "react-query";
import { Candidate_Schema, User_Schema } from "../../../utils/constants";
import useDebounce from "../../../components/hooks/useDebounce";
import useSearchCandidate from "../../../components/react-query/candidate/useSearchCandidate";
import { useNavigate } from "react-router-dom";
import CreateAdvanceSearch from "../../../components/admin/Applicant/CreateAdvanceSearch";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";
import CustomDialog from "../../../components/CustomDialog";
import CreateApplicantForm from "./CreateApplicantForm";
import useModalState from "../../../components/react-query/useModalState";
import { useGetAllJob } from "../../../components/react-query/jobs/useGetAllJob";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const View = () => {
  const navigate = useNavigate();
  const limitApplicants: number = 12;
  const [loadMoreApplicant, setLoadMoreApplicant] = useState<boolean>(true);
  const [skipApplicants, setSkipApplicants] = useState<number>(0);

  const { modalState, open, close } = useModalState();

  const {
    isLoading: ApplicantGettingLoading,
    data,
    refetch,
  } = useGetAllApplicant({
    limit: limitApplicants,
    skip: skipApplicants,
    available: true,
  });

  const { data: applicantsData } = useQuery<Candidate_Schema[]>(["applicants"]);

  const { data: applicantsMultiData } = useQuery<Candidate_Schema[]>([
    "multi-applicants",
  ]);

  const { data: jobData } = useGetAllJob();

  useTitle("Applicant");

  useEffect(() => {
    if (!ApplicantGettingLoading) {
      if (data?.length !== limitApplicants) {
        loadMoreApplicant && setLoadMoreApplicant(false);
      }
    }
  }, [ApplicantGettingLoading]);

  // search candidate
  const [searchText, setSearchText] = useState("");
  const debouncedSearchQuery = useDebounce(searchText, 300);
  const { data: SearchCandidates, refetch: simpleSearchRefetch } =
    useSearchCandidate({
      name: debouncedSearchQuery,
    });

  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false);

  const toggleAdvanceSearch = () => {
    setShowAdvanceSearch(!showAdvanceSearch);

    if (showAdvanceSearch === true) {
      setSearchText("");
    }
  };

  const { data: advanceSearchData } = useQuery<Candidate_Schema[]>([
    "search-candidate",
  ]);

  const [selectedDate, setSelectedDate] = useState("");

  const [filterApplicantList, setFilterApplicantList] = useState<
    Candidate_Schema[]
  >([]);

  const handleClearSearch = () => {
    setSelectedDate("");
    setFilterApplicantList([]);
  };
  const handleSearch = () => {
    if (selectedDate) {
      const localList = applicantsData?.filter(
        (data) => data?.createdAt?.slice(0, 10) === selectedDate
      );

      if (localList?.length) {
        setFilterApplicantList([...localList]);
      } else {
        setFilterApplicantList([]);
      }
    }
  };

  return (
    <>
      <Grid
        xs={12}
        item
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ mx: 4 }}
      >
        <Typography variant="h4">Applicant</Typography>
        {/* <Button
          variant="outlined"
          sx={{ mr: -1.6 }}
          onClick={() => {
            navigate("/recruiter/add-candidate");
          }}
        >
          Create Applicant
        </Button> */}
      </Grid>

      <Grid item container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: 2.4,
            }}
          >
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
              <FormControl sx={{ m: 1, width: "45ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Search Candidate
                </InputLabel>
                <OutlinedInput
                  type={"text"}
                  value={searchText}
                  placeholder="Search Candidate by name, phone or email"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setSearchText("");
                        }}
                        edge="end"
                      >
                        {searchText && <CloseIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Search Candidate"
                />
              </FormControl>

              <Box sx={{ display: "flex", gap: 4 }}>
                {applicantsMultiData?.length ? (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={async () => {
                        await open();
                      }}
                    >
                      Assign Applicant
                    </Button>
                  </Box>
                ) : null}
                <Box>
                  <Button variant="contained" onClick={toggleAdvanceSearch}>
                    {showAdvanceSearch ? "Hide" : "Advance"} Search
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                width: "38%",
                alignItems: "center",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Select date"
                  value={
                    selectedDate ? moment(selectedDate, "YYYY-MM-DD") : null
                  }
                  onChange={(newValue: any) => {
                    newValue && setSelectedDate(newValue?.format("YYYY-MM-DD"));
                  }}
                  renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                  )}
                />
              </LocalizationProvider>
              {/* <TextField
                fullWidth
                value={interactionText}
                select
                onChange={(e) => {
                  setInteractionText(e.target.value);
                }}
                label="Interaction status"
                placeholder="select interaction status"
              >
                {Object.values(InteractionStatus).map((v: string) => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </TextField> */}
              <Box sx={{ display: "flex", gap: 2, alignContent: "center" }}>
                <Box>
                  <Button variant="contained" onClick={handleSearch}>
                    Search
                  </Button>
                </Box>
                <Box>
                  <Button variant="contained" onClick={handleClearSearch}>
                    Clear
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2, ml: 2.4 }}>
        <CreateAdvanceSearch showAdvanceSearch={showAdvanceSearch} />
      </Grid>

      {SearchCandidates?.length ? (
        <ViewApplicant data={SearchCandidates} />
      ) : null}

      {filterApplicantList?.length ? (
        <ViewApplicant data={filterApplicantList} />
      ) : null}

      {!!SearchCandidates?.length && (
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      )}

      {advanceSearchData?.length ? (
        <ViewApplicant data={advanceSearchData} />
      ) : null}

      {!!advanceSearchData?.length && (
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} />
        </Grid>
      )}

      <Grid container spacing={2}>
        {applicantsData?.length ? (
          <ViewApplicant data={applicantsData} />
        ) : null}

        {loadMoreApplicant ? (
          <Grid item xs={12} display={"flex"} justifyContent={"center"}>
            {ApplicantGettingLoading ? (
              <CustomLoader />
            ) : (
              <Button
                variant="outlined"
                onClick={() =>
                  setSkipApplicants(
                    applicantsData?.length ? applicantsData.length : 0
                  )
                }
              >
                Load more
              </Button>
            )}
          </Grid>
        ) : (
          !applicantsData?.length && (
            <Grid item xs={12}>
              <SearchNotFound searchQuery={"No Results Found"} />
            </Grid>
          )
        )}
      </Grid>

      {jobData && jobData?.length && (
        <CustomDialog
          title={"Create Application"}
          onClose={close}
          open={modalState}
          hideFooter={true}
          maxWidth="sm"
        >
          <CreateApplicantForm
            candidates={applicantsMultiData}
            multiCandidate
            jobs={jobData}
            close={close}
          />
        </CustomDialog>
      )}
    </>
  );
};

export default withAuth(
  withLayout(View, RecruiterLayout),
  AUTH_ROLES.RECRUITER
);
