import { useRef } from "react";
import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

//  mui
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import useCreateMapper, {
  CREATE_MAPPER,
  MAPPING,
} from "../react-query/mapping/useCreateMapper";
import { STRING_VALIDATOR } from "../../utils/yup-validators";
import { fieldToLabelMapper } from "../../utils/constants";

// utils

const MappingSchema = Yup.object().shape({
  name: STRING_VALIDATOR,
  mappings: Yup.array().of(
    Yup.object().shape({
      beKey: Yup.string(),
      excelKey: Yup.string(),
    })
  ),
});

export default function SaveMappingDialog({
  mappings,
  open,
  handleClose,
}: {
  mappings: MAPPING[];
  open: boolean;
  handleClose: () => void;
}) {
  const formRef = useRef<HTMLFormElement>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CREATE_MAPPER>({
    defaultValues: { mappings: mappings },
    resolver: yupResolver(MappingSchema),
  });
  const { mutateAsync: createMapper } = useCreateMapper();

  const onSubmit = async (values: CREATE_MAPPER) => {
    try {
      const mapper = await createMapper({ ...values, mappings: [...mappings] });
      handleClose();
    } catch (error) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{`Save Mapper for later usage`}</DialogTitle>
      <DialogContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(onSubmit)();
          }}
          ref={formRef}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                label="Mapper name"
                fullWidth
                {...register("name")}
                variant="standard"
                error={Boolean(errors.name)}
                helperText={Boolean(errors.name) && errors.name?.message}
              />
            </Grid>
            <Grid item xs={12} container spacing={1}>
              {mappings.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  key={index}
                  display={"flex"}
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"space-around"}
                >
                  <Chip label={item.excelKey} color="warning" />
                  <Chip label={fieldToLabelMapper[item.beKey]} color="info" />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            formRef?.current?.requestSubmit();
          }}
        >
          Save Mapper
        </Button>
      </DialogActions>
    </Dialog>
  );
}
