import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { Applicant_BY_JOB_SCHEMA, User_Schema } from "../../../utils/constants";
import { useGetAllRecruiter } from "../../react-query/recruiter/useGetAllRecruiter";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useMoveApplicant from "../../react-query/applicant/useMoveApplicant";

interface Pipeline {
  key: string;
  label: string;
  data?: any[] | undefined;
}
interface ApplicanInput {
  assignee: User_Schema | null | string | undefined;
}

const ApplicantFormSchema = Yup.object().shape({
  assignee: STRING_VALIDATOR,
});

const ChangeRecruiterForm = ({
  candidate,
  close,
}: {
  candidate: Applicant_BY_JOB_SCHEMA;
  close: () => void;
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ApplicanInput>({
    resolver: yupResolver(ApplicantFormSchema),
    defaultValues: {},
  });

  const { data: recruiterData } = useGetAllRecruiter();

  const [recruiterValue, setRecruiterValue] = useState<User_Schema | null>();

  const { mutate: moveApplicantMutate } = useMoveApplicant(
    close,
    candidate.applicationId
  );
  const onSubmit = async (data: ApplicanInput) => {
    console.log("object", data);
    let responseData: any = {};

    if (recruiterValue?.userId) {
      responseData["assignee"] = recruiterValue?.userId;
    }

    try {
      await moveApplicantMutate(responseData);
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event: any, newValue: User_Schema | null) => {
                setRecruiterValue(newValue);
                setValue("assignee", newValue?.userId);
              }}
              getOptionLabel={(option) =>
                option?.firstName
                  ? `${option?.firstName} ${option?.lastName}`
                  : ""
              }
              options={recruiterData ? recruiterData : []}
              fullWidth
              value={recruiterValue || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Please select a recruiter"
                  {...register("assignee")}
                  error={Boolean(errors.assignee)}
                  helperText={
                    Boolean(errors.assignee) && "This field is required"
                  }
                />
              )}
            />
          </Grid>
        </Grid>
        <LoadingButton
          fullWidth
          type="submit"
          variant="contained"
          sx={{ my: 2 }}
        >
          Change Recruiter
        </LoadingButton>
      </form>
    </>
  );
};

export default ChangeRecruiterForm;
