import axios from "axios";
import { useMutation } from "react-query";
import { CREATE_CANDIDATE } from "./useCreateCandidate";

const useBulkUploadCandidate = () => {
  return useMutation((values: { candidates: CREATE_CANDIDATE[] }) =>
    axios.post("/candidate/bulk", values).then((res) => res.data)
  );
};

export default useBulkUploadCandidate;
