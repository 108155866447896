import { useQuery } from "react-query";
import axios from "axios";
import { Applicant_BY_JOB_SCHEMA } from "../../../utils/constants";

export const useGetApplicantByJobId = (jobId: string | undefined) => {
  const fetchApplicantDetails = () => {
    return axios
      .get(`/application?jobId=${jobId}&candidate=true`)
      .then((res) => res.data);
  };

  return useQuery<Applicant_BY_JOB_SCHEMA[]>(
    ["applicant", jobId],
    fetchApplicantDetails,
    {
      refetchOnMount: "always",
      keepPreviousData: true,
    }
  );
};
