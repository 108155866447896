import moment from "moment";
import { useEffect, useState } from "react";

// mui
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

// utils
import { Candidate_Schema } from "../../../utils/constants";

//  hooks & hocs
import useModalState from "../../../components/react-query/useModalState";

// components
import CustomDialog from "../../../components/CustomDialog";
import CreateApplicantForm from "./CreateApplicantForm";
import ViewCandidateDetails from "./ViewCandidateDetails";
import { ViewData } from "../../../components/ResuableUI";
import { useNavigate } from "react-router-dom";
import { useGetAllJob } from "../../../components/react-query/jobs/useGetAllJob";
import { useQuery, useQueryClient } from "react-query";

const ViewApplicant = ({ data }: { data: Candidate_Schema[] }) => {
  // modal for apply
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { modalState, open, close } = useModalState();

  const { data: applicantsMultiData } = useQuery<Candidate_Schema[]>([
    "multi-applicants",
  ]);

  // modal for view candidate details
  const {
    modalState: candidateViewModalState,
    open: openCandidateModal,
    close: closeCandidateModal,
  } = useModalState();

  // selected candidate in which all actions will be done
  const [selectedCandidate, setSelectedCandidate] = useState<
    Candidate_Schema | undefined
  >(undefined);

  useEffect(() => {
    if (modalState === false) {
      setSelectedCandidate(undefined);
    }
  }, [modalState]);

  useEffect(() => {
    if (candidateViewModalState === false) {
      setSelectedCandidate(undefined);
    }
  }, [candidateViewModalState]);

  const { data: jobData } = useGetAllJob();

  return (
    <>
      <Box sx={{ p: 3, width: "100%" }}>
        <Grid container spacing={3}>
          {data?.map((candidate) => (
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              key={candidate?.candidateId}
              sx={{ position: "relative" }}
            >
              <Box sx={{ position: "absolute", right: "1rem", top: "2rem" }}>
                <Box sx={{ display: "flex" }}>
                  <Tooltip title="View">
                    <IconButton
                      color="primary"
                      onClick={async () => {
                        await setSelectedCandidate(candidate);
                        await openCandidateModal();
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        navigate(
                          `/recruiter/applicant/${candidate?.candidateId}`
                        );
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Box
                sx={{
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  borderRadius: 2,
                  bgcolor: "white",
                  py: 2,
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    ml: -1.5,
                    mt: -1,
                  }}
                >
                  <Checkbox
                    checked={
                      applicantsMultiData &&
                      applicantsMultiData.find(
                        (c: Candidate_Schema) => c.phone === candidate.phone
                      )
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        let localList: Candidate_Schema[] = [];

                        if (applicantsMultiData?.length) {
                          localList = [...applicantsMultiData, candidate];
                        } else {
                          localList.push(candidate);
                        }
                        queryClient.setQueryData(
                          ["multi-applicants"],
                          [...localList]
                        );
                      } else {
                        let localUpdatedList: Candidate_Schema[] = [];
                        if (applicantsMultiData?.length) {
                          localUpdatedList = applicantsMultiData.filter(
                            (c: Candidate_Schema) => c.phone !== candidate.phone
                          );
                        }
                        queryClient.setQueryData(
                          ["multi-applicants"],
                          [...localUpdatedList]
                        );
                      }
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  {ViewData(
                    "",
                    `${candidate?.firstName ? candidate.firstName : ""} ${
                      candidate?.lastName ? candidate.lastName : ""
                    }`,
                    "header"
                  )}
                </Box>
                {ViewData("Phone :", candidate?.phone)}
                {ViewData("Email :", candidate?.email)}
                {candidate?.gender
                  ? ViewData("Gender :", candidate?.gender)
                  : null}
                {candidate?.cctc
                  ? ViewData("Current CTC :", candidate?.cctc)
                  : null}

                <Button
                  variant="contained"
                  sx={{ my: 2 }}
                  fullWidth
                  onClick={async () => {
                    await setSelectedCandidate(candidate);
                    await open();
                  }}
                >
                  ADD
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {!!selectedCandidate ? (
        <CustomDialog
          title={`${
            selectedCandidate?.firstName ? selectedCandidate?.firstName : ""
          } ${selectedCandidate?.lastName ? selectedCandidate?.lastName : ""}`}
          onClose={closeCandidateModal}
          open={candidateViewModalState}
          hideFooter={true}
          maxWidth="lg"
        >
          <ViewCandidateDetails candidate={selectedCandidate} hideComment />
        </CustomDialog>
      ) : null}

      {jobData && jobData?.length && (
        <CustomDialog
          title={"Create Application"}
          onClose={close}
          open={modalState}
          hideFooter={true}
          maxWidth="sm"
        >
          <CreateApplicantForm
            candidate={selectedCandidate}
            jobs={jobData}
            // recruiter={recruiterData}
            close={close}
          />
        </CustomDialog>
      )}
    </>
  );
};

export default ViewApplicant;
