import axios from "axios";
import moment from "moment";
import { useQuery } from "react-query";

export default function useRecruiterActivityReport({
  start = moment().subtract(1, "years").format("YYYY-MM-DD"),
  end = moment().format("YYYY-MM-DD"),
}: {
  start?: string;
  end?: string;
}) {
  const fetchStatusReportsData = () => {
    let url = `/application/report/activity?start=${start}&end=${end}`;
    return axios.get(url).then((res) => res.data || []);
  };

  return useQuery(
    ["RecruiterActivityReport", start, end],
    fetchStatusReportsData,
    {
      keepPreviousData: true,
      refetchOnMount: true,
    }
  );
}
