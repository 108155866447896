import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
import { BRAND } from "../utils/constants";

// ----------------------------------------------------------------------

interface Props {
  disabledLink?: boolean;
  sx?: object;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/img/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx, cursor: "pointer" }}>
      <img
        src="/images/forgotPassword.png"
        alt="logo"
        style={{ height: "100%", width: "100%", objectFit: "contain" }}
      />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" style={{ textDecoration: "none" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          // bgcolor: "primary.main",
        }}
      >
        {/* <Logo /> */}
        {/* {logo} */}
        {/* <img src="/images/grassroot-white-logo.png" width="150px" /> */}
        <Typography variant="h3" color="primary.dark" sx={{}}>
          {BRAND}
        </Typography>
      </Box>
    </Link>
  );
}
