// layout
import withLayout from "../../../components/HOC/withLayout";
import RecruiterLayout from "../../../components/layouts/recruiter";

// component
import CreateCandidateForm from "../../../components/candidate/CreateCandidateForm";
import useTitle from "../../../components/hooks/useTitle";

// material ui
import { Box, Typography } from "@mui/material";
import withAuth, { AUTH_ROLES } from "../../../components/HOC/withAuthGuard";

const View = () => {
  useTitle("Create Candidate");

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Box sx={{ mb: 3, mt: -2 }}>
          <Typography variant="h4">Create Candidate</Typography>
        </Box>
        <CreateCandidateForm />
      </Box>
    </>
  );
};

export default withAuth(
  withLayout(View, RecruiterLayout),
  AUTH_ROLES.RECRUITER
);
