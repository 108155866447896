import { useState } from "react";

const useModalState = () => {
  const [modalState, setModalState] = useState(false);
  return {
    modalState,
    open: () => setModalState(true),
    close: () => setModalState(false),
  };
};

export default useModalState;
