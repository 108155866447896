import React from "react";
import * as Yup from "yup";

// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { STRING_VALIDATOR } from "../../../utils/yup-validators";

// material ui
import { Grid, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// hocs & hooks
import { Pipeline_Schema } from "../../../utils/constants";
import useEditPipeline from "../../react-query/pipeline/useEditPipeline";

const AddRoundSchema = Yup.object().shape({
  key: STRING_VALIDATOR,
  label: STRING_VALIDATOR,
});

const AddRoundPipelineForm = ({
  pipeline,
  handleClose,
  addIndex,
}: {
  addIndex: number;
  pipeline: Pipeline_Schema;
  handleClose: () => void;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(AddRoundSchema),
    defaultValues: { key: "", label: "" },
    mode: "onBlur",
  });

  const { mutateAsync: addRoundInPipeline, isLoading: IsRoundGettingAdded } =
    useEditPipeline(pipeline.pipelineId);
  const onSubmit = async (values: { key: string; label: string }) => {
    let keyIndex = pipeline.pipeline.findIndex(
      (item) => item.key === values.key
    );
    if (keyIndex !== -1) {
      handleClose();
      return;
    }

    try {
      let localPipeline = pipeline.pipeline;
      let newRound = { ...values, data: [] };
      localPipeline.splice(addIndex, 0, newRound);
      const editedPipeline = await addRoundInPipeline({
        ...pipeline,
        pipeline: [...localPipeline],
      });
      handleClose();
    } catch (error) {}
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              {...register("key")}
              label="Key"
              error={Boolean(errors.key)}
              helperText={Boolean(errors.key) && errors.key?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              maxRows={2}
              fullWidth
              {...register("label")}
              label="Label"
              error={Boolean(errors.label)}
              helperText={Boolean(errors.label) && errors.label?.message}
            />
          </Grid>

          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              loading={IsRoundGettingAdded}
            >
              Add Round
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AddRoundPipelineForm;
