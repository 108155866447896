import { Box, Typography } from "@mui/material";
import moment from "moment";

export const returnDataValue = (
  value: string | undefined,
  type: string | undefined
) => {
  if (type === "dob") return moment(value).format("DD-MM-YYYY");
  if (type === "header")
    return value && value?.length > 14 ? `${value?.slice(0, 14)}...` : value;
  return value;
};

export const ViewData = (
  key: string,
  value: string | undefined,
  type?: string
) => {
  return (
    <Box>
      <Typography variant={type === "header" ? "h6" : "body1"}>
        {key} {returnDataValue(value, type)}
      </Typography>
    </Box>
  );
};
